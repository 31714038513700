import styled from '@emotion/styled';

export const NotFound = styled.div`
  padding: 50px 20px;
  text-align: center;
  h1 {
    font-size: 40px;
    font-weight: bolder;
  }

  max-width: 600px;
  margin: auto;
`;
