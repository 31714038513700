import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import * as Styled from './YoutubeEmbed.styled';

export interface YoutubeEmbedProps {
  embedId: string;
}

export const YoutubeEmbed = (props: YoutubeEmbedProps) => {
  const { embedId } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  return (
    <Styled.YoutubeEmbed>
      <iframe
        width="100%"
        height="280"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Styled.YoutubeEmbed>
  );
};

export default YoutubeEmbed;
