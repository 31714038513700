import { deepOmit } from '@makeit-studio/helpers';
import { $ } from 'config/zeus';
import { useApollo } from '@makeit-studio/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import * as models from './models';
import actions from './actions';

export const treatmentOut = (values) => {
  const output = {
    ...values,
  };

  return deepOmit(output, ['_id', '__typename', 'createdAt', 'updatedAt', 'get.*']);
};

export const useList = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.list,
      name: 'events',
      model: {
        items: models.eventListModel,
      },
      inputs: {
        page: 1,
        limit: 10,
        sortBy: {
          startAt: 'ASC',
        },
        searchBy: $`search`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useTypeList = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.types,
      name: 'types',
      model: {
        items: models.eventType,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useDetail = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.detail,
      name: 'event',
      model: models.eventModel,
      inputs: {
        id: $`id`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useRecentList = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.recent,
      name: 'events',
      model: {
        items: models.eventListModel,
      },
      inputs: {
        page: 1,
        limit: 10,
        sortBy: {
          createdAt: 'DESC',
        },
        searchBy: $`search`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useSuggestedList = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.suggested,
      name: 'events',
      model: {
        items: models.eventListModel,
      },
      inputs: {
        page: 1,
        limit: 10,
        sortBy: {
          startAt: 'ASC',
        },
        searchBy: $`search`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const usePinnedList = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.pinned,
      name: 'myPinnedEvents',
      model: {
        items: models.eventListModel,
      },
      inputs: {
        // page: 1,
        // limit: 20,
        sortBy: {
          startAt: 'ASC',
        },
        searchBy: $`search`,
      },
    }),
    [],
  );

  return useApollo(params);
};
