import styled from '@emotion/styled';
import { v, vAlign, lineClamp, transition } from '@makeit-studio/ui-library';

export const NavItem = styled.div`
  padding: 4px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
  }
  .arrow,
  .nav-item {
    height: 56px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 14px;
    cursor: pointer;
    ${(props) => transition(props.theme, 'background')}
  }
  .arrow {
    margin-left: 5px;
    flex-shrink: 0;
    padding: 5px;
    justify-content: center;
    color: ${v('colors.dark')};
    font-size: 18px;
    &.active {
      transform: rotateX(-180deg);
    }
  }

  .nav-item {
    position: relative;
    justify-content: space-between;
    width: 100%;

    font-size: 18px;
    font-weight: 400;

    .left {
      display: flex;
      align-items: center;
      .icon {
        line-height: 1;
        font-size: 24px;
        min-width: 25px;
        margin-right: 20px;
        &.active {
          display: none;
        }
      }
      .text {
        ${lineClamp(2)}
        max-height: none;
        ${(props) => transition(props.theme, 'opacity')}
        &.hidden {
          opacity: 0;
        }
      }
    }

    &.active,
    &:hover {
      .icon {
        &.inactive {
          display: none;
        }
        &.active {
          display: inline;
        }
      }
      background-color: ${v('colors.primaryLight')};
      color: ${v('colors.primary')};

      /* Letter spacing from 400 -> 600 */
      font-weight: 600;
      /* letter-spacing: -0.27px; */

      /* Letter spacing from 400 -> 700 (bold) */
      /*
      font-weight: bold;
      letter-spacing: -0.535px;
      */
    }
    &.active:after {
      content: '';
      ${vAlign}
      height: 32px;
      right: -22px;

      width: 3px;
      border-radius: 4px;
      background: ${v('colors.primary')};
    }
  }

  &.tab {
    padding: 0;
    .nav-item {
      font-weight: 600;
      height: auto;
      font-size: 14px;
      padding: 12px 22px;

      &.active:after {
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        width: 32px;
        height: 3px;
        top: calc(100% - 2px);
        right: auto;
      }
    }
  }
`;

export const SubItem = styled.div`
  margin-left: 15px;

  border-left: solid 1px ${v('colors.border.light')};
  > * {
    font-size: 90%;
    margin-left: 8px;
  }
`;
