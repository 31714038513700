import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const App = styled.div`
  height: 100vh;

  .app-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .app-content {
      position: relative;
      height: 100%;
      overflow: auto;
      &.no-scroll {
        overflow: hidden;
      }
      &.grey {
        background-color: ${v('colors.primaryLight')};
      }
      > *:first-of-type {
        padding: max(calc(env(safe-area-inset-top) + 20px), 35px) 20px 20px 20px;
      }
    }
  }
`;
