import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction, pushRoute } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import PageTitle from 'components/items/PageTitle';
import { useHistory, useLocation } from 'react-router-dom';
import ListEventLayout from 'components/layouts/ListEventLayout';
import qs from 'qs';
import { usePinnedList } from 'store/event/hooks';
import { cloneDeep } from 'lodash';
import { useSearchParams } from 'hooks/searchParams';
import * as Styled from './MyEvents.styled';

export interface MyEventsProps {}

export const MyEvents = (props: MyEventsProps) => {
  // mapStateToProps
  const list = useSelector((state: StoreState) => state.event.pinned?.data);
  const loading = useSelector((state: StoreState) => state.event.pinned?.loading);

  const { location, eventSearch } = useSearchParams();

  const loadData = usePinnedList();

  const handleSearch = useCallback(() => {
    loadData({
      variables: { search: eventSearch },
    });
  }, [eventSearch, loadData]);

  useEffect(() => {
    handleSearch();
  }, [location, handleSearch]);

  return (
    <Styled.MyEvents>
      <ListEventLayout options={{ title: 'My Events', text: 'List of my events', route: 'my-events', loading, list: list?.items || [] }} noBack />
    </Styled.MyEvents>
  );
};

export default MyEvents;
