export const addressModel = {
  number: true,
  street: true,
  streetBis: true,
  floor: true,
  box: true,
  zip: true,
  state: true,
  city: true,
  country: true,
  location: {
    type: true,
    coordinates: true,
  },
};

export const commentModel = {
  title: true,
  createdAt: true,
  author: true,
  content: true,
  read: true,
};

export const imageModel = {
  _id: true,
  title: true,
  fileType: true,
  large: true,
  medium: true,
  small: true,
  getFile: {
    mime: true,
    title: true,
    getUrl: true,
  },
};

export const translatableModel = {
  en: true,
};
