import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const PreferencesForm = styled.form`
  .step {
    display: flex;
    align-items: top;
    justify-content: space-between;
    flex-wrap: wrap;
    &.second {
      margin-top: 30px;
    }
    h3 {
      width: 100%;
    }
  }

  .radio-container {
    flex: 1;
    margin: 0 -5px;
  }

  .custom-checkbox {
    display: block;
    width: 30%;
    margin: 10px 0 30px 0;
    .label {
      display: block !important;
      text-align: center;
    }
    .box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 90%;
      max-height: 100px;
      background-color: #f2f3f7 !important;
      z-index: 0;
      border-radius: 4px;
      border: none;

      &.active {
        border: solid 2px #28242e;
      }
    }
    p {
      position: relative;
      top: 40px;
    }
    img {
      width: 100%;
      max-width: 50px;
      max-height: 50px;
      top: 25px;
      position: relative;
    }
  }
`;
