import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const ProjectCountdown = styled.div`
  .countdown-wrapper {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    position: relative;
    top: 70px;
    z-index: 3;
    .item {
      .sub-item {
        font-weight: ${v('font.weight.bold')};
        display: block;
        p {
          background-color: white;
          padding: 5px;
          border-radius: ${v('border.radius.default')};
          color: ${v('colors.primary')};
          display: table;
          margin: 0 auto;
        }
        span {
          color: white;
          width: 100%;
        }
      }
    }
    .separator {
      p {
        color: white;
        margin: 0;
      }
    }
  }
`;
