import React from 'react';
import { css, Global, useTheme } from '@emotion/react';
import { fontSize, getV } from '@makeit-studio/ui-library';

export const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        html,
        body {
          background-color: white;
          color: ${getV(theme, 'colors.text.secondary')};
          font-size: ${getV(theme, 'font.size.default')};
          height: 100%;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
          margin: 0;
          margin-bottom: 10px;
          color: ${getV(theme, 'colors.text.primary')};
          font-family: ${getV(theme, 'font.family.title')};
          position: relative;
          display: block;
        }
        h1 {
          ${fontSize('28px', 0)}
          text-transform: ${getV(theme, 'font.style.uppercase')};
          font-weight: 900;
        }
        h2 {
          ${fontSize('18px', 0)}
          font-weight: 400;
          text-transform: ${getV(theme, 'font.style.uppercase')};
        }
        h3 {
          ${fontSize('16px', 0)}
          text-transform: ${getV(theme, 'font.style.uppercase')};
          font-style: ${getV(theme, 'font.style.italic')};
          font-weight: ${getV(theme, 'font.weight.regular')};
        }
        h4 {
          ${fontSize('20px', 0)}
        }

        a {
          .link {
            &.link-switcher {
              margin-top: 20px;
            }
          }
        }
        .list-item {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
        span {
          position: relative;
          display: block;
        }
        input,
        select,
        textarea,
        button {
          border-radius: 0px;
          background-color: transparent;
          border: none;
          text-decoration: none;
          box-shadow: none;
          font-weight: 700;
          font-family: inherit;
        }
        .align-center {
          text-align: ${getV(theme, 'align.center')};
        }
        .align-left {
          text-align: ${getV(theme, 'align.left')};
        }
        .align-right {
          text-align: ${getV(theme, 'align.right')};
        }
        .content,
        .heading,
        .back {
          display: block;
          margin-bottom: 30px;
          hr {
            margin: 0 0 30px 0;
            width: 100%;
            height: 1px;
            border: 0;
            background-color: ${getV(theme, 'colors.primary300')};
          }
        }
        .image-wrapper {
          &.full {
            position: relative;
            display: block;
            width: 100vw;
            top: -20px;
            left: -20px;
            height: 340px;
            overflow: hidden;
            margin-bottom: 30px;
            .background {
              width: 100%;
              z-index: 0;
              position: relative;
            }
            .logo {
              z-index: 3;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
            }
          }
          .overlay {
            width: 100%;
            height: 100%;
            background-color: ${getV(theme, 'colors.primary')};
            opacity: 0.3;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }
        }
        .inline-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            margin: 0 !important;
          }
          .margin-top {
            margin-top: 15px !important;
          }
        }
        .search-filter-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .search-wrapper {
            background-color: ${getV(theme, 'colors.background.lightGrey')};
            width: 100%;
            height: 50px;
            border-radius: 4px;
            margin-right: 20px;
          }
        }
        .settings-nav-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: solid 1px ${getV(theme, 'colors.primary300')};
          &:last-child {
            border-bottom: none;
          }

          &:nth-of-type(7) {
            .item-wrapper {
              h4 {
                color: ${getV(theme, 'colors.error')};
              }
              .image-wrapper {
                background-color: ${getV(theme, 'colors.error200')};
              }
            }
          }

          .item-wrapper {
            display: flex;
            align-items: center;
            .image-wrapper {
              margin-right: 20px;
              background-color: ${getV(theme, 'colors.primaryLight')};
              width: 30px;
              height: 30px;
              display: flex;
              border-radius: ${getV(theme, 'border.radius.default')};
              img {
                max-width: 18px;
                max-height: 18px;
                margin: auto;
              }
            }
            h4 {
              margin: 0;
            }
          }
        }
        .tabs-wrapper {
          .content-tab {
            .tab-sub-item {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              span {
                font-weight: ${getV(theme, 'font.weight.bold')};
              }
              svg {
                margin-right: 10px;
                font-size: 18px;
              }
            }
            p {
              line-height: ${getV(theme, 'font.lineHeight.text')};
            }
          }
        }
        .background-absolute {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: ${getV(theme, 'colors.primaryLight')};
          z-index: 0;
        }
        .category-wrapper {
          display: flex;
          align-items: center;
          margin-top: 20px;
          overflow: auto;
          width: calc(100% + 20px);
          -ms-overflow-style: none; /* for Internet Explorer, Edge */
          scrollbar-width: none; /* for Firefox */
          &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
        }
        .modal-wrapper {
          padding: 20px;
          .actions {
            margin-top: 20px !important;
          }
        }
        .modal-wrapper-logout {
          padding: 20px;
        }
      `}
    />
  );
};

export default GlobalStyles;
