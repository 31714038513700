import React, { useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';
// Import actions here

import classNames from 'classnames';
import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';
import { TextItem } from '@makeit-studio/ui-store';
import RadioItem from 'components/listItems/RadioItem';
import * as Styled from './PreferencesForm.styled';

// assets
import ticketIcon from './assets/ticket-icon.svg';
import timeIcon from './assets/time-icon.svg';
import beerIcon from './assets/beer-icon.svg';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
}

export type PreferencesFormProps = StateProps & DispatchProps & OwnProps;

const PreferencesForm = (props: PreferencesFormProps) => {
  const { handleSubmit, submitting, valid } = props;

  const types = useSelector((state: StoreState) => state.event.types?.data?.items || []);
  const moments = [
    {
      value: 'BEFORE',
      label: 'Before the game',
      logo: {
        getFile: {
          getUrl: ticketIcon,
        },
      },
    },
    {
      value: 'DURING',
      label: 'During the game',
      logo: {
        getFile: {
          getUrl: timeIcon,
        },
      },
    },
    {
      value: 'AFTER',
      label: 'After the game',
      logo: {
        getFile: {
          getUrl: beerIcon,
        },
      },
    },
  ];

  return (
    <Styled.PreferencesForm onSubmit={handleSubmit}>
      <div className={classNames('step', 'first')}>
        <TextItem tag="h3" path="Type of events" />
        <Field
          items={types}
          component={ProjectInput}
          className={classNames('radio-container')}
          itemComponent={RadioItem}
          seed="radio"
          name="settings.eventTypesIds"
          modelValues={{ label: 'name', value: '_id' }}
          multipleSelection
        />
      </div>
      <div className={classNames('step', 'second')}>
        <TextItem tag="h3" path="Favorite moments" />

        <Field
          items={moments}
          component={ProjectInput}
          className={classNames('radio-container')}
          itemComponent={RadioItem}
          seed="radio"
          name="settings.eventMoments"
          modelValues={{ label: 'name', value: '_id' }}
          multipleSelection
        />
        <ProjectButton disabled={submitting || !valid} label="Save" type="submit" />
      </div>
    </Styled.PreferencesForm>
  );
};

export default PreferencesForm;
