import React, { useCallback, useMemo } from 'react';

import { ClassNames, useTheme } from '@emotion/react';
import cloneDeep from 'lodash/cloneDeep';
import getContent from '@makeit-studio/helpers/dist/content/getContent';

import { FieldWrapperProps, FormElement, FormElementProps } from '@makeit-studio/ui-library';
import { FormItemLabel, TextItem } from '@makeit-studio/ui-store';
import get from 'lodash/get';
import * as Styled from './Radio.styled';

export type RadioProps = FieldWrapperProps &
  FormElementProps & {
    inlined?: boolean;
    rowClassName?: string;
    radioClassName?: string;
    circleClassName?: string;
    insideCircleClassName?: string;
    itemComponent?: React.FC<any>;
    modelValues?: Record<string, any>;
    multipleSelection?: boolean;
    onChange?: (...args) => any;
    textClassName?: string;
    imageClassName?: string;
    items?: any[];
  };

export const Radio = (props: RadioProps) => {
  const {
    items,
    input,
    label,
    className,
    radioClassName,
    onChange,
    circleClassName,
    insideCircleClassName,
    textClassName,
    rowClassName,
    inlined,
    imageClassName,
    disabled,
    // content props
    lg,
    defaultLanguage,
    content,
    localeContent,
    itemComponent: ItemComponent,
    modelValues,
    multipleSelection,
  } = props;

  const isMultiple = useMemo(() => multipleSelection, [multipleSelection]);

  const treatItems = () => {
    let listItems;

    if (typeof items === 'string' && (content || localeContent)) {
      listItems = getContent(content, items, lg, null, defaultLanguage) ?? getContent(localeContent, items, lg, null, defaultLanguage);
    } else {
      listItems = cloneDeep(items);
    }
    if (!Array.isArray(listItems) && typeof listItems === 'object') {
      listItems = Object.values(listItems);

      listItems.map((item) => {
        item.id = `${input.name}-${item.value || ''}`;
        return item;
      });
    }

    if (!listItems) return null;

    return listItems;
  };

  const list = treatItems();

  const isChecked = useCallback(
    (item) => {
      if (!item) return false;

      const itemValue = (modelValues?.value && get(item, modelValues?.value)) || item.value || item.label || item;

      if (isMultiple) {
        return (input.value || []).indexOf(itemValue) !== -1;
      }
      return input.value === itemValue;
    },
    [modelValues, isMultiple, input],
  );

  const handleChange = useCallback(
    (item) => {
      if (disabled) return;
      const value = item && ((modelValues?.value && get(item, modelValues?.value)) || item.value || item.label || item);

      const values = cloneDeep(input.value) || [];

      if (isMultiple) {
        if (isChecked(value)) {
          const index = values.indexOf(value);
          values.splice(index, 1);
        } else {
          values.push(value);
        }
      }

      if (input.onChange) input.onChange(isMultiple ? values : value);

      if (onChange) onChange(isMultiple ? values : value);
    },
    [input, onChange, isMultiple, modelValues, isChecked, disabled],
  );

  const theme = useTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <FormElement
          {...props}
          className={cx(
            css`
              ${Styled.Radio(theme)}
            `,
            className,
            radioClassName,
          )}
        >
          {label && <FormItemLabel {...props} className="" />}
          <div
            className={cx('items-wrapper', inlined && 'inlined', disabled && 'disabled')}
            // allows to have touched effect
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            tabIndex={0}
          >
            {list &&
              list.map(function (elem, index) {
                if (!elem) return null;
                if (ItemComponent) return <ItemComponent key={index} checked={isChecked(elem)} action={() => handleChange(elem)} data={elem} />;
                return (
                  <div key={index} className={cx('row-container', rowClassName, isChecked(elem) && 'active')}>
                    <div className={cx('circle', circleClassName)} onClick={() => handleChange(elem)}>
                      <div className={cx('inside-circle', insideCircleClassName)} />
                    </div>

                    {elem.img ? (
                      <div className={cx('img', imageClassName)} onClick={() => handleChange(elem)}>
                        <img src={elem.img} alt={elem.label || elem.value} />
                      </div>
                    ) : (
                      <div className={cx('text', textClassName)} onClick={() => handleChange(elem)}>
                        <TextItem path={elem.label || elem} />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </FormElement>
      )}
    </ClassNames>
  );
};

export default Radio;
