import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const RadioItem = styled.div`
  flex: 0 0 33%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;

  .image-container {
    margin: 5px;
    border-radius: ${v('border.radius.default')};
    padding: 20px;
    background-color: ${v('colors.background.lightGrey')};
    margin-bottom: 10px;
    height: 100px;
    width: 100px;
    border: 1px solid ${v('colors.background.lightGrey')};
    display: flex;
    justify-content: center;
    align-items: center;
    &.checked {
      border: 1px solid ${v('color.border.default')};
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
`;
