import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';

import { StoreState } from 'store/appReducers';

import * as Styled from './RadioItem.styled';

export interface RadioItemProps {
  action?: (...args) => any;
  checked?: boolean;
  data?: {
    name?: string;
    logo?: {
      getFile?: {
        getUrl?: string;
      };
    };
    label?: string;
  } & string;
}

export const RadioItem = (props: RadioItemProps) => {
  const { action, checked, data } = props;
  return (
    <Styled.RadioItem onClick={action}>
      <div className={classNames('image-container', checked && 'checked')}>
        <img src={data?.logo?.getFile?.getUrl} alt={data?.name || data} />
      </div>
      <TextItem path={data?.label || data?.name || data} />
    </Styled.RadioItem>
  );
};

export default RadioItem;
