import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const PageTitle = styled.div`
  display: block;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  &.center {
    text-align: center;
  }
  button {
    padding: 0;
    min-height: 0;
    height: 35px;
    margin-bottom: 20px;
    &:hover,
    &.active {
      &:before {
        display: none !important;
      }
    }
  }
  h1 {
    margin: 0;
  }
  div {
    &.uppercase {
      text-transform: ${v('font.style.uppercase')};
    }
  }
`;
