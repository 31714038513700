export default {
  AF: 'Afghanistan',
  AX: 'Alandeilanden',
  AL: 'Albani\u00eb',
  DZ: 'Algerije',
  AS: 'Amerikaans Samoa',
  VI: 'Amerikaanse Maagdeneilanden',
  UM: 'Amerikaanse kleinere afgelegen eilanden',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua en Barbuda',
  AR: 'Argentini\u00eb',
  AM: 'Armeni\u00eb',
  AW: 'Aruba',
  AU: 'Australi\u00eb',
  AZ: 'Azerbeidzjan',
  BS: 'Bahama\u2019s',
  BH: 'Bahrein',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BE: 'Belgi\u00eb',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosni\u00eb en Herzegovina',
  BW: 'Botswana',
  BV: 'Bouveteiland',
  BR: 'Brazili\u00eb',
  IO: 'Britse Gebieden in de Indische Oceaan',
  VG: 'Britse Maagdeneilanden',
  BN: 'Brunei',
  BG: 'Bulgarije',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodja',
  CA: 'Canada',
  KY: 'Caymaneilanden',
  CF: 'Centraal-Afrikaanse Republiek',
  CL: 'Chili',
  CN: 'China',
  CX: 'Christmaseiland',
  CC: 'Cocoseilanden',
  CO: 'Colombia',
  KM: 'Comoren',
  CG: 'Congo',
  CD: 'Congo-Kinshasa',
  CK: 'Cookeilanden',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CY: 'Cyprus',
  DK: 'Denemarken',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominicaanse Republiek',
  DE: 'Duitsland',
  EC: 'Ecuador',
  EG: 'Egypte',
  SV: 'El Salvador',
  GQ: 'Equatoriaal-Guinea',
  ER: 'Eritrea',
  EE: 'Estland',
  ET: 'Ethiopi\u00eb',
  FO: 'Faer\u00f6er',
  FK: 'Falklandeilanden',
  FJ: 'Fiji',
  PH: 'Filipijnen',
  FI: 'Finland',
  FR: 'Frankrijk',
  GF: 'Frans-Guyana',
  PF: 'Frans-Polynesi\u00eb',
  TF: 'Franse Gebieden in de zuidelijke Indische Oceaan',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgi\u00eb',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Grenada',
  GR: 'Griekenland',
  GL: 'Groenland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinee',
  GW: 'Guinee-Bissau',
  GY: 'Guyana',
  HT: 'Ha\u00efti',
  HM: 'Heard- en McDonaldeilanden',
  HN: 'Honduras',
  HU: 'Hongarije',
  HK: 'Hongkong SAR van China',
  IS: 'IJsland',
  IE: 'Ierland',
  IN: 'India',
  ID: 'Indonesi\u00eb',
  IQ: 'Irak',
  IR: 'Iran',
  IM: 'Isle of Man',
  IL: 'Isra\u00ebl',
  IT: 'Itali\u00eb',
  CI: 'Ivoorkust',
  JM: 'Jamaica',
  JP: 'Japan',
  YE: 'Jemen',
  JE: 'Jersey',
  JO: 'Jordani\u00eb',
  CV: 'Kaapverdi\u00eb',
  CM: 'Kameroen',
  KZ: 'Kazachstan',
  KE: 'Kenia',
  KG: 'Kirgizi\u00eb',
  KI: 'Kiribati',
  KW: 'Koeweit',
  HR: 'Kroati\u00eb',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Letland',
  LB: 'Libanon',
  LR: 'Liberia',
  LY: 'Libi\u00eb',
  LI: 'Liechtenstein',
  LT: 'Litouwen',
  LU: 'Luxemburg',
  MO: 'Macao SAR van China',
  MK: 'Macedoni\u00eb',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MV: 'Maldiven',
  MY: 'Maleisi\u00eb',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marokko',
  MH: 'Marshalleilanden',
  MQ: 'Martinique',
  MR: 'Mauritani\u00eb',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesi\u00eb',
  MD: 'Moldavi\u00eb',
  MC: 'Monaco',
  MN: 'Mongoli\u00eb',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibi\u00eb',
  NR: 'Nauru',
  NL: 'Nederland',
  AN: 'Nederlandse Antillen',
  NP: 'Nepal',
  NI: 'Nicaragua',
  NC: 'Nieuw-Caledoni\u00eb',
  NZ: 'Nieuw-Zeeland',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  KP: 'Noord-Korea',
  MP: 'Noordelijke Marianeneilanden',
  NO: 'Noorwegen',
  NF: 'Norfolkeiland',
  UG: 'Oeganda',
  UA: 'Oekra\u00efne',
  UZ: 'Oezbekistan',
  OM: 'Oman',
  ZZ: 'Onbekend of onjuist gebied',
  TL: 'Oost-Timor',
  AT: 'Oostenrijk',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestijns Gebied',
  PA: 'Panama',
  PG: 'Papoea-Nieuw-Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PN: 'Pitcairn',
  PL: 'Polen',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Roemeni\u00eb',
  RU: 'Rusland',
  RW: 'Rwanda',
  RE: 'R\u00e9union',
  BL: 'Saint Barth\u00e9lemy',
  KN: 'Saint Kitts en Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre en Miquelon',
  VC: 'Saint Vincent en de Grenadines',
  SB: 'Salomonseilanden',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tom\u00e9 en Principe',
  SA: 'Saoedi-Arabi\u00eb',
  SN: 'Senegal',
  RS: 'Servi\u00eb',
  CS: 'Servi\u00eb en Montenegro',
  SC: 'Seychellen',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SH: 'Sint-Helena',
  MF: 'Sint-Maarten',
  SI: 'Sloveni\u00eb',
  SK: 'Slowakije',
  SD: 'Soedan',
  SO: 'Somali\u00eb',
  ES: 'Spanje',
  LK: 'Sri Lanka',
  SR: 'Suriname',
  SJ: 'Svalbard en Jan Mayen',
  SZ: 'Swaziland',
  SY: 'Syri\u00eb',
  TJ: 'Tadzjikistan',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad en Tobago',
  TD: 'Tsjaad',
  CZ: 'Tsjechi\u00eb',
  TN: 'Tunesi\u00eb',
  TR: 'Turkije',
  TM: 'Turkmenistan',
  TC: 'Turks- en Caicoseilanden',
  TV: 'Tuvalu',
  UY: 'Uruguay',
  VU: 'Vanuatu',
  VA: 'Vaticaanstad',
  VE: 'Venezuela',
  GB: 'Verenigd Koninkrijk',
  AE: 'Verenigde Arabische Emiraten',
  US: 'Verenigde Staten',
  VN: 'Vietnam',
  WF: 'Wallis en Futuna',
  EH: 'Westelijke Sahara',
  BY: 'Wit-Rusland',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  ZA: 'Zuid-Afrika',
  GS: 'Zuid-Georgi\u00eb en Zuidelijke Sandwicheilanden',
  KR: 'Zuid-Korea',
  SE: 'Zweden',
  CH: 'Zwitserland',
};
