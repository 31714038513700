import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const ProjectLoginForm = styled.form`
  .forgot-wrapper {
    text-align: right;
    margin-top: 6px;
    font-size: 14px;
  }
  .forgot {
  }
`;
