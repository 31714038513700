import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';
// Import actions here

import classNames from 'classnames';
import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';
import ProjectRange from 'components/formItems/ProjectRange';

import * as Styled from './FilterForm.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
  values?: any;
  form?: any;
}

export type FilterFormProps = StateProps & DispatchProps & OwnProps;

const FilterForm = (props: FilterFormProps) => {
  const { handleSubmit, submitting, valid, form } = props;

  const resetValues = useMemo(
    () => ({
      date: undefined,
      price: undefined,
      avatars: undefined,
    }),
    [],
  );
  return (
    <Styled.FilterForm onSubmit={handleSubmit}>
      {/* <Field component={ProjectInput} seed="select" name="eventType" label="Event type" items={item} /> */}

      {/* <Field component={ProjectInput} seed="select" name="event-championship" label="Championship" defaultValue="-" items={} /> */}
      <Field component={ProjectInput} seed="date" name="date.from" label="Dates" />

      <Field component={ProjectRange} type="range" name="price" label="Price" minValue="0" maxValue="100" currency="$" />
      <Field component={ProjectRange} type="range" name="avatars" label="Numbers of avatars" minValue="1" maxValue="20" currency="" />

      <div className={classNames('row')}>
        <div className={classNames('col-6')}>
          <ProjectButton label="Reset" colorClassName="ternary" action={() => form.reset(resetValues)} smallPadding />
        </div>
        <div className={classNames('col-6')}>
          <ProjectButton disabled={submitting || !valid} label="Apply" type="submit" smallPadding />
        </div>
      </div>
    </Styled.FilterForm>
  );
};

export default FilterForm;
