import React from 'react';
import { Field } from 'react-final-form';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

// Import actions here

import { required, email, composeValidators } from '@makeit-studio/ui-library';

import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';
import * as Styled from './ProjectResetPasswordForm.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
}

export type ProjectResetPasswordFormProps = StateProps & DispatchProps & OwnProps;

const ProjectResetPasswordForm = (props: ProjectResetPasswordFormProps) => {
  const { handleSubmit, submitting, valid } = props;

  return (
    <Styled.ProjectResetPasswordForm onSubmit={handleSubmit}>
      <Field withValueBorder component={ProjectInput} name="email" type="email" label="Email" validate={composeValidators(required, email)} />
      <ProjectButton disabled={submitting || !valid} label="Submit" type="submit" angleRight />
    </Styled.ProjectResetPasswordForm>
  );
};

export default ProjectResetPasswordForm;
