import React, { useCallback, useMemo } from 'react';

import { Input, Password, CustomSelect, Toggle, Textarea, SwitchInput, Checkbox, MultipleUploadZone, DateTimePicker } from '@makeit-studio/ui-store';

import { ClassNames, useTheme } from '@emotion/react';

import CheckableBox from 'components/items/CheckableBox';
import * as Styled from './ProjectInput.styled';
import 'react-datetime/css/react-datetime.css';
import Radio from '../Radio';

export type ProjectInputProps = {
  light?: boolean;
  className?: string;
  seed?: string;
  united?: boolean;
  meta?: any;
};

const ProjectInput = (props: ProjectInputProps) => {
  const { light, className, seed, united, meta } = props;

  const theme = useTheme();

  const isValid = useMemo(() => meta?.visited && meta?.valid, [meta]);

  const genCommonProps = useCallback(
    (css, cx) => {
      return {
        className: cx(
          css`
            ${Styled.ProjectInput(theme, isValid)}
          `,
          isValid && 'valid',
          light && 'light',
          className,
        ),
        small: light,
        labelClassName: cx('project-label'),
        fieldWrapperClassName: cx('field-wrapper'),
        contentWrapperClassName: cx('project-content-wrapper'),
        iconLeftClassName: cx('project-icon-left'),
        errorClassName: cx('project-error'),
        withValueBorder: true,
        noShadow: true,
      };
    },
    [theme, isValid, className, light],
  );

  const getComponent = useCallback(() => {
    if (seed === 'date') return DateTimePicker;

    if (seed === 'textarea') return Textarea;

    if (seed === 'switch') return SwitchInput;

    return Input;
  }, [seed]);

  const renderComponent = useCallback(
    (css, cx) => {
      const commonProps = genCommonProps(css, cx);

      if (seed === 'checkbox') {
        return (
          <Checkbox
            {...commonProps}
            labelClassName={cx('project-checkbox-label')}
            checkedClassName={cx('project-checked')}
            boxClassName={cx('project-box')}
            checkedElement={CheckableBox}
            {...props}
          />
        );
      }
      if (seed === 'password') {
        return <Password {...commonProps} {...props} />;
      }
      if (seed === 'select') {
        return (
          <CustomSelect
            {...commonProps}
            noCheck
            activeWrapperClassName={cx('select-active')}
            valueClassName={cx('select-value')}
            itemsClassName={cx('select-items')}
            itemClassName={cx('select-item')}
            itemsWrapperClassName={cx('select-items-wrapper')}
            itemsContentWrapperClassName={cx('select-items-content-wrapper')}
            {...props}
          />
        );
      }

      if (seed === 'radio') {
        return <Radio {...commonProps} united={united} radioClassName={cx('radio')} rowClassName={cx('radio-row')} inlined {...props} />;
      }

      if (seed === 'toggle') {
        return <Toggle {...commonProps} {...props} />;
      }

      if (seed === 'upload') {
        return (
          <MultipleUploadZone
            {...commonProps}
            // specific
            dropzoneClassName={cx('dropzone')}
            {...props}
          />
        );
      }

      const Component = getComponent();

      return <Component {...commonProps} {...props} />;
    },
    [seed, genCommonProps, getComponent, props, united],
  );

  return <ClassNames>{({ css, cx }) => renderComponent(css, cx)}</ClassNames>;
};

export default ProjectInput;
