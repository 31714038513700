import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import Countdown from 'react-countdown';
import * as Styled from './ProjectCountdown.styled';

export interface ProjectCountdownProps {
  value?: string;
}

export const ProjectCountdown = (props: ProjectCountdownProps) => {
  const { value } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  // Random component
  // const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // if (completed) {
    //   // Render a complete state
    //   return <Completionist />;
    // }
    // Render a countdown

    return (
      <div className={classNames('countdown')}>
        <div className={classNames('countdown-wrapper')}>
          <div className={classNames('item')}>
            <div className={classNames('sub-item')}>
              <TextItem path={days} tag="p" />
              <TextItem path="days" />
            </div>
          </div>
          <div className={classNames('separator')}>
            <TextItem path=":" tag="p" />
          </div>
          <div className={classNames('item')}>
            <div className={classNames('sub-item')}>
              <TextItem path={hours} tag="p" />
              <TextItem path="hours" />
            </div>
          </div>
          <div className={classNames('separator')}>
            <TextItem path=":" tag="p" />
          </div>
          <div className={classNames('item')}>
            <div className={classNames('sub-item')}>
              <TextItem path={minutes} tag="p" />
              <TextItem path="minutes" />
            </div>
          </div>
          <div className={classNames('separator')}>
            <TextItem path=":" tag="p" />
          </div>
          <div className={classNames('item')}>
            <div className={classNames('sub-item')}>
              <TextItem path={seconds} tag="p" />
              <TextItem path="seconds" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Styled.ProjectCountdown>
      <Countdown date={value} renderer={renderer} />
    </Styled.ProjectCountdown>
  );
};

export default ProjectCountdown;
