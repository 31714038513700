import React, { useMemo, useEffect, useState } from 'react';

import appActions from '@makeit-studio/store/dist/app/actions';
import classNames from 'classnames/bind';

import { GlobalStyles } from '@makeit-studio/ui-library';
import { ThemeProvider } from '@emotion/react';
import theme from 'styling/theme';
import AppGlobalStyles from 'styling/components/GlobalStyles';
import { Helmet } from 'react-helmet';

import { Switch } from 'react-router';
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';

// Global components
import { SeoHandler, SideMenus, Modals, Loader } from '@makeit-studio/ui-store';

// Layouts
import App from 'components/layouts/App';
import NotLogged from 'components/layouts/NotLogged';

import { useSelector } from 'react-redux';
import ProjectHeader from 'components/global/ProjectHeader';
import { getUrlFromPath, StoreConfig, useAction } from '@makeit-studio/store';

import Register from 'components/routes/Register';
import { StoreState } from 'store/appReducers';
import ProjectButton from 'components/items/ProjectButton';
import useBootUp from 'store/useBootUp';
import { useRefreshToken } from 'store/auth/hooks';
import { useConfig } from 'hooks/config';
import { ConfirmResetPassword } from 'components/routes/ConfirmResetPassword/ConfirmResetPassword.styled';
import * as Styled from './Main.styled';

export interface StateProps {
  ready: boolean;
  session: object;
}

export interface OwnProps {}

export type MainProps = StateProps & OwnProps & RouteComponentProps;

const Main = (props: MainProps) => {
  const { match } = props;

  const { bootDone } = useConfig();

  return (
    <ThemeProvider theme={theme}>
      <SeoHandler />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <title>Avatar</title>
      </Helmet>
      <GlobalStyles />
      <AppGlobalStyles />
      <Loader />
      <Modals button={ProjectButton} />
      <SideMenus />
      <Styled.Main>
        {bootDone && (
          <div className={classNames('content')}>
            <Switch>
              <Route exact path={getUrlFromPath('welcome', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('onboarding', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('introduction', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('login', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('resetPassword', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('confirmResetPassword', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('renewPassword', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('register', match)} component={NotLogged} />
              <Route exact path={getUrlFromPath('preferences', match)} component={NotLogged} />
              <Route component={App} />
            </Switch>
          </div>
        )}
      </Styled.Main>
    </ThemeProvider>
  );
};

export default withRouter(Main);
