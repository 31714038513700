import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import { TextItem } from '@makeit-studio/ui-store';
import { Form } from 'react-final-form';
import ProjectLoginForm from 'components/forms/ProjectLoginForm';
import Logo from 'components/items/Logo';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { pushRoute } from '@makeit-studio/store';
import classNames from 'classnames';

import ProjectButton from 'components/items/ProjectButton';
import { useAfterLogin } from 'store/account/hooks';
import { useLogin } from 'store/auth/hooks';
import * as Styled from './Login.styled';

export interface LoginProps {}

export const Login = (props: LoginProps & RouteComponentProps) => {
  const { history, location } = props;

  const lg = useSelector((state: StoreState) => state.content.lg);
  const session = useSelector((state: StoreState) => state.auth?.data);

  const afterLogin = useAfterLogin();
  const login = useLogin();

  const submit = useCallback(
    async (values) => {
      await login({
        variables: values,
      });
    },
    [login],
  );

  useEffect(() => {
    const profileInit = async () => {
      if (session) {
        await afterLogin();
        // redirect will occurs in NotLogged
      }
    };
    profileInit();
  }, [session, afterLogin, history, lg]);

  return (
    <Styled.Login>
      <div className={classNames('heading', 'align-center')}>
        <TextItem tag="h1" path="Login now" />
        <TextItem tag="p" path="Please enter the details below to continue" />
      </div>
      <Form
        initialValues={
          location.state ||
          {
            // email: 'software@makeit-studio.com',
            // password: 'MyGMS_2021',
          }
        }
        component={ProjectLoginForm}
        onSubmit={submit}
      />
      <ProjectButton colorClassName="secondary" logoLeft label="Login with my Arena Sport account" />
      <NavLink to={pushRoute('register')}>
        <TextItem className={classNames('link', 'link-switcher', 'align-center')} path="<span>Don’t have an account? <b>Sign up</b></span>" isHtml />
      </NavLink>
    </Styled.Login>
  );
};

export default Login;
