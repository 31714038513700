import { createActionStructure, createAsyncStructure, createResourceStructure } from '@makeit-studio/store/dist/utils/actionsToolkit';

import constants from './constants';

const actions = {
  ...createResourceStructure(`${constants.prefix}`),
  // auth
  login: createAsyncStructure(`${constants.prefix}Login`),
  refreshToken: createAsyncStructure(`${constants.prefix}RefreshToken`),
  signup: createAsyncStructure(`${constants.prefix}Signup`),
  register: createAsyncStructure(`${constants.prefix}Register`),
  changeEmail: createAsyncStructure(`${constants.prefix}ChangeEmail`),
  changePassword: createAsyncStructure(`${constants.prefix}ChangePassword`),
  resetPassword: createAsyncStructure(`${constants.prefix}ResetPassword`),
  confirmResetPassword: createAsyncStructure(`${constants.prefix}ConfirmResetPassword`),
  renewPassword: createAsyncStructure(`${constants.prefix}RenewPassword`),
  // account
  afterLogin: createAsyncStructure(`${constants.prefix}AfterLogin`),
  me: createAsyncStructure(`${constants.prefix}Me`),
  updateMe: createAsyncStructure(`${constants.prefix}UpdateMe`),
  checkRegistration: createAsyncStructure(`${constants.prefix}CheckRegistration`),
  confirmRegistration: createAsyncStructure(`${constants.prefix}ConfirmRegistration`),
  resendCode: createAsyncStructure(`${constants.prefix}ResendCode`),

  // company accounts
  accounts: createAsyncStructure(`${constants.prefix}Accounts`),
  delete: createAsyncStructure(`${constants.prefix}Delete`),

  // favorite
  addPinned: createAsyncStructure(`${constants.prefix}AddPinned`),
  removePinned: createAsyncStructure(`${constants.prefix}RemovePinned`),

  renewEmail: createAsyncStructure(`${constants.prefix}RenewEmail`),
};

export default actions;
