import { addressModel, imageModel } from 'store/appModels';

const avatarModel = {
  name: true,
  description: true,
  picture: imageModel,
  stream: { isLive: true, hls: true },
  participants: {
    id: true,
    position: true,
    eventId: true,
  },
  externalLink: true,
  _id: true,
};

const eventListModel = {
  _id: true,
  name: true,
  startAt: true,
  endAt: true,
  category: {
    _id: true,
    name: true,
  },
  type: {
    _id: true,
    name: true,
  },
  background: imageModel,
  createdAt: true,
};

const eventModel = {
  ...eventListModel,
  createdAt: true,
  updatedAt: true,
  description: true,
  location: addressModel,
  categoryId: true,
  typeId: true,

  liveEventId: true,
  liveEvent: {
    _id: true,
    createdAt: true,
    updatedAt: true,
    name: true,
    description: true,
    eventId: true,
    avatarIds: true,
    avatarsCount: true,
  },
  price: {
    amount: true,
    currency: true,
  },
  participants: {
    name: true,
    _id: true,
    avatars: avatarModel,
    picture: imageModel,
  },
};

const eventType = {
  _id: true,
  name: true,
  logo: imageModel,
};

export { eventListModel, eventModel, eventType };
