import React, { useCallback, useState } from 'react';
import { IoFunnelOutline, IoCloseOutline } from 'react-icons/io5';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import { Form } from 'react-final-form';
import { RouteComponentProps } from 'react-router-dom';
import { useAction } from '@makeit-studio/store';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import FilterForm from 'components/forms/FilterForm';

import event from 'store/event';
import * as Styled from './Filter.styled';

export interface FilterProps {
  onActive?: () => any;
}

export const Filter = (props: FilterProps & RouteComponentProps) => {
  const { onSubmit, initialValues } = props;

  // need a global state for scroll behavior
  const filterActive = useSelector((state: StoreState) => state.event.filterActive);

  const setFilterActive = useAction(event.actions.filterActive, true);

  const handleActive = useCallback(
    (forced?: boolean) => {
      const newValue = forced ?? !filterActive;
      setFilterActive(newValue);
    },
    [setFilterActive, filterActive],
  );

  return (
    <Styled.Filter>
      <IoFunnelOutline className={classNames('filter-icon', filterActive && 'active')} onClick={() => handleActive()} />
      <div className={classNames('filter', filterActive && 'active')}>
        <div className={classNames('overlay')} />
        <div className={classNames('filter-wrapper', filterActive && 'active')}>
          <TextItem className={classNames('title')} path="<p><b>Filters</b></p>" isHtml />
          <IoCloseOutline className={classNames('close-icon')} onClick={() => handleActive(false)} />
          <div className={classNames('form-wrapper')}>
            <Form
              initialValues={initialValues}
              component={FilterForm}
              onSubmit={(e) => {
                handleActive(false);
                if (onSubmit) onSubmit(e);
              }}
            />
          </div>
        </div>
      </div>
    </Styled.Filter>
  );
};

export default Filter;
