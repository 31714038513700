import { app, useSyncAction } from '@makeit-studio/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAccountInit } from './account/hooks';
import { StoreState } from './appReducers';
import { useTypeList as useEventTypeList } from './event/hooks';

export const useBootUp = () => {
  const ready = useSelector((state: StoreState) => state.app.ready);
  const session = useSelector((state: StoreState) => state.auth?.data);
  const accountInit = useAccountInit();
  const eventTypesList = useEventTypeList();
  const loaderShow = useSyncAction(app.actions.loader.show);
  const loaderHide = useSyncAction(app.actions.loader.hide);
  const bootUpStart = useSyncAction(app.actions.bootup.request);
  const bootUpDone = useSyncAction(app.actions.bootup.result);

  const launchBootUp = useCallback(async () => {
    if (ready) return;

    console.log('START BOOT');
    bootUpStart();
    loaderShow();

    if (session) {
      await accountInit();
    }
    await eventTypesList();

    loaderHide();
    bootUpDone();
    console.log('END BOOT');
  }, [session, ready, loaderHide, loaderShow, bootUpDone, bootUpStart, accountInit, eventTypesList]);

  return launchBootUp;
};

export default useBootUp;
