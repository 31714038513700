import { StoreConfig, StoreState } from '@makeit-studio/store';
import auth from 'store/auth';
import { SessionData } from 'store/auth/reducer';

import urls from './urls';

const env = 'staging'; // local | dev | staging | prod

export const config = StoreConfig.setOptions({
  env,
  browser: true,
  // authorizationPrefix: 'Bearer',
  authorizationPrefix: 'Bearer',
  disconnectedErrors: ['2011'],
  // eslint-disable-next-line
  project: 'Avatar',
  defaultLanguage: 'en',
  noLanguageInUrl: true,
  loginApollo: true,
  noLogoutApi: true,
  storage: {
    name: 'avatar-storage',
    reducers: ['session', 'auth'],
  },
  api: {
    baseUrlApollo: urls[env],
    contentUrl: urls.content[env],
  },
  checkRefreshToken: (sess: SessionData) => {
    // If working with Cognito for the login
    const now = new Date();
    return sess?.expiresIn <= now.getTime() / 1000;
  },
  sessionSelector: auth.selectors.getSession,

  // PROJECT ONLY
  noWelcomeFlow: true,
});

export default config;
