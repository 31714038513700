import React from 'react';
import { Field } from 'react-final-form';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';
import classNames from 'classnames';
// Import actions here

import { required, email, composeValidators } from '@makeit-studio/ui-library';
import { NavLink } from 'react-router-dom';

import ProjectButton from 'components/items/ProjectButton';
import ProjectInput from 'components/formItems/ProjectInput';
import { pushRoute } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import * as Styled from './ProjectLoginForm.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
}

export type ProjectLoginFormProps = StateProps & DispatchProps & OwnProps;

const ProjectLoginForm = (props: ProjectLoginFormProps) => {
  const { handleSubmit, submitting, valid } = props;

  // mapStateToProps
  const content = useSelector((state: StoreState) => state.content.raw);

  return (
    <Styled.ProjectLoginForm onSubmit={handleSubmit}>
      <Field component={ProjectInput} name="email" type="email" label="Email" validate={composeValidators(required, email)} />
      <Field component={ProjectInput} seed="password" name="password" label="Password" validate={required} />
      <div className={classNames('forgot-wrapper')}>
        <NavLink className={classNames('forgot')} to={pushRoute('resetPassword')}>
          <TextItem path="Forgot password?" />
        </NavLink>
      </div>
      <ProjectButton disabled={submitting || !valid} label="Login" type="submit" angleRight />
    </Styled.ProjectLoginForm>
  );
};

export default ProjectLoginForm;
