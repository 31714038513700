import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';

import Login from 'components/routes/Login';
import Register from 'components/routes/Register';
import ResetPassword from 'components/routes/ResetPassword';
import RenewPassword from 'components/routes/RenewPassword';
import Preferences from 'components/routes/Preferences';
import Welcome from 'components/routes/Welcome';
import Introduction from 'components/routes/Introduction';
import Onboarding from 'components/routes/Onboarding';
import ConfirmResetPassword from 'components/routes/ConfirmResetPassword';

import { Switch } from 'react-router';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { getRouteInfoFromLocation, getUrlFromPath, pushRoute } from '@makeit-studio/store';
import ProjectHeader from 'components/global/ProjectHeader';
import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import { StoreState } from 'store/appReducers';
import * as Styled from './NotLogged.styled';
import background from './assets/background.png';

export interface NotLoggedProps {}

export const NotLogged = (props: NotLoggedProps & RouteComponentProps) => {
  const { match, location } = props;

  const routeInfo = getRouteInfoFromLocation(location) as any; // Todo: review
  const lg = useSelector((state: StoreState) => state.content.lg);
  const accountData = useSelector((state: StoreState) => state.account?.profile?.data);

  const noHeadersRoutes = ['onboarding', 'introduction', 'welcome'];

  if (accountData) {
    return <Redirect to={pushRoute('home', lg)} />;
  }
  return (
    <Styled.NotLogged>
      {!noHeadersRoutes?.includes(routeInfo?.path) && <ProjectHeader />}
      <div className={classNames('content-wrapper')}>
        <Switch>
          <Route exact path={getUrlFromPath('welcome', match)} component={Welcome} />
          <Route exact path={getUrlFromPath('onboarding', match)} component={Onboarding} />
          <Route exact path={getUrlFromPath('introduction', match)} component={Introduction} />
          <Route exact path={getUrlFromPath('login', match)} component={Login} />
          <Route exact path={getUrlFromPath('register', match)} component={Register} />
          <Route exact path={getUrlFromPath('preferences', match)} component={Preferences} />
          <Route exact path={getUrlFromPath('resetPassword', match)} component={ResetPassword} />
          <Route exact path={getUrlFromPath('confirmResetPassword', match)} component={ConfirmResetPassword} />
          <Route exact path={getUrlFromPath('renewPassword', match)} component={RenewPassword} />
        </Switch>
      </div>
    </Styled.NotLogged>
  );
};

export default NotLogged;
