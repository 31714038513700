import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { FormState } from 'final-form';

import { useSelector } from 'react-redux';

import { required, email, composeValidators } from '@makeit-studio/ui-library';

import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';

import { StoreState } from 'store/appReducers';

import * as Styled from './ConfirmRegisterCodeForm.styled';

export interface ConfirmRegisterCodeFormOwnProps {}

export type ConfirmRegisterCodeFormProps = FormRenderProps & FormState<any> & ConfirmRegisterCodeFormOwnProps;

const ConfirmRegisterCodeForm = (props: ConfirmRegisterCodeFormProps) => {
  const { handleSubmit, submitting, valid } = props;

  const content = useSelector((state: StoreState) => state.content.raw);

  return (
    <Styled.ConfirmRegisterCodeForm onSubmit={handleSubmit}>
      <Field component={ProjectInput} name="code" label="Confirmation Code" validate={required} />
      <ProjectButton disabled={submitting || !valid} label="Submit" type="submit" />
    </Styled.ConfirmRegisterCodeForm>
  );
};

export default ConfirmRegisterCodeForm;
