import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import { pushRoute, auth, useAction } from '@makeit-studio/store';
import { IoCalendarClearOutline, IoSettingsOutline, IoBookmarkOutline } from 'react-icons/io5';
import { useUnity } from 'lib';
import * as Styled from './MainNav.styled';

export interface MainNavProps {
  navOpen?: boolean;
  border?: boolean;
  onClick?: (...args) => any;
}

export const MainNav = (props: MainNavProps) => {
  const { navOpen, onClick, border } = props;
  const history = useHistory();
  const unity = useUnity();

  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const session = useSelector((state: StoreState) => state.auth?.data);
  const profile = useSelector((state: StoreState) => state.account?.profile?.data);

  useEffect(() => {
    if (session && session.idToken) unity.setAuthData(session.idToken);
  }, [unity, session, session.idToken]);

  return (
    <Styled.MainNav className={classNames('MainNav')}>
      <NavLink exact to={pushRoute(session ? 'home' : 'login', lg)} className={classNames('nav-item')}>
        <IoCalendarClearOutline />
      </NavLink>
      {profile && (
        <>
          <NavLink exact to={pushRoute('my-events', lg)} className={classNames('nav-item')}>
            <IoBookmarkOutline />
          </NavLink>
          <NavLink exact to={pushRoute('settings', lg)} className={classNames('nav-item')}>
            <IoSettingsOutline />
          </NavLink>
        </>
      )}
    </Styled.MainNav>
  );
};

export default MainNav;
