import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { pushRoute, auth, useAction } from '@makeit-studio/store';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import { PageTitle } from 'components/items/PageTitle';
import { RouteComponentProps } from 'react-router-dom';
import * as Styled from './Privacy.styled';

export interface PrivacyProps {}

export const Privacy = (props: PrivacyProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  return (
    <Styled.Privacy>
      <PageTitle
        title="Privacy policy"
        topLabel="Back"
        topAction={() => {
          history.push(pushRoute('settings', lg));
        }}
      />
      <TextItem
        path="<p>Terms and conditions are the vital details that businesses put in place to ensure that they protect their rights. However, you should also ensure that they are legal, enforceable, and serve their intended purpose. Otherwise, you leave your company exposed to legal liabilities or unwanted consequences.</p>
<p>Rather than leave your contracts to best guesses, check out the information below to learn more about terms and conditions.</p>"
        isHtml
      />
    </Styled.Privacy>
  );
};

export default Privacy;
