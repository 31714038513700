import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import { useHistory, RouteComponentProps, Redirect } from 'react-router-dom';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import { pushRoute } from '@makeit-studio/store';
import Logo from 'components/items/Logo';
import ProjectButton from 'components/items/ProjectButton';
import useBootUp from 'store/useBootUp';
import { useAfterLogin } from 'store/account/hooks';
import config from 'config/general';
import * as Styled from './Welcome.styled';

// assets
import backgroundWelcome from './assets/welcome-image.png';

export interface WelcomeProps {}

export const Welcome = () => {
  const history = useHistory();
  // mapStateToProps

  const lg = useSelector((state: StoreState) => state.content.lg);

  const accountData = useSelector((state: StoreState) => state.account?.profile?.data);

  const isWelcomeScreenFlow = !config?.noWelcomeFlow;

  if (!isWelcomeScreenFlow) {
    return <Redirect to={pushRoute(accountData ? 'home' : 'login', lg)} />;
  }

  return (
    <Styled.Welcome>
      <div className={classNames('image-wrapper', 'full')}>
        <Logo mode="fullVerticalLight" />
        <div className={classNames('overlay')} />
        <img src={backgroundWelcome} alt={backgroundWelcome} className={classNames('background')} />
      </div>
      <div className={classNames('heading', 'align-center')}>
        <TextItem tag="h1" path="Welcome to Avatar Ticket" />
        <TextItem tag="p" path="We bring you right in the middle of the action in 360 VR Video" />
      </div>
      <ProjectButton
        angleRight
        label="Start now"
        className={classNames('start-btn')}
        action={() => {
          history.push(pushRoute('onboarding', lg));
        }}
      />
    </Styled.Welcome>
  );
};

export default Welcome;
