import React from 'react';
import { Field } from 'react-final-form';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import { required } from '@makeit-studio/ui-library';

import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';
import * as Styled from './ProjectRenewPasswordForm.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
}

export type ProjectRenewPasswordFormProps = StateProps & DispatchProps & OwnProps;

const ProjectRenewPasswordForm = (props: ProjectRenewPasswordFormProps) => {
  const { handleSubmit, submitting, valid } = props;

  return (
    <Styled.ProjectRenewPasswordForm onSubmit={handleSubmit}>
      <Field component={ProjectInput} name="password" seed="password" label="Current Password" isRequired validate={required} />
      <Field component={ProjectInput} name="newPassword" seed="password" label="New Password" isRequired validate={required} />
      <Field component={ProjectInput} name="newPasswordConfirmation" seed="password" label="Confirm New Password" isRequired validate={required} />
      <ProjectButton disabled={submitting || !valid} label="Change password" type="submit" angleRight />
    </Styled.ProjectRenewPasswordForm>
  );
};

export default ProjectRenewPasswordForm;
