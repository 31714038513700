import { defaultTheme } from '@makeit-studio/ui-library';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

// const primaryTextColor = '#383338';
// const secondaryTextColor = '#938F95';
const primary = '#28242E';
const primaryLight = '#F2F3F7';
const primary300 = '#BFBDC0';
const primary600 = '#7E7C82';
const success = '#61E786';
const warning = '#FCBF49';
const error = '#E54B4B';
const error200 = '#FADBDB';

const merged = merge(cloneDeep(defaultTheme), {
  border: {
    radius: {
      default: '4px',
      button: '4px',
      avatar: '8px',
    },
    width: {
      form: '0',
    },
  },

  align: {
    center: 'center',
    left: 'left',
    right: 'right',
  },

  colors: {
    primary,
    primaryLight,
    primary300,
    primary600,
    secondary: '#F5CC46',
    dark: primary,
    success,
    error,
    warning,
    error200,
    link: primary,

    border: {
      default: primary,
      light: '#E5E5E5',
    },
    input: {
      label: primary,
      background: primaryLight,
    },
    // for select
    state: {
      active: primaryLight,
    },
    text: {
      primary,
      secondary: primary,
      pending: '#695BFF',
      success,
      warning,
    },
    background: {
      lightGrey: primaryLight,
      disabled: primaryLight,
      pending: '#bdd8ff',
      success,
      warning,
      chat: {
        input: primaryLight,
        file: primary,
      },
    },
    button: {
      hover: primary,
      active: primary,
    },
  },
  decal: {
    select: '12px',
  },
  font: {
    family: {
      title: "'Source sans Pro', sans-serif",
      default: "'Source sans Pro', sans-serif",
    },
    letterSpacing: {
      form: '0px',
      button: '0px',
    },
    lineHeight: {
      text: '1.8em',
    },
    style: {
      uppercase: 'uppercase',
      italic: 'italic',
    },
    size: {
      title: '20px',
      medium: '18px',
      default: '14px',
      button: '16px',
      label: '16px',
      message: '16px',
      small: '12px',
      extraSmall: '10px',
    },
    weight: {
      regular: '400',
      bold: '700',
      extraBold: '900',
    },
  },
  padding: {
    x: {
      form: '14px',
    },
    y: {
      form: '5px',
    },
    modal: '30px',
    extraSmall: '10px',
    small: '14px',
    medium: '20px',
    big: '30px',
  },
  width: {
    form: {
      lateralIcon: '45px',
    },
  },
  margin: {
    y: {
      form: '24px',
    },
  },
  height: {
    input: {
      min: '43px',
    },
  },
});

export default merged;
