import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import Logo from 'components/items/Logo';
import ProjectButton from 'components/items/ProjectButton';
import Slider from 'react-slick';
import YoutubeEmbed from 'components/items/YoutubeEmbed';
import { RouteComponentProps } from 'react-router-dom';
import * as Styled from './Onboarding.styled';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RiCheckboxCircleLine } from 'react-icons/ri';

export interface OnboardingProps {}

const CheckOptions = ({ text }) => (
  <div className={classNames('check-option')}>
    <span className={classNames('icon')}>
      <RiCheckboxCircleLine />
    </span>
    <span>{text}</span>
  </div>
);

export const Onboarding = (props: OnboardingProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  const customSlider = useRef();
  const [currentPage, setCurrentPage] = useState(0);

  const Content = (
    <div>
      <hr />
      <div className={classNames('end-content')}>
        <span>SWITCH & SLIDE BETWEEN</span>
        <span className={classNames('bold')}>20 AVATAR EXPERIENCES IN 360</span>
        <CheckOptions text="Be in the pub with fans at 4:00 PM" />
        <CheckOptions text="Be at the fan village at 5:00 PM" />
        <CheckOptions text="Be at the hot dog stand at 8:00 PM" />
        <CheckOptions text="Be at the game at 9:00 PM" />
      </div>
    </div>
  );

  const slide = [
    {
      embedId: 'h5t-d2pP_UU',
      title: "Let's take a tour",
      text: 'Discover all the features',
    },
    {
      embedId: 'JTWdUBIvOFY',
      title: 'Set preferences',
      text: 'Setup your preferences and get access to your personal avatars',
    },
    {
      embedId: '',
      title: 'Live streaming',
      text: '3 Ways to experience 360 VR',
    },
    {
      embedId: '',
      title: 'Customize your experience',
      text: 'Be your own director and choose where you want to be!',
      content: () => Content,
    },
  ];

  const gotoNext = useCallback(() => {
    console.log(currentPage, slide.length);
    if (currentPage < slide.length - 1) setCurrentPage(currentPage + 1);
    if (customSlider?.current) {
      const customNode = customSlider.current as any;
      customNode.slickNext();
    }
  }, [currentPage]);

  const gotoPrev = useCallback(() => {
    if (currentPage !== 0) setCurrentPage(currentPage - 1);
    if (customSlider?.current) {
      const customNode = customSlider.current as any;
      customNode.slickPrev();
    }
  }, [currentPage]);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrow: false,
    }),
    [],
  );

  return (
    <Styled.Onboarding>
      <Slider {...settings} className={classNames('onboarding-slider', 'slider')} ref={customSlider}>
        {slide.map((item, key) => {
          if (key === slide.length - 1) console.log('last one');
          return (
            <div className={classNames('slider-item')} key={key}>
              <div className={classNames('video-wrapper', 'full')}>
                <Logo mode="fullLight" />
                <YoutubeEmbed embedId={item.embedId} />
              </div>
              <div className={classNames('heading', 'align-center')}>
                <TextItem tag="h1" path={item.title} />
                <TextItem tag="p" path={item.text} />
                {item.content ? item.content() : ''}
              </div>
            </div>
          );
        })}
      </Slider>
      {currentPage === slide.length - 1 ? (
        <div className={classNames('inline-buttons')}>
          <ProjectButton
            label="Login"
            colorClassName="ternary"
            className="half"
            action={() => {
              history.push('login', lg);
            }}
          />
          <ProjectButton
            label="Create Account"
            // colorClassName="ternary"
            className="half"
            action={() => {
              history.push('register', lg);
            }}
          />
        </div>
      ) : (
        <div className={classNames('inline-buttons')}>
          <ProjectButton
            label="Skip"
            colorClassName="ternary"
            className="half"
            action={() => {
              history.push('introduction', lg);
            }}
          />
          <ProjectButton angleRight label="Next" className="half" onClick={() => gotoNext()} />
        </div>
      )}
    </Styled.Onboarding>
  );
};

export default Onboarding;
