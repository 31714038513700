import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const Filter = styled.div`
  max-height: 100vh;
  .filter-icon {
    font-size: 22px;
    path {
      stroke: ${v('colors.primary')};
    }
  }

  .filter {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    overflow: hidden;

    &.active {
      opacity: 1;
      transition: 0.6s ease-in-out;
      visibility: visible;
      .filter-wrapper {
        visibility: visible;
      }
    }
    .overlay {
      width: 100%;
      height: 100%;
      background-color: ${v('colors.primary')};
      opacity: 0.7;
    }

    .filter-wrapper {
      /* MainNav + inset top + 10px */
      height: calc(100vh - 110px);
      position: absolute;
      width: 100%;
      bottom: -400px;
      left: 0;
      background-color: white;
      border-radius: 4px 4px 0 0;
      transition: 0.3s ease-in-out;
      padding: 20px;
      visibility: hidden;

      .form-wrapper {
        height: 100%;
        overflow: scroll;
        padding-bottom: 40px;
      }
      &.active {
        bottom: 0;
        transition: 0.6s ease-in-out;
      }
      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        path {
          stroke: ${v('colors.primary600')};
        }
      }
      .title {
        text-align: center;
      }
    }
  }
`;
