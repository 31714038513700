import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import { useHistory } from 'react-router-dom';

import { StoreState } from 'store/appReducers';

import ProjectButton from 'components/items/ProjectButton';
import YoutubeEmbed from 'components/items/YoutubeEmbed';
import Avatar from 'components/items/Avatar';
import Tabs from 'components/items/Tabs';
import PageTitle from 'components/items/PageTitle';
import * as Styled from './EventAvatars.styled';

import { useUnity } from '../../../lib';

export interface EventAvatarsProps {}

export const EventAvatars = (props: EventAvatarsProps) => {
  const unity = useUnity();
  // mapStateToProps
  const history = useHistory();
  const lg = useSelector((state: StoreState) => state.content.lg);
  const [currentTab, setCurrentTab] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const eventMeta = useSelector((state: StoreState) => state.event.detail);
  const event = useMemo(() => eventMeta?.data || {}, [eventMeta]);

  const tabsParticipant = useMemo(() => {
    const res = [];
    for (let i = 0; i < event?.participants?.length; i += 1) {
      const item = event?.participants[i];
      res.push({ label: `${item?.name} Avatars`, key: item?._id });
    }
    return res;
  }, [event]);

  useEffect(() => {
    if (event?.participants?.[0]) setCurrentTab(event?.participants?.[0]?._id);
  }, [event]);

  useEffect(() => {
    if (currentTab && event?.participants) {
      setParticipant(event?.participants?.find((e) => e._id === currentTab));
    }
  }, [event, currentTab]);

  useEffect(() => {
    if (participant?.avatars) setCurrentAvatar(participant?.avatars?.[0]?._id);
  }, [participant]);

  useEffect(() => {
    setAvatar(participant?.avatars?.find((e) => e._id === currentAvatar));
  }, [currentAvatar, participant, event]);

  return (
    <Styled.EventAvatars>
      <div className={classNames('background-absolute')} />
      <PageTitle
        title="Full experience"
        topLabel="Back"
        textUppercase
        center
        topAction={() => {
          history.goBack();
        }}
      />
      <div className={classNames('tabs-wrapper')}>
        <Tabs items={tabsParticipant} tabAction={(e) => setCurrentTab(e)} selected={currentTab} />
        <div className={classNames('content-tab')}>
          <TextItem
            className={classNames('subtitle')}
            path={`Switch and slide between ${participant?.avatars?.length || 0} Avatar experiences in 360°`}
            tag="span"
          />
          <Avatar data={participant} activeKey="_id" action={(e) => setCurrentAvatar(e)} selected={currentAvatar} />
          {avatar && (
            <div className={classNames('stream-wrapper')}>
              <div className={classNames('subtitle')}>
                <span className={classNames('bold')}>Currently Streaming: </span>
                <span>{avatar?.participants?.find((e) => e.id === participant?._id)?.position}</span>
              </div>
              <div className={classNames('stream-wrapper', 'provider-stream')}>
                <div className={classNames('button-wrapper')}>
                  <ProjectButton
                    label="360° Stream"
                    colorClassName="secondary"
                    logoLeft
                    action={() => {
                      unity.loadAvatar(avatar?._id, event?._id);
                    }}
                  />
                </div>
              </div>
              {/* <YoutubeEmbed embedId="57RGGDTHbFA" /> */}
            </div>
          )}
        </div>
      </div>
      {/* <div className={classNames('stream-wrapper', 'provider-stream')}>
        <div className={classNames('button-wrapper')}>
          <ProjectButton
            label="Watch on arena sport tv"
            colorClassName="secondary"
            logoLeft
            action={() => {
              window.open('https://www.tvarenasport.com/', '_blank');
            }}
          />
        </div>
      </div> */}
    </Styled.EventAvatars>
  );
};

export default EventAvatars;
