import React, { useCallback, useEffect, useState } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';

import { TextItem } from '@makeit-studio/ui-store';
import RegisterForm from 'components/forms/RegisterForm';
import Logo from 'components/items/Logo';
import { Form } from 'react-final-form';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { pushRoute, useAction } from '@makeit-studio/store';

import cloneDeep from 'lodash/cloneDeep';
import account from 'store/account';
import { parseToken } from 'utils/helpers';
import { fieldComparison } from '@makeit-studio/ui-library';
import { useAfterLogin, useCheckRegistration, useConfirmRegistration, useListAccounts, useResendCode, useSignUp } from 'store/account/hooks';
import { useLogin } from 'store/auth/hooks';
import ConfirmRegisterCodeForm from 'components/forms/ConfirmRegisterCodeForm';
import * as Styled from './Register.styled';

export interface RegisterProps {}

export const Register = (props: RegisterProps & RouteComponentProps) => {
  const { history } = props;

  const lg = useSelector((state: StoreState) => state.content.lg);
  const session = useSelector((state: StoreState) => state.auth?.data);

  const [awaitingCode, setAwaitingCode] = useState(false);
  const [registeringAccount, setRegisteringAccount] = useState(null);

  const register = useSignUp();
  const login = useLogin();
  const afterLogin = useAfterLogin();
  const getAccounts = useListAccounts();
  const checkRegistration = useCheckRegistration();
  const resendCode = useResendCode();
  const confirmRegistration = useConfirmRegistration();

  const submit = useCallback(
    async (values) => {
      const toSend = cloneDeep(values);

      await register({
        variables: { input: toSend },
      }).then((response) => {
        // Here we handle the outcome where the registration went through but the confirmation
        // code was not entered. We first check that the account exists and if it does,
        // we check that it's confirmed but not verified e-mail wise. We then send back another
        // confirmation code and redirect the user to the confirmation form
        const firstResponse = response[0];
        const hasError = firstResponse?.extensions?.code || false;
        if (hasError) {
          if (hasError === 'ACCOUNT_ALREADY_EXISTS_ERROR') {
            getAccounts({
              variables: {
                search: { email: values.email },
              },
            }).then((accountRes) => {
              if (!accountRes.items[0]) return;
              checkRegistration({
                variables: {
                  id: accountRes.items[0]._id,
                },
              }).then((registrationResponse) => {
                if (!registrationResponse) {
                  // This outcome means the registration was interrupted before confirming code
                  setAwaitingCode(true);
                  setRegisteringAccount({ ...accountRes.items[0], password: values.password });
                  resendCode({
                    variables: {
                      id: accountRes.items[0]._id,
                    },
                  });
                } else {
                  // This outcome means the account was already validated and we redirect to login
                  setAwaitingCode(false);
                  history.push(pushRoute('login', lg));
                }
              });
            });
          }
        }
        return response;
      });

      // Redirect to the confirmation code form
      setAwaitingCode(true);
      await getAccounts({
        variables: {
          search: { email: values.email },
        },
      }).then((accountRes) => {
        if (!accountRes.items[0]) return;
        setRegisteringAccount({ ...accountRes.items[0], password: values.password });
      });
    },
    [checkRegistration, resendCode, register, getAccounts, history, lg],
  );

  const submitCode = useCallback(
    async (values) => {
      const toSend = cloneDeep(values);

      await confirmRegistration({
        variables: { id: registeringAccount._id, code: toSend.code, email: registeringAccount.email },
      });

      // Login user after code is confirmed
      await login({
        variables: {
          email: registeringAccount.email,
          password: registeringAccount.password,
        },
        success: 'Account created with success',
      });
    },
    [registeringAccount, login, confirmRegistration],
  );

  const registerValidation = useCallback((values) => {
    const errors = fieldComparison({
      field1: 'password',
      field2: 'passwordConfirmation',
      validationMessage: 'Does not match the new password above',
    })(values);

    return errors;
  }, []);

  useEffect(() => {
    const profileInit = async () => {
      if (session) {
        await afterLogin();
        history.push(pushRoute('preferences', lg));
      }
    };
    profileInit();
  }, [session, afterLogin, history, lg]);

  return (
    <Styled.Register>
      {!awaitingCode ? (
        <>
          <div className={classNames('heading', 'align-center')}>
            <TextItem tag="h1" path="Create an account" />
            <TextItem tag="p" path="Please enter the details below to continue" />
          </div>
          <Form validate={registerValidation} component={RegisterForm} onSubmit={submit} />
        </>
      ) : (
        <>
          <div className={classNames('heading', 'align-center')}>
            <TextItem tag="h1" path="Create an account" />
            <TextItem tag="p" path="Please enter the code received via e-mail" />
          </div>
          <Form validate={registerValidation} component={ConfirmRegisterCodeForm} onSubmit={submitCode} />
        </>
      )}

      <NavLink to={pushRoute('login')}>
        <TextItem className={classNames('link', 'link-switcher', 'align-center')} path="<span>You have an account? <b>Login</b></span>" isHtml />
      </NavLink>
    </Styled.Register>
  );
};

export default Register;
