import { $ } from 'config/zeus';
import { useApollo, auth } from '@makeit-studio/store';
import { useMemo } from 'react';
import * as models from './models';
import actions from './actions';

export const useRefreshToken = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      actionResult: actions.login.result,
      name: 'refreshToken',
      noFeedback: true,
      model: models.sessionModel,
      noToken: true,
      inputs: {
        refreshToken: $`refreshToken`,
      },
      // Allow to manipulate data before sending variables to Apollo Call
      treatmentOut: (data) => {
        return { refreshToken: data?.refreshToken };
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useLogin = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      actionRequest: actions.login.request,
      actionResult: actions.login.result,
      name: 'login',
      inputs: {
        email: $`email`,
        password: $`password`,
      },
      model: models.sessionModel,
    }),
    [],
  );

  return useApollo(params);
};
