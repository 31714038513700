import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const Onboarding = styled.div`
  height: 94vh;
  position: relative;

  .logo {
    top: 25% !important;
  }
  .inline-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .onboarding-slider {
    width: 100vw;
    top: -20px;
    left: -20px;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;

    .video-wrapper {
      position: relative;
      margin-bottom: 30px;
      height: 340px;
      .logo {
        z-index: 2;
        position: absolute;
        top: 48%;
        left: 48%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .slick-dots {
      position: relative;
      bottom: inherit;
      li {
        button {
          background-color: ${v('colors.primary')};
          border-radius: 20px;
          height: 12px;
          width: 12px;
          &:before {
            opacity: 1;
            content: '';
          }
        }
      }
      li.slick-active {
        margin-right: 20px;
        button {
          background-color: ${v('colors.primaryLight')};
          width: 30px;
          padding-right: 20px;
        }
      }
    }
    .heading {
      padding: 0 20px;
    }
  }

  .end-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    .bold {
      font-weight: bold;
      margin-bottom: 15px;
    }
    .check-option {
      display: flex;
      align-items: center;
      height: 20px;
      .icon {
        margin-right: 10px;
        padding-bottom: 2px;
      }
    }
  }
`;
