import { createReducer } from '@reduxjs/toolkit';
import {
  StoreAction,
  StoreData,
  handleRequest,
  handleResponse,
  handleUpdateResponse,
  handleAddResponse,
  handleDeleteResponse,
} from '@makeit-studio/store';
import cloneDeep from 'lodash/cloneDeep';
import auth from 'store/auth';
import actions from './actions';

interface AccountState {
  profile: StoreData<any, any>;
  list: StoreData<any, any>;
}

export const initialState: AccountState = {
  profile: null,
  list: null,
};

export const treatmentIn = (values) => {
  const output = cloneDeep(values);

  return output;
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.me.request, (state, action) => {
      handleRequest(state, action, 'profile', true);
    })
    .addCase(actions.me.result, (state, action) => {
      handleResponse(state, action, 'profile', null, true);
    })
    .addCase(actions.accounts.request, (state, action) => {
      handleRequest(state, action, 'list', true);
    })
    .addCase(actions.accounts.result, (state, action) => {
      handleResponse(state, action, 'list', null, true);
    })
    .addCase(auth.actions.logout.result, (state, action) => {
      state.profile = null;
    });
});

export default reducer;
