import styled from '@emotion/styled';
import { v, transition } from '@makeit-studio/ui-library';

export const Logo = styled.div`
  position: relative;
  margin: auto;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  ${(props) => transition(props.theme, 'all')}
  max-width: 190px;
  img {
    max-width: 100%;
    object-fit: contain;
  }
  &.full {
    height: auto;
    margin: initial;
  }
  &.align-center {
    margin: 0 auto 30px auto;
  }
`;
