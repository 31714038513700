import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import ProjectButton from 'components/items/ProjectButton';
import * as Styled from './ListEvent.styled';

export interface ListEventProps {
  title?: string;
  children?: any;
  labelAction?: string;
  action?: () => any;
  vertical?: boolean;
  horizontal?: boolean;
}

export const ListEvent = (props: ListEventProps) => {
  const { title, children, labelAction, action, vertical, horizontal } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const event = useSelector((state: StoreState) => state.event.list);

  return (
    <Styled.ListEvent>
      {title && (
        <div className={classNames('top')}>
          {title && <TextItem path={title} tag="h3" />}
          {action && <ProjectButton color="ternary" relative noMargin angleRight label={labelAction} action={action} />}
        </div>
      )}
      <div className={classNames('list', vertical && 'vertical', horizontal && 'horizontal')}>{children}</div>
    </Styled.ListEvent>
  );
};

export default ListEvent;
