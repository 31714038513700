import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ClassNames, useTheme } from '@emotion/react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { pushRoute, useAction, app } from '@makeit-studio/store';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import { NavLink, withRouter, useHistory, RouteComponentProps } from 'react-router-dom';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import ProjectButton from 'components/items/ProjectButton';
import PageTitle from 'components/items/PageTitle';
import auth from 'store/auth';
import * as Styled from './Settings.styled';

// assets
import profile from './assets/profile.svg';
import preference from './assets/preference.svg';
import account from './assets/account.svg';
import terms from './assets/terms.svg';
import privacy from './assets/privacy.svg';
import logOut from './assets/logout.svg';

export interface SettingsProps {}

export const Settings = (props: SettingsProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const theme = useTheme();
  const logout = useAction(auth.actions.logout.result);
  const dialogShow = useAction(app.actions.dialog.show);

  const handleLogout = useCallback(() => {
    dialogShow({
      id: 'logout',
      title: 'Are you sure?',
      wrapperClassName: classNames('modal-wrapper-logout'),
      actions: [
        { label: 'No', color: 'secondary' },
        {
          label: 'Yes',
          action: () => {
            logout();
            history.push(pushRoute('login', lg));
          },
        },
      ],
    });
  }, [logout, dialogShow, history, lg]);

  const settingsItem = [
    {
      link: 'settings.profile',
      icon: profile,
      title: 'Profile',
    },
    {
      link: 'settings.preferences',
      icon: preference,
      title: 'Preferences',
    },
    {
      link: 'settings.account',
      icon: account,
      title: 'Account',
    },
    {
      link: 'settings.terms',
      icon: terms,
      title: 'Terms and conditions',
    },
    {
      link: 'settings.privacy',
      icon: privacy,
      title: 'Privacy',
    },
  ];

  return (
    <ClassNames>
      {({ css, cx }) => (
        <div
          className={cx(
            css`
              ${Styled.Settings(theme)}
            `,
          )}
        >
          <PageTitle title="Settings" />
          {settingsItem.map((item, key) => (
            <NavLink exact to={pushRoute(item.link, lg)} className={classNames('settings-nav-item')} key={key}>
              <div className={classNames('item-wrapper')}>
                <div className={classNames('image-wrapper')}>
                  <img src={item.icon} alt={item.icon} />
                </div>
                <TextItem path={item.title} tag="h4" />
              </div>
              <IoChevronForward />
            </NavLink>
          ))}
          <div className={classNames('settings-nav-item')} onClick={handleLogout}>
            <div className={classNames('item-wrapper')}>
              <div className={classNames('image-wrapper')}>
                <img src={logOut} alt="logout" />
              </div>
              <TextItem path="Log out" tag="h4" />
            </div>
            <IoChevronForward />
          </div>
        </div>
      )}
    </ClassNames>
  );
};

export default Settings;
