import { createReducer } from '@reduxjs/toolkit';
import {
  StoreAction,
  StoreData,
  handleRequest,
  handleResponse,
  handleUpdateResponse,
  handleAddResponse,
  handleDeleteResponse,
} from '@makeit-studio/store';
import cloneDeep from 'lodash/cloneDeep';
import actions from './actions';

export interface SessionData {
  idToken?: string;
  refreshToken?: string;
  expiresIn?: number;
  email?: string;
}
export interface AuthState {
  data: SessionData | null;
  loading: boolean;
  other?: any;
}

const initialState: AuthState = {
  data: null,
  loading: false,
};

export const treatmentIn = (values) => {
  const output = cloneDeep(values);

  return output;
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.login.request, (state) => {
      state.loading = true;
    })
    .addCase(actions.login.result, (state, action) => {
      state.loading = false;
      if (action.payload.status === 'rejected') return;
      state.data = {
        // used to check expiration
        creationDate: new Date().toISOString(),
        ...action.payload?.data,
      };
    })
    .addCase(actions.logout.result, (state) => {
      state.data = null;
      state.other = null;
    })
    .addCase(actions.setOther, (state, action) => {
      state.other = state.other || {};
      if (action.payload?.key) state.other[action.payload.key] = action.payload?.value;
    });
});

export default reducer;
