import styled from '@emotion/styled';
import { v, ball } from '@makeit-studio/ui-library';

export const Avatar = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: scroll;
  width: calc(100% + 20px);

  .avatar-item {
    position: relative;
    max-width: 120px;
    margin-right: 10px;

    &.active {
      .avatar-picture {
        border: solid 2px ${v('colors.success')};

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #28242e;
          opacity: 0.8;
        }
      }
    }
    .avatar-picture {
      width: 120px;
      height: 120px;
      overflow: hidden;
      border-radius: ${v('border.radius.avatar')};
      position: relative;
      border: solid 2px transparent;

      .check {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 30px;
        height: 30px;
        z-index: 2;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .avatar-team {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .avatar-team-logo {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: white;
        padding: 2px;
        display: flex;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      span {
        margin-left: 10px;
        font-weight: ${v('font.weight.bold')};
        font-size: ${v('font.size.small')};
      }
    }
    /* flex-shrink: 0;
  ${ball('40px')}
  font-size: 30px;
  color: white;
  font-weight: bold;
  background-color: ${v('colors.dark')};
  &.text {
    font-size: 20px;
  }
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  } */
  }
`;
