import React from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

// Import actions here

import classNames from 'classnames/bind';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import { pushRoute } from '@makeit-studio/store';
import Logo from 'components/items/Logo';
import * as Styled from './ProjectHeader.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  relative?: boolean;
}

export type ProjectHeaderProps = RouteComponentProps & OwnProps;

const ProjectHeader = (props: ProjectHeaderProps) => {
  const { relative } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  return (
    <Styled.Header className={classNames(relative && 'relative')}>
      <div className={classNames('left')}>
        <NavLink exact to={pushRoute('', lg)}>
          <Logo mode="full" />
        </NavLink>
      </div>
    </Styled.Header>
  );
};

export default withRouter(ProjectHeader);
