export const content = {
  seo: {
    title: '',
    description: '',
    keywords: '',
  },
  genders: [
    {
      value: 'M',
      label: 'M',
    },
    {
      value: 'F',
      label: 'F',
    },
  ],
};

export default content;
