import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

// Import actions here
import classNames from 'classnames/bind';
import { TextItem } from '@makeit-studio/ui-store';

// assets
import check from './assets/check.svg';
import PlaceholderAvatar from './assets/avatar-placeholder.png';

import * as Styled from './Avatar.styled';

export interface AvatarProps {
  action?: Function;
  items?: any[];
  isObject?: boolean;
  activeKey?: string;
  selected?: string;
  data?: any;
}

const Avatar = (props: AvatarProps) => {
  const { action, data, isObject, activeKey = 'key', selected } = props;

  const handleSelection = useCallback(
    (item) => {
      if (isObject) {
        action(item);
      } else {
        action(item[activeKey]);
      }
    },
    [isObject, action, activeKey],
  );

  return (
    <Styled.Avatar>
      {data?.avatars?.map((item, index) => (
        <div
          onClick={action ? () => handleSelection(item) : null}
          key={index}
          className={classNames('avatar-item', item[activeKey] && item[activeKey] === selected && 'active')}
        >
          {item.picture ? (
            <div className={classNames('avatar-picture', 'image-wrapper')}>
              {item[activeKey] && item[activeKey] === selected && <img src={check} alt={check} className={classNames('check')} />}
              <img src={item.picture?.getFile?.getUrl} alt="profile-user" />
            </div>
          ) : (
            <div className={classNames('avatar-picture', 'image-wrapper')}>
              {item[activeKey] && item[activeKey] === selected && <img src={check} alt={check} className={classNames('check')} />}
              <img src={PlaceholderAvatar} alt="profile-user" />
            </div>
          )}
          <div className={classNames('avatar-team')}>
            {data?.picture && (
              <div className={classNames('avatar-team-logo')} style={{ backgroundImage: `url(${data?.picture?.getFile?.getUrl})` }} />
            )}
            <TextItem path={data?.name} tag="span" />
          </div>
          <TextItem className={classNames('avatar-name')} path={item.name} tag="p" />
          <div />
        </div>
      ))}
      {data?.avatars?.length === 0 && <TextItem path="No avatars in live" />}
    </Styled.Avatar>
  );
};

export default Avatar;
