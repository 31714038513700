/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	ObjectId: "String",
	DateTime: "String",
	FileDto:{
		getUrl:{
			size:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	EventMoments: "enum",
	AccountDto:{
		pinnedEvents:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"EventSearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"EventSortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Gender: "enum",
	Roles: "enum",
	EventSearchDto:{
		ids:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		typeIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		categoryIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		liveEventId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		},
		searchText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"NumberRangeArgs",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"DateRangeArgs",
			array:false,
			arrayRequired:false,
			required:false
		},
		participantsIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatars:{
			type:"NumberRangeArgs",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NumberRangeArgs:{
		min:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DateRangeArgs:{
		to:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		from:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventSortByDto:{
		name:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		startAt:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SortByDirection: "enum",
	EventPriceComponentInputDto:{
		amount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		currency:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	LocationComponentInputDto:{
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coordinates:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AddressComponentInputDto:{
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		street:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		streetBis:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		floor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		box:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"LocationComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageComponentInputDto:{
		_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fileType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		large:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		medium:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		small:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvatarStreamComponentInputDto:{
		isLive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		hls:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvatarParticipantComponentInputDto:{
		id:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		},
		position:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvatarInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		picture:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		stream:{
			type:"AvatarStreamComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		participants:{
			type:"AvatarParticipantComponentInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		externalLink:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LiveEventInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:true
		},
		event:{
			type:"EventInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatarIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:true,
			required:true
		},
		avatars:{
			type:"AvatarInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventCategoryInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		logo:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventTypeInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		logo:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventParticipantInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		avatars:{
			type:"AvatarInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		picture:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventInputDto:{
		background:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"AddressComponentInputDto",
			array:false,
			arrayRequired:false,
			required:true
		},
		categoryId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:true
		},
		category:{
			type:"EventCategoryInputDto",
			array:false,
			arrayRequired:false,
			required:true
		},
		typeId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"EventTypeInputDto",
			array:false,
			arrayRequired:false,
			required:true
		},
		startAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:true
		},
		endAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		liveEventId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		},
		liveEvent:{
			type:"LiveEventInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"EventPriceComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		participantIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		participants:{
			type:"EventParticipantInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AccountSettingsComponentInputDto:{
		eventTypes:{
			type:"EventTypeInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		eventTypesIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		eventMoments:{
			type:"EventMoments",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AccountInputDto:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"Gender",
			array:false,
			arrayRequired:false,
			required:false
		},
		birthDate:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		hasAcceptedTerms:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		roles:{
			type:"Roles",
			array:true,
			arrayRequired:false,
			required:true
		},
		settings:{
			type:"AccountSettingsComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		pinnedEventsIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		pinnedEvents:{
			type:"EventInputDto",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Query:{
		login:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		refreshToken:{
			refreshToken:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		accounts:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"AccountSearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"AccountSortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		account:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		myPinnedEvents:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"EventSearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"EventSortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		events:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"EventSearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"EventSortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		event:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		participants:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"EventParticipantsSearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"EventParticipantSortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		participant:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		categories:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"EventCategorySearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"EventCategorySortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		category:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		types:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"EventTypeSearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"EventTypeSortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		type:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		liveEvent:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		filesGetOne:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		avatars:{
			page:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			searchBy:{
				type:"AvatarSearchDto",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"AvatarSortByDto",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		avatar:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	AccountSearchDto:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		searchText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AccountSortByDto:{
		email:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventParticipantsSearchDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatarsCount:{
			type:"NumberRangeArgs",
			array:false,
			arrayRequired:false,
			required:false
		},
		searchText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventParticipantSortByDto:{
		name:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventCategorySearchDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		searchText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventCategorySortByDto:{
		name:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventTypeSearchDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		searchText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventTypeSortByDto:{
		name:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvatarSearchDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AvatarSortByDto:{
		name:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"SortByDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Mutation:{
		register:{
			input:{
				type:"NewAccountInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		accountsEditSelf:{
			input:{
				type:"EditAccountInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		accountsEditOne:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"EditAccountInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		confirmRegistration:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		checkRegistrationStatus:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resendConfirmationCode:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteUser:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePassword:{
			input:{
				type:"EditAccountPasswordInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateEmail:{
			input:{
				type:"EditAccountEmailInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addPinnedEvent:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removePinnedEvent:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resetPassword:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		confirmResetPassword:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			newPassword:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			confirmPassword:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createEvent:{
			input:{
				type:"NewEventInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		editEvent:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"EditEventInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteEvent:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createEventParticipant:{
			input:{
				type:"NewEventParticipantInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteEventParticipant:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		editEventParticipant:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"EditEventParticipantInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createEventCategory:{
			input:{
				type:"NewEventCategoryInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteEventCategory:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		editEventCategory:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"NewEventCategoryInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createEventType:{
			input:{
				type:"NewEventTypeInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteEventType:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		editEventType:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"NewEventTypeInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLiveEvent:{
			input:{
				type:"NewLiveEventInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		editLiveEvent:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"NewLiveEventInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLiveEvent:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		filesAddOne:{
			input:{
				type:"UploadInitInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAvatar:{
			input:{
				type:"NewAvatarInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		editAvatar:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"EditAvatarInputDto",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAvatar:{
			id:{
				type:"ObjectId",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	NewAccountInputDto:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"Gender",
			array:false,
			arrayRequired:false,
			required:false
		},
		birthDate:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		hasAcceptedTerms:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		roles:{
			type:"Roles",
			array:true,
			arrayRequired:false,
			required:true
		},
		settings:{
			type:"AccountSettingsComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		pinnedEventsIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		pinnedEvents:{
			type:"EventInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		passwordConfirmation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	EditAccountInputDto:{
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"Gender",
			array:false,
			arrayRequired:false,
			required:false
		},
		birthDate:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		roles:{
			type:"Roles",
			array:true,
			arrayRequired:false,
			required:true
		},
		settings:{
			type:"AccountSettingsComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		pinnedEventsIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		pinnedEvents:{
			type:"EventInputDto",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	EditAccountPasswordInputDto:{
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"Gender",
			array:false,
			arrayRequired:false,
			required:false
		},
		birthDate:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		roles:{
			type:"Roles",
			array:true,
			arrayRequired:false,
			required:true
		},
		settings:{
			type:"AccountSettingsComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		pinnedEventsIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		pinnedEvents:{
			type:"EventInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		newPassword:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		newPasswordConfirmation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	EditAccountEmailInputDto:{
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gender:{
			type:"Gender",
			array:false,
			arrayRequired:false,
			required:false
		},
		birthDate:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		roles:{
			type:"Roles",
			array:true,
			arrayRequired:false,
			required:true
		},
		settings:{
			type:"AccountSettingsComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		pinnedEventsIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		pinnedEvents:{
			type:"EventInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		newEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	NewEventInputDto:{
		background:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"AddressComponentInputDto",
			array:false,
			arrayRequired:false,
			required:true
		},
		categoryId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:true
		},
		typeId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:true
		},
		startAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:true
		},
		endAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		liveEventId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		},
		liveEvent:{
			type:"LiveEventInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"EventPriceComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		participantIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		participants:{
			type:"EventParticipantInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EditEventInputDto:{
		background:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"AddressComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		categoryId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		},
		typeId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		},
		startAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		endAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		liveEventId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:false
		},
		liveEvent:{
			type:"LiveEventInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"EventPriceComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		participantIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:false,
			required:true
		},
		participants:{
			type:"EventParticipantInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewEventParticipantInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		avatars:{
			type:"AvatarInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		picture:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EditEventParticipantInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatars:{
			type:"AvatarInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		picture:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewEventCategoryInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		logo:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewEventTypeInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		logo:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	NewLiveEventInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventId:{
			type:"ObjectId",
			array:false,
			arrayRequired:false,
			required:true
		},
		event:{
			type:"EventInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		avatarIds:{
			type:"ObjectId",
			array:true,
			arrayRequired:true,
			required:true
		},
		avatars:{
			type:"AvatarInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		avatarsCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UploadInitInput:{
		fileName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		publicFile:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		tagsIds:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	NewAvatarInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		picture:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		stream:{
			type:"AvatarStreamComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		participants:{
			type:"AvatarParticipantComponentInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		externalLink:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EditAvatarInputDto:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		picture:{
			type:"ImageComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		stream:{
			type:"AvatarStreamComponentInputDto",
			array:false,
			arrayRequired:false,
			required:false
		},
		participants:{
			type:"AvatarParticipantComponentInputDto",
			array:true,
			arrayRequired:false,
			required:true
		},
		externalLink:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	EventPriceComponentDto:{
		amount:"Float",
		currency:"String"
	},
	Location:{
		type:"String",
		coordinates:"Float"
	},
	AddressComponentDto:{
		number:"String",
		street:"String",
		streetBis:"String",
		floor:"String",
		box:"String",
		zip:"String",
		state:"String",
		city:"String",
		country:"String",
		location:"Location"
	},
	ImageComponentDto:{
		_id:"String",
		title:"String",
		fileType:"String",
		large:"String",
		medium:"String",
		small:"String",
		getFile:"FileDto"
	},
	AvatarStreamComponentDto:{
		isLive:"Boolean",
		hls:"String"
	},
	AvatarParticipantComponentDto:{
		id:"ObjectId",
		position:"String",
		eventId:"ObjectId"
	},
	AvatarDto:{
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		description:"String",
		picture:"ImageComponentDto",
		stream:"AvatarStreamComponentDto",
		participants:"AvatarParticipantComponentDto",
		externalLink:"String"
	},
	BaseDto:{
		"...on AvatarDto": "AvatarDto",
		"...on LiveEventDto": "LiveEventDto",
		"...on EventCategoryDto": "EventCategoryDto",
		"...on EventTypeDto": "EventTypeDto",
		"...on EventParticipantDto": "EventParticipantDto",
		"...on EventDto": "EventDto",
		"...on AccountDto": "AccountDto",
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	LiveEventDto:{
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		description:"String",
		eventId:"ObjectId",
		event:"EventDto",
		avatarIds:"ObjectId",
		avatars:"AvatarDto",
		avatarsCount:"Float"
	},
	EventCategoryDto:{
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		logo:"ImageComponentDto"
	},
	EventTypeDto:{
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		logo:"ImageComponentDto"
	},
	EventParticipantDto:{
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		avatars:"AvatarDto",
		avatarsCount:"Float",
		picture:"ImageComponentDto"
	},
	EventDto:{
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		background:"ImageComponentDto",
		name:"String",
		description:"String",
		location:"AddressComponentDto",
		categoryId:"ObjectId",
		category:"EventCategoryDto",
		typeId:"ObjectId",
		type:"EventTypeDto",
		startAt:"DateTime",
		endAt:"DateTime",
		liveEventId:"ObjectId",
		liveEvent:"LiveEventDto",
		price:"EventPriceComponentDto",
		participantIds:"ObjectId",
		participants:"EventParticipantDto",
		avatarsCount:"Float"
	},
	PaginatedEventsDto:{
		page:"Float",
		limit:"Float",
		totalCount:"Float",
		count:"Float",
		items:"EventDto",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean"
	},
	PaginatedEventTypesDto:{
		page:"Float",
		limit:"Float",
		totalCount:"Float",
		count:"Float",
		items:"EventTypeDto",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean"
	},
	PaginatedEventCategoriesDto:{
		page:"Float",
		limit:"Float",
		totalCount:"Float",
		count:"Float",
		items:"EventCategoryDto",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean"
	},
	PaginatedEventParticipantsDto:{
		page:"Float",
		limit:"Float",
		totalCount:"Float",
		count:"Float",
		items:"EventParticipantDto",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean"
	},
	FileDto:{
		_id:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		mime:"String",
		title:"String",
		accountId:"String",
		getUrl:"String"
	},
	UploadInitDto:{
		url:"String",
		file:"FileDto"
	},
	AccountSettingsComponentDto:{
		eventTypes:"EventTypeDto",
		eventTypesIds:"ObjectId",
		eventMoments:"EventMoments"
	},
	AccountDto:{
		_id:"ObjectId",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		email:"String",
		firstName:"String",
		lastName:"String",
		country:"String",
		gender:"Gender",
		birthDate:"DateTime",
		phoneNumber:"String",
		hasAcceptedTerms:"Boolean",
		roles:"Roles",
		settings:"AccountSettingsComponentDto",
		pinnedEventsIds:"ObjectId",
		pinnedEvents:"EventDto"
	},
	UserSessionDto:{
		localId:"String",
		email:"String",
		displayName:"String",
		idToken:"String",
		registered:"Boolean",
		refreshToken:"String",
		expiresIn:"String"
	},
	PaginatedAccountsDto:{
		page:"Float",
		limit:"Float",
		totalCount:"Float",
		count:"Float",
		items:"AccountDto",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean"
	},
	PaginatedAvatarsDto:{
		page:"Float",
		limit:"Float",
		totalCount:"Float",
		count:"Float",
		items:"AvatarDto",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean"
	},
	Query:{
		login:"UserSessionDto",
		me:"AccountDto",
		refreshToken:"UserSessionDto",
		accounts:"PaginatedAccountsDto",
		account:"AccountDto",
		myPinnedEvents:"PaginatedEventsDto",
		events:"PaginatedEventsDto",
		event:"EventDto",
		participants:"PaginatedEventParticipantsDto",
		participant:"EventParticipantDto",
		categories:"PaginatedEventCategoriesDto",
		category:"EventCategoryDto",
		types:"PaginatedEventTypesDto",
		type:"EventTypeDto",
		liveEvents:"LiveEventDto",
		liveEvent:"LiveEventDto",
		filesGetOne:"FileDto",
		avatars:"PaginatedAvatarsDto",
		avatar:"AvatarDto"
	},
	Mutation:{
		register:"AccountDto",
		accountsEditSelf:"AccountDto",
		accountsEditOne:"AccountDto",
		confirmRegistration:"Boolean",
		checkRegistrationStatus:"Boolean",
		resendConfirmationCode:"Boolean",
		deleteUser:"Boolean",
		updatePassword:"AccountDto",
		updateEmail:"AccountDto",
		addPinnedEvent:"AccountDto",
		removePinnedEvent:"AccountDto",
		resetPassword:"Boolean",
		confirmResetPassword:"Boolean",
		createEvent:"EventDto",
		editEvent:"EventDto",
		deleteEvent:"Boolean",
		createEventParticipant:"EventParticipantDto",
		deleteEventParticipant:"Boolean",
		editEventParticipant:"EventParticipantDto",
		createEventCategory:"EventCategoryDto",
		deleteEventCategory:"Boolean",
		editEventCategory:"EventCategoryDto",
		createEventType:"EventTypeDto",
		deleteEventType:"Boolean",
		editEventType:"EventTypeDto",
		createLiveEvent:"LiveEventDto",
		editLiveEvent:"LiveEventDto",
		deleteLiveEvent:"Boolean",
		filesAddOne:"UploadInitDto",
		createAvatar:"AvatarDto",
		editAvatar:"AvatarDto",
		deleteAvatar:"Boolean"
	}
}