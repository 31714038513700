export const urls = {
  local: 'http://localhost:4011/api',
  dev: 'https://api.dev.mygms.makeit-studio.com/api/graphql',
  staging: 'https://api.staging.avatar.makeit-studio.com/api',
  prod: 'https://api.mygms.makeit-studio.com/api/graphql',
  content: {
    local: 'https://s3.eu-central-1.amazonaws.com/demo.greenomy.public/content/demo',
    dev: 'https://s3.eu-central-1.amazonaws.com/demo.greenomy.public/content/demo',
    staging: 'https://s3.eu-central-1.amazonaws.com/demo.greenomy.public/content/demo',
    prod: 'https://s3.eu-central-1.amazonaws.com/demo.greenomy.public/content/demo',
  },
};

export default urls;
