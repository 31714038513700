import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const SearchForm = styled.form`
  .search-input {
    margin-top: 0;
    width: 100%;
  }
  .form-wrapper {
    display: flex;
    align-items: center;
    margin: 0 20px;
    .button {
      display: flex;
    }
    input {
      &::placeholder {
        color: ${v('colors.primary300')};
      }
    }
    svg {
      path {
        stroke: ${v('colors.primary300')};
      }
    }
  }
`;
