import React, { useState, useEffect } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

// Import actions here

import classNames from 'classnames/bind';
import { Field } from 'react-final-form';
import ProjectInput from 'components/formItems/ProjectInput';
import { required } from '@makeit-studio/ui-library';
import countriesList from 'data/countries';

export interface CountryFieldProps {
  className?: string;
  disabled?: boolean;
  notRequired?: boolean;
  label?: string;
  name?: string;
  multiple?: string;
  loadCountries?: () => void;
  inputType?: string;
  validate?: any;
}

const CountryField = (props: CountryFieldProps) => {
  const { className, disabled, notRequired, label = 'Country', name = 'country', multiple, loadCountries, inputType, ...rest } = props;
  // mapStateToProps
  const countries = countriesList;

  const lg = useSelector((state: StoreState) => state.content.lg);

  useEffect(() => {
    if (!countries && loadCountries) loadCountries();
  }, [countries, loadCountries]);

  return (
    <Field
      disabled={disabled}
      component={ProjectInput}
      name={name}
      label={label}
      multipleSelection={multiple}
      seed="select"
      items={countries}
      modelValues={{
        text: 'text',
        value: 'value',
      }}
      filter
      validate={notRequired ? null : required}
      star={!notRequired}
      customClassName={classNames(className)}
      inputType={inputType}
      {...rest}
    />
  );
};

export default CountryField;
