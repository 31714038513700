import styled from '@emotion/styled';
import { v, ball } from '@makeit-studio/ui-library';

export const CheckableBox = styled.div`
  ${ball('16px')}
  flex-shrink: 0;
  &.small {
    ${ball('12px')}
    border-radius: 2px;
  }
  border-radius: 2px;
  font-size: 8px;
  background-color: white;
  border: 1px solid ${v('colors.border.default')};
  color: white;
  margin-right: 14px;
  &.active {
    border-color: ${v('colors.primary')} !important;
    background-color: ${v('colors.primary')} !important;
  }
`;
