import React from 'react';
import { Field } from 'react-final-form';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

// Import actions here

import classNames from 'classnames';

import { required, email, composeValidators } from '@makeit-studio/ui-library';

import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';
import CountryField from 'components/formHelpers/CountryField';
import { TextItem } from '@makeit-studio/ui-store';
import { passwordValidation } from 'utils/helpers';
import * as Styled from './RegisterForm.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
  edit?: boolean;
}

export type RegisterFormProps = StateProps & DispatchProps & OwnProps;

const RegisterForm = (props: RegisterFormProps) => {
  const { handleSubmit, submitting, valid, edit } = props;

  const gender = ['MALE', 'FEMALE', 'OTHER'];

  return (
    <Styled.RegisterForm onSubmit={handleSubmit}>
      <div className={classNames('row')}>
        <div className={classNames('col-6')}>
          <Field component={ProjectInput} name="firstName" label="First name" validate={required} />
        </div>
        <div className={classNames('col-6')}>
          <Field component={ProjectInput} name="lastName" label="Last name" validate={required} />
        </div>
      </div>
      <Field component={ProjectInput} seed="select" name="gender" label="Gender" defaultValue="OTHER" items={gender} validate={required} />
      <Field component={ProjectInput} seed="date" name="birthDate" label="Date of birth" validate={required} />
      <Field component={ProjectInput} name="phoneNumber" label="Phone number" validate={required} />
      <CountryField name="country" inputType="location" label="Location" validate={required} />
      {!edit && (
        <>
          <Field component={ProjectInput} name="email" inputType="email" label="Email" validate={composeValidators(required, email)} />
          <Field
            component={ProjectInput}
            seed="password"
            name="password"
            label="Password"
            validate={composeValidators(required, passwordValidation)}
          />
          <Field component={ProjectInput} seed="password" name="passwordConfirmation" label="Confirm password" validate={required} />
          <Field type="checkbox" component={ProjectInput} seed="checkbox" name="hasAcceptedTerms" validate={required}>
            <TextItem isHtml path="<div>Accept our <b><a href='https://google.com'>privacy policy</a></b></div>" />
          </Field>
        </>
      )}
      <ProjectButton disabled={submitting || !valid} label={edit ? 'Save' : 'Sign up'} type="submit" angleRight />
    </Styled.RegisterForm>
  );
};

export default RegisterForm;
