import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const ProjectRange = styled.div`
  margin-top: 30px;
  position: relative;
  .project-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .project-main-line {
    height: auto;
    width: 94%;
    background-color: ${v('colors.primaryLight')};
    margin: 10px auto 0 auto;
    border-radius: 10px;
  }
  .project-thumbs {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background-color: ${v('colors.primary')};
  }
`;
