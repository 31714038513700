import { css } from '@emotion/react';
import { getV } from '@makeit-studio/ui-library';

export const ProjectInput = (theme, isValid) => css`
  .project-label {
    /* to complete */
    text-transform: none;
    font-weight: ${getV(theme, 'font.weight.bold')};
    &.left {
      text-align: left;
    }
  }

  .project-error {
  }

  .field-wrapper {
    .project-icon-left {
      font-size: 18px;
      color: ${getV(theme, 'colors.text.primary')};
      border-right: none;
    }
    .project-content-wrapper {
      /* to complete */

      background-color: ${getV(theme, 'colors.background.chat.input')};

      input {
        font-weight: ${getV(theme, 'font.weight.regular')};
      }
    }
  }

  .icon {
    font-size: 24px;
  }
  &.valid:not(.light) {
    .project-label {
      color: ${getV(theme, 'colors.primary')};
      font-weight: ${getV(theme, 'font.weight.bold')};
    }
    .field-wrapper {
      .project-content-wrapper {
        /* box-shadow: 0 0 0 2px ${getV(theme, 'colors.border.default')}; */
        border: solid 1px ${getV(theme, 'colors.success')};
      }
    }
  }
  &.light {
    font-size: 14px;
    color: ${getV(theme, 'colors.text.secondary')};
    .field-wrapper .project-content-wrapper {
      border: none;
      background-color: ${getV(theme, 'colors.primaryLight')};
    }
  }

  /* Checkbox */
  .project-checkbox-label {
    font-size: ${getV(theme, 'font.size.medium')};
  }

  &:hover .project-box {
    /* box-shadow: 0 0 0 2px ${getV(theme, 'colors.border.default')};
    border-color: ${getV(theme, 'colors.primary')}; */
  }
  .project-checked {
    &,
    a {
      font-weight: 600;
      color: ${getV(theme, 'colors.primary')};
    }
  }

  /* Select */
  .select-items-content-wrapper {
    top: calc(100% + 12px) !important;

    .select-items {
      padding: 7px;
      .select-item {
        margin-top: 2px;
        border-radius: ${getV(theme, 'border.radius.default')};
        &.active {
          color: ${getV(theme, 'colors.primary')};
          font-weight: normal;
        }
      }
    }
  }
`;
