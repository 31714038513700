import { call } from '@makeit-studio/store';
import { config } from 'config/general';

export const api = {
  app: {
    contentProd: (options) =>
      call(`${config.api.contentUrl}-${options.lg || 'all'}.json`, 'GET', {
        raw: true,
      }),
    contentDev: (options) =>
      call(`${config.api.contentUrl}-${options.lg || 'all'}.json`, 'GET', {
        raw: true,
      }),
  },
};

export default api;
