import React, { useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Switch } from 'react-router';

import { getRouteInfoFromLocation, getUrlFromPath, useAction } from '@makeit-studio/store';
import { useSelector } from 'react-redux';

import { StoreState } from 'store/appReducers';
import MainNav from 'components/global/MainNav';

// Import should be direct
import Home from 'components/routes/Home';
import NotFound from 'components/routes/NotFound';
import MyEvents from 'components/routes/MyEvents';
import EventDetail from 'components/routes/EventDetail';
import EventAvatars from 'components/routes/EventAvatars';
import Events from 'components/routes/Events';
import Settings from 'components/routes/Settings';
import Profile from 'components/routes/Settings/Profile';
import Preferences from 'components/routes/Settings/Preferences';
import Account from 'components/routes/Settings/Account';
import Terms from 'components/routes/Settings/Terms';
import Privacy from 'components/routes/Settings/Privacy';
import RenewEmail from 'components/routes/Settings/Account/RenewEmail';
import RenewPassword from 'components/routes/Settings/Account/RenewPassword';
import event from 'store/event';
import * as Styled from './App.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {}

export type AppProps = StateProps & DispatchProps & OwnProps & RouteComponentProps;

const App = (props: AppProps) => {
  const { match, location } = props;

  // const auth = useSelector((state: StoreState) => state.auth?.data);
  // const lg = useSelector((state: StoreState) => state.content.lg);

  const filterActive = useSelector((state: StoreState) => state.event.filterActive);

  const setFilterActive = useAction(event.actions.filterActive, true);

  const { path } = getRouteInfoFromLocation(location.pathname) as any;

  const greyBackground = useMemo(() => {
    return ['event.id', 'event.id.avatars'].indexOf(path) >= 0;
  }, [path]);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    contentRef.current.scrollTop = 0;
    // make sure to reset the filter active when the page changes
    setFilterActive(false);
  }, [location.pathname, setFilterActive]);

  useEffect(() => {
    if (filterActive && contentRef.current && contentRef.current.scrollTop !== 0) {
      contentRef.current.scrollTo?.({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [filterActive]);

  // if (!auth) {
  //   return <Redirect to={pushRoute('login', lg)} />;
  // }

  return (
    <Styled.App className={classNames('App')}>
      <div className={classNames('app-content-wrapper')}>
        <div ref={contentRef} className={classNames('app-content', greyBackground && 'grey', filterActive && 'no-scroll')}>
          <Switch>
            <Route exact path={getUrlFromPath('home', match)} component={Home} />
            <Route exact path={getUrlFromPath('event.id', match)} component={EventDetail} />
            <Route exact path={getUrlFromPath('event.id.avatars', match)} component={EventAvatars} />
            <Route exact path={getUrlFromPath('events', match)} component={Events} />
            <Route exact path={getUrlFromPath('events.type', match)} component={Events} />
            <Route exact path={getUrlFromPath('my-events', match)} component={MyEvents} />
            <Route exact path={getUrlFromPath('settings', match)} component={Settings} />
            <Route exact path={getUrlFromPath('settings.profile', match)} component={Profile} />
            <Route exact path={getUrlFromPath('settings.preferences', match)} component={Preferences} />
            <Route exact path={getUrlFromPath('settings.account', match)} component={Account} />
            <Route exact path={getUrlFromPath('settings.account.change-email', match)} component={RenewEmail} />
            <Route exact path={getUrlFromPath('settings.account.renew-password', match)} component={RenewPassword} />
            <Route exact path={getUrlFromPath('settings.terms', match)} component={Terms} />
            <Route exact path={getUrlFromPath('settings.privacy', match)} component={Privacy} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <MainNav border={greyBackground} />
      </div>
    </Styled.App>
  );
};

export default App;
