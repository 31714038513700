import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { Form } from 'react-final-form';
import { NavLink, RouteComponentProps, useHistory } from 'react-router-dom';

import ProjectResetPasswordForm from 'components/forms/ProjectResetPasswordForm';
import { TextItem } from '@makeit-studio/ui-store';
import { IoChevronBack } from 'react-icons/io5';
import { useAction, pushRoute } from '@makeit-studio/store';
import account from 'store/account';
import { useResetPassword } from 'store/account/hooks';
import * as Styled from './ResetPassword.styled';

export interface ResetPasswordProps {}

export const ResetPassword = (props: ResetPasswordProps & RouteComponentProps) => {
  const history = useHistory();
  const lg = useSelector((state: StoreState) => state.content.lg);

  const resetPassword = useResetPassword();

  // const submit = (values) => {
  //   resetPassword({
  //     email: values.email,
  //     callback: (res) => {
  //       console.log('res', res);
  //     },
  //     success: 'An email has been sent to you',
  //   });
  // };

  const submit = useCallback(
    async (values) => {
      await resetPassword({
        variables: values,
        success: 'Verification sent via email',
      });
      history.push(pushRoute('confirmResetPassword', lg));
    },
    [resetPassword, history, lg],
  );

  return (
    <Styled.ResetPassword>
      <NavLink to={pushRoute('login')} className={classNames('back')}>
        <IoChevronBack />
      </NavLink>
      <div className={classNames('heading', 'align-center')}>
        <TextItem tag="h1" path="Reset password" />
        <TextItem tag="p" path="Enter the email associated with your account and we'll send an email with instructions to reset your password." />
      </div>
      <Form initialValues={{}} component={ProjectResetPasswordForm} onSubmit={submit} />
    </Styled.ResetPassword>
  );
};

export default ResetPassword;
