import React, { createContext, useContext, useMemo } from 'react';
import { UnityBridge, UnityScenes } from '.';

export const UnityContext = createContext(null) as any;

export const UnityProvider = ({ children }: any) => {
  const ctx = useMemo(() => {
    const bridge = new UnityBridge();

    return {
      setCurrentScene: (scene: UnityScenes) => bridge.switchScene(scene),
      setAuthData: (token: string) => bridge.setAuthData(token),
      loadAvatar: (avatarId: string, eventId: string) => bridge.loadAvatar(avatarId, eventId),
      loadStream: (streamUrl: string) =>
        bridge.loadStream({
          hls: streamUrl,
        }),
    };
  }, []);

  return <UnityContext.Provider value={ctx}>{children}</UnityContext.Provider>;
};

export const useUnity = () => useContext<typeof UnityContext>(UnityContext);
