import { createReducer } from '@reduxjs/toolkit';
import {
  StoreAction,
  StoreData,
  handleRequest,
  handleResponse,
  handleUpdateResponse,
  handleAddResponse,
  handleDeleteResponse,
} from '@makeit-studio/store';
import cloneDeep from 'lodash/cloneDeep';
import auth from 'store/auth';
import actions from './actions';

interface EventState {
  list: StoreData<any, any>;
  detail: StoreData<any, any>;
  types: StoreData<any, any>;
  suggested: StoreData<any, any>;
  pinned: StoreData<any, any>;
  recent: StoreData<any, any>;
  filterActive?: boolean;
}

export const initialState: EventState = {
  list: null,
  detail: null,
  types: null,
  suggested: null,
  recent: null,
  pinned: null,
  filterActive: false,
};

export const treatmentIn = (values) => {
  const output = cloneDeep(values);

  return output;
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.list.request, (state, action) => {
      handleRequest(state, action, 'list', true);
    })
    .addCase(actions.list.result, (state, action) => {
      handleResponse(state, action, 'list', treatmentIn);
    })
    .addCase(actions.recent.request, (state, action) => {
      handleRequest(state, action, 'recent', true);
    })
    .addCase(actions.recent.result, (state, action) => {
      handleResponse(state, action, 'recent', treatmentIn);
    })
    .addCase(actions.suggested.request, (state, action) => {
      handleRequest(state, action, 'suggested', true);
    })
    .addCase(actions.suggested.result, (state, action) => {
      handleResponse(state, action, 'suggested', treatmentIn);
    })
    .addCase(actions.detail.request, (state, action) => {
      handleRequest(state, action, 'detail', action?.payload?.variables?.reset);
    })
    .addCase(actions.detail.result, (state, action) => {
      handleResponse(state, action, 'detail', treatmentIn);
    })
    .addCase(actions.update.result, (state, action) => {
      handleUpdateResponse(state, action, 'list', treatmentIn);
    })
    .addCase(actions.add.result, (state, action) => {
      handleAddResponse(state, action, 'list', treatmentIn);
    })
    .addCase(actions.delete.result, (state, action) => {
      handleDeleteResponse(state, action, 'list');
    })
    .addCase(actions.types.request, (state, action) => {
      handleRequest(state, action, 'types', true);
    })
    .addCase(actions.types.result, (state, action) => {
      handleResponse(state, action, 'types', treatmentIn);
    })
    .addCase(actions.pinned.request, (state, action) => {
      handleRequest(state, action, 'pinned', true);
    })
    .addCase(actions.pinned.result, (state, action) => {
      handleResponse(state, action, 'pinned', treatmentIn);
    })
    .addCase(actions.filterActive, (state, action) => {
      state.filterActive = action.payload;
    })
    .addCase(auth.actions.logout.result, (state) => {
      state.list = null;
      state.detail = null;
    });
});

export default reducer;
