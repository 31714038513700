import React from 'react';
import { Field } from 'react-final-form';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';
// Import actions here

import classNames from 'classnames';
import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';
import { Input, Button } from '@makeit-studio/ui-store';

import { required, email, composeValidators } from '@makeit-studio/ui-library';

import * as Styled from './ProjectRenewEmailForm.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
}

export type ProjectRenewEmailFormProps = StateProps & DispatchProps & OwnProps;

const ProjectRenewEmailForm = (props: ProjectRenewEmailFormProps) => {
  const { handleSubmit, submitting, valid } = props;

  // mapStateToProps
  const content = useSelector((state: StoreState) => state.content.raw);

  return (
    <Styled.ProjectRenewEmailForm onSubmit={handleSubmit}>
      <Field component={ProjectInput} name="password" seed="password" label="Current Password" isRequired validate={required} />
      <Field component={ProjectInput} name="newEmail" type="email" label="New email" isRequired validate={required} />
      <ProjectButton disabled={submitting || !valid} label="Save" type="submit" angleRight />
    </Styled.ProjectRenewEmailForm>
  );
};

export default ProjectRenewEmailForm;
