import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

import { TextItem } from '@makeit-studio/ui-store';
import ProjectButton from 'components/items/ProjectButton';
import { pushRoute } from '@makeit-studio/store';
import BackButton from 'components/items/BackButton';
import { RouteComponentProps } from 'react-router-dom';
import * as Styled from './NotFound.styled';

export interface NotFoundProps {}

export const NotFound = (props: NotFoundProps & RouteComponentProps) => {
  const { history } = props;
  const auth = useSelector((state: StoreState) => state.auth);
  const lg = useSelector((state: StoreState) => state.content.lg);

  return (
    <Styled.NotFound>
      <BackButton />
      <TextItem tag="h1" path="404 Not found" />
      <TextItem tag="p" path="It seems that the page you are looking for do not exists" />
    </Styled.NotFound>
  );
};

export default NotFound;
