import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { pushRoute, useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';

import { StoreState } from 'store/appReducers';
import PageTitle from 'components/items/PageTitle';
import ListEvent from 'components/listItems/ListEvent';
import Card from 'components/items/Card';
import Filter from 'components/items/Filter';
import CategoryItem from 'components/items/CategoryItem';
import SearchForm from 'components/forms/SearchForm';
import qs from 'qs';
import { deepOmit } from '@makeit-studio/helpers';
import ListEventLayout from 'components/layouts/ListEventLayout';
import { useRecentList, useSuggestedList } from 'store/event/hooks';
import { useSearchParams } from 'hooks/searchParams';
import { cloneDeep } from 'lodash';
import * as Styled from './Events.styled';

export interface EventsProps {}

export const Events = (props: EventsProps) => {
  const recent = useSelector((state: StoreState) => state.event.recent);
  const suggested = useSelector((state: StoreState) => state.event.suggested);
  const profile = useSelector((state: StoreState) => state.account?.profile?.data);

  const loadRecent = useRecentList();
  const loadSuggested = useSuggestedList();

  const params = useParams();

  const { location, eventSearch } = useSearchParams();

  const type = useMemo(() => params?.type, [params]);

  const handleLoadSuggest = useCallback(
    (search) => {
      if (profile) {
        loadSuggested({ variables: { search: { ...search, typeIds: profile?.settings?.eventTypesIds } } });
      }
    },
    [profile, loadSuggested],
  );

  const options = useMemo(() => {
    switch (type) {
      case 'suggested':
        return {
          title: 'Suggested events',
          route: 'events.type',
          text: 'List of suggested events',
          loading: suggested?.loading,
          list: suggested?.data?.items,
        };
      default:
        return { title: 'New events', route: 'events.type', text: 'List of new events', list: recent?.data?.items };
    }
  }, [type, suggested, recent]);

  const handleSearch = useCallback(() => {
    if (type === 'suggested') handleLoadSuggest(eventSearch);
    else loadRecent({ variables: { search: eventSearch } });
  }, [eventSearch, type, handleLoadSuggest, loadRecent]);

  useEffect(() => {
    handleSearch();
  }, [location, handleSearch]);

  return (
    <Styled.Events>
      <ListEventLayout options={options} noCategory={type === 'suggested'} />
    </Styled.Events>
  );
};

export default Events;
