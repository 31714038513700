import { Validator } from '@makeit-studio/ui-library';

export const parseToken = (token) => {
  const extract = token?.split('.')?.[1];
  if (extract) {
    const decoded = atob(extract);
    if (decoded) return JSON.parse(decoded);
  }

  return null;
};

// eslint-disable-next-line
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/;

export const passwordValidation = (value) =>
  value && !passwordRegex.test(value)
    ? Validator.getValidationInfo(
        'password',
        'Your password should contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long',
      )
    : false;
