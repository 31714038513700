import React, { useCallback } from 'react';
import { pushRoute } from '@makeit-studio/store';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import PageTitle from 'components/items/PageTitle';
import RegisterForm from 'components/forms/RegisterForm';
import { Form } from 'react-final-form';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import { useUpdateMe } from 'store/account/hooks';
import { deepOmit } from '@makeit-studio/helpers';
import * as Styled from './Profile.styled';

export interface ProfileProps {}

export const Profile = (props: ProfileProps & RouteComponentProps) => {
  const { history } = props;

  const lg = useSelector((state: StoreState) => state.content.lg);
  const profile = useSelector((state: StoreState) => state.account.profile?.data);

  const updateMe = useUpdateMe();

  const submit = useCallback(
    async (values) => {
      await updateMe({
        variables: { input: values },
      });

      history.push(pushRoute('settings', lg));
    },
    [updateMe, history, lg],
  );

  return (
    <Styled.Profile>
      <PageTitle
        title="Profile"
        topLabel="Back"
        topAction={() => {
          history.push(pushRoute('settings', lg));
        }}
      />
      <Form edit component={RegisterForm} initialValues={deepOmit(profile, ['pinnedEvents', 'pinnedEventsIds'])} onSubmit={submit} />
    </Styled.Profile>
  );
};

export default Profile;
