import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const TeamItem = styled.div`
  display: block;
  text-align: center;
  width: 33%;
  .logo {
    background-color: ${v('colors.primaryLight')};
    padding: 5px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: inline-flex;
    margin: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .name {
    margin-top: 10px;
    font-weight: ${v('font.weight.bold')};
  }
`;
