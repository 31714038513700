import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const Welcome = styled.div`
  height: 94vh;
  position: relative;
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      text-align: center;
      display: block;
      width: 60%;
      font-size: 28px;
    }
    p {
      width: 90%;
      font-size: ${v('font.size.message')};
      margin-top: 10px;
    }
  }
  .start-btn {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;
