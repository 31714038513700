import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { pushRoute, auth, useAction } from '@makeit-studio/store';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import PreferencesForm from 'components/forms/PreferencesForm';
import { Form } from 'react-final-form';
import PageTitle from 'components/items/PageTitle';
import { RouteComponentProps } from 'react-router-dom';
import { useUpdateMe } from 'store/account/hooks';
import * as Styled from './Preferences.styled';

export interface PreferencesProps {}

export const Preferences = (props: PreferencesProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const profile = useSelector((state: StoreState) => state.account.profile?.data);

  const updateMe = useUpdateMe();

  const submit = useCallback(
    async (values) => {
      const res = await updateMe({
        variables: {
          input: values,
        },
      });
      if (res) {
        history.push(pushRoute('settings', lg));
      }
    },
    [updateMe, history, lg],
  );

  return (
    <Styled.Preferences>
      <PageTitle
        title="Preferences"
        topLabel="Back"
        topAction={() => {
          history.push(pushRoute('settings', lg));
        }}
      />
      <Form component={PreferencesForm} initialValues={{ settings: profile?.settings || {} }} onSubmit={submit} />
    </Styled.Preferences>
  );
};

export default Preferences;
