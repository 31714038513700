export const Routes = {
  register: {
    en: 'register',
  },
  login: {
    en: 'login',
  },
  resetPassword: {
    en: 'reset-password',
  },
  confirmResetPassword: {
    en: 'confirm-reset-password',
  },
  renewPassword: {
    en: 'renew-password',
  },
  welcome: {
    en: '',
  },
  onboarding: {
    en: 'onboarding',
  },
  introduction: {
    en: 'introduction',
  },
  preferences: {
    en: 'preferences',
  },
  home: {
    en: 'home',
  },
  event: {
    en: 'event',
    id: {
      params: ':id',
      avatars: {
        en: 'avatars',
      },
    },
  },
  events: {
    en: 'events',
    type: {
      params: ':type',
    },
  },
  suggestedEvents: {
    en: 'suggested-events',
  },
  newEvents: {
    en: 'new-events',
  },
  otherEvents: {
    en: 'other-events',
  },
  myEvents: {
    en: 'my-events',
  },
  eventDetail: {
    en: 'event-detail',
  },
  settings: {
    en: 'settings',
    profile: {
      en: 'profile',
    },
    preferences: {
      en: 'preferences',
    },
    account: {
      en: 'account',
      email: {
        en: 'change-email',
      },
      password: {
        en: 'renew-password',
      },
    },
    terms: {
      en: 'terms',
    },
    privacy: {
      en: 'privacy',
    },
  },
};

export default Routes;
