import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { pushRoute, auth, useAction } from '@makeit-studio/store';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import PageTitle from 'components/items/PageTitle';
import { Form } from 'react-final-form';

import account from 'store/account';
import ProjectRenewPasswordForm from 'components/forms/ProjectRenewPasswordForm';
import { fieldComparison } from '@makeit-studio/ui-library';
import qs from 'query-string';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useChangePassword } from 'store/account/hooks';
import * as Styled from './RenewPassword.styled';

export interface RenewPasswordProps {}

export const RenewPassword = (props: RenewPasswordProps & RouteComponentProps) => {
  const history = useHistory();
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  const renewPassword = useChangePassword();

  const passwordMatch = (values) => {
    const errors = fieldComparison({
      field1: 'newPassword',
      field2: 'newPasswordConfirmation',
      validationMessage: 'Does not match the new password above',
    })(values);
    return errors;
  };

  const submit = useCallback(
    async (values) => {
      const res = await renewPassword({
        variables: {
          input: values,
        },
      });
      history.push(pushRoute('settings.account', lg));
    },
    [renewPassword, history, lg],
  );

  return (
    <Styled.RenewPassword>
      <PageTitle
        title="Change password"
        topLabel="Back"
        topAction={() => {
          history.push(pushRoute('settings.account', lg));
        }}
      />
      <Form initialValues={{}} component={ProjectRenewPasswordForm} validate={passwordMatch} onSubmit={submit} />
    </Styled.RenewPassword>
  );
};

export default RenewPassword;
