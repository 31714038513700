import { css } from '@emotion/react';
import { getV, transition, absoluteFill } from '@makeit-studio/ui-library';

export const ProjectButton = (theme) => css`
  padding: 16px;
  font-weight: 700;
  min-height: 50px;
  height: auto;
  font-size: ${getV(theme, 'font.size.button')};
  transition: background ${getV(theme, 'transition.duration')} ${getV(theme, 'transition.timingFunction')};

  &.small-padding {
    padding: 10px;
  }

  &.right {
    margin-left: auto;
  }
  &.center {
    margin-left: auto;
    margin-right: auto;
  }
  &.left {
    margin-right: auto;
  }

  .icon {
    max-width: 20px;
    img {
      width: 100%;
    }
  }

  &.grey-light {
    background-color: white;
    border: solid 1px ${getV(theme, 'colors.border.light')};
    color: ${getV(theme, 'colors.grey')};
  }
  &.transparent {
    background-color: transparent;
    color: ${getV(theme, 'colors.primary')};
    border: solid 1px transparent;
  }

  &.secondary {
    color: ${getV(theme, 'colors.primary')};
    border: solid 1px ${getV(theme, 'colors.border.default')};
    background-color: white;
  }

  &.ternary {
    color: ${getV(theme, 'colors.primary')};
    border: none;
    background-color: transparent;
  }

  &:not(.disabled) {
    &:hover:not(.noFontEffect),
    &:active {
      /* font-weight: bold;
      letter-spacing: -0.535px; */
    }
  }

  &:active:not(.disabled) {
    position: relative;
    &:before {
      content: '';
      ${absoluteFill};
      background-color: rgba(200, 200, 200, 0.2);
    }
  }

  &.disabled {
    opacity: 1 !important;
    color: ${getV(theme, 'colors.text.primary')};
    background-color: ${getV(theme, 'colors.background.disabled')};
  }

  &.half {
    width: 48% !important;
    max-width: 48% !important;
  }

  &.full {
    width: 100% !important;
    max-width: 100% !important;
  }
  /* .icon {
    max-width: 20px;
    display: inline-flex;
    position: relative;
    // top: 2px;
    img {
      width: 100%;
    }
  } */
`;
