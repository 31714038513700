import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import { Range, getTrackBackground } from 'react-range';
import * as Styled from './ProjectRange.styled';

export interface ProjectRangeProps {
  rtl?: boolean;
  label?: string;
  input?: any;
  minValue?: number;
  maxValue?: number;
  currency?: string;
}

export const ProjectRange = (props: ProjectRangeProps) => {
  const { label, input, minValue, maxValue, currency } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const [values, setValues] = useState([minValue, maxValue]);
  const ref = useRef();

  const handleChange = useCallback(
    (value) => {
      input.onChange(value);
    },
    [input],
  );

  useEffect(() => {
    if (input.value) setValues(input?.value);
    else setValues([minValue, maxValue]);
  }, [input, minValue, maxValue]);

  return (
    <Styled.ProjectRange>
      <div className={classNames('project-label')}>
        <TextItem path={label} tag="span" />
        <TextItem path={`${values[0]} ${currency} - ${values[1]} ${currency}`} tag="span" />
      </div>

      <Range
        step={1}
        min={minValue}
        max={maxValue}
        values={values}
        onChange={handleChange}
        renderTrack={(track) => (
          <div className={classNames('project-main-line')} onMouseDown={track?.props.onMouseDown} onTouchStart={track?.props.onTouchStart}>
            <div
              className={classNames('project-track')}
              ref={track?.props.ref}
              style={{
                height: '8px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#F2F3F7', '#939196', '#F2F3F7'],
                  min: minValue,
                  max: maxValue,
                }),
                alignSelf: 'center',
              }}
            >
              {track?.children}
            </div>
          </div>
        )}
        renderThumb={(thumb: any) => (
          <div className={classNames('project-thumbs')} {...thumb?.props}>
            {thumb?.children}
          </div>
        )}
      />
    </Styled.ProjectRange>
  );
};

export default ProjectRange;
