import React from 'react';
import ReactDOM from 'react-dom';

import ClientApp from 'appComponents/ClientApp';

import { StoreConfig, configReduxStore } from '@makeit-studio/store';

import { Validator } from '@makeit-studio/ui-library';

// from the parent
import config from 'config/general';
import routes from 'config/route';

import content from 'config/content';
import apis from 'store/apis';
import * as zeus from 'config/zeus';
import * as zeusTypes from 'config/zeus/const';

import parentReducers from 'store/appReducers';
import parentSagas from 'store/appSaga';

import reportWebVitals from './reportWebVitals';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
const rootElement = document.getElementById('root');

// Se global config of the store (must be done once)
StoreConfig.setOptions(config);
Validator.setOptions({
  validationPath: config.validationPath,
});
if (content) {
  StoreConfig.setContent(content);
}
if (zeus) {
  StoreConfig.setZeus({ ...zeus, ...zeusTypes });
}
if (apis) {
  StoreConfig.setApis(apis);
}
if (routes) {
  StoreConfig.setRoutes(routes);
}

/* eslint-disable */
const preloadedState =  (window as any).__PRELOADED_STATE__;
// remove _persist from preloaded state from server to avoid problem with persist rehydratation if the front has to rehydrate too.
if (preloadedState?._persist) delete preloadedState._persist;

// Allow the passed state to be garbage-collected
delete  (window as any).__PRELOADED_STATE__;
/* eslint-enable */

const storeSetup = configReduxStore({
  initialState: preloadedState,
  reducers: parentReducers,
  sagas: parentSagas,
  useFile: !config.noStoreFile,
  useGeoLoc: !config.noStoreGeoLoc,
  useAuth: !config.noStoreAuth,
  useApp: !config.noStoreApp,
  useContent: !config.noStoreContent,
});

// Render app
const renderApp = (dev = false) => {
  if (!rootElement) throw new Error('rootElement not found!');

  const persistor = storeSetup.persistor;

  const isSSR = rootElement.hasChildNodes();
  const renderMethod = !dev && rootElement.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;

  // render or hydrate app
  const renderRoot = () =>
    renderMethod(<ClientApp store={storeSetup.store} persistor={storeSetup.persistor} history={storeSetup.history} />, rootElement);

  // allows to not use the PersistGate
  const waitPersistor = () => {
    const { bootstrapped } = persistor.getState();
    if (bootstrapped) renderRoot();
    else {
      persistor.subscribe(waitPersistor);
    }
  };

  // loadableReady must only be called in SSR
  if (isSSR) {
    // loadableReady(waitPersistor);
  } else waitPersistor();
};

// Let's Go!
// ------------------------------------
renderApp();
