import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const MainNav = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: ${v('colors.primaryLight')};
  border-radius: 4px 4px 0 0;
  flex-shrink: 0;
  z-index: 4;

  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.4);

  .nav-item {
    text-align: center;
    flex: 1;
    font-size: 16px;
    svg {
      font-size: 24px;
      stroke: ${v('colors.primary')};
    }
    &.active {
      path,
      rect {
        fill: ${v('colors.primary')};
      }
    }
    img {
      width: 100%;
      max-width: 24px;
      max-height: 24px;
      display: flex;
      margin: 0 auto;
    }
  }
`;
