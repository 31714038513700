import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import { TextItem } from '@makeit-studio/ui-store';

import * as Styled from './Logo.styled';

// assets
import logoFull from './assets/logo-full.svg';
import logo from './assets/logo.svg';
import logoFullLight from './assets/logo-full-light.svg';
import logoFullVerticalLight from './assets/logo-full-vertical-light.svg';
import logoLight from './assets/logo-light.svg';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  mode?: string;
  small?: boolean;
  alignCenter?: boolean;
}

export type LogoProps = StateProps & DispatchProps & OwnProps;

const Logo = (props: LogoProps) => {
  const { mode, small, alignCenter } = props;

  const image = useMemo(() => {
    const images = {
      full: logoFull,
      fullLight: logoFullLight,
      fullVerticalLight: logoFullVerticalLight,
      default: logo,
      defaultLight: logoLight,
    };
    return images[mode] || images.default;
  }, [mode]);

  return (
    <Styled.Logo className={classNames('logo', mode, small && 'small', alignCenter && 'align-center')}>
      <img src={image} alt="logo" />
    </Styled.Logo>
  );
};

export default Logo;
