import styled from '@emotion/styled';
import { v, grid } from '@makeit-studio/ui-library';

export const FilterForm = styled.form`
  .row {
    width: 100%;
    margin: 0 !important;
    ${grid()}
  }
  .project-label {
    text-transform: none;
    font-weight: ${v('font.weight.bold')};
    margin-top: 20px;
  }
`;
