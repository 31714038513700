import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const ListEvent = styled.div`
  margin-top: 30px;
  &:last-child {
    .list {
      border-bottom: none !important;
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    h3 {
      margin: 0;
    }
    button {
      padding: 0;
    }
  }
  .list {
    align-items: flex-start;
    display: flex;

    &.vertical {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      > * {
        width: 48%;
        margin-bottom: 20px;
      }
    }
    &.horizontal {
      overflow: auto;
      width: calc(100% + 20px);
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      padding-bottom: 30px;
      border-bottom: solid 1px #f2f3f7;
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      > * {
        min-width: 240px;
        margin-right: 20px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
`;
