import { css } from '@emotion/react';
import { getV } from '@makeit-studio/ui-library';

const dim = '20px';
const dimInside = '12px';

export const Radio = (theme) => css`
  .row-container {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 10px;

    .circle {
      border-radius: 50%;
      height: ${dim};
      width: ${dim};
      border: ${getV(theme, 'border.width.form')} solid ${getV(theme, 'colors.border.default')};
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: white;
    }
    .inside-circle {
      border-radius: 50%;
      height: ${dimInside};
      width: ${dimInside};
      background-color: ${getV(theme, 'colors.primary')};
      display: none;
    }

    &.active {
      .inside-circle {
        display: block;
      }
    }

    .text {
      font-size: 14px;
      padding-left: 6px;
      font-weight: bold;
    }

    .img {
      padding-left: 6px;
    }
  }
  .items-wrapper {
    &.inlined {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .row-container {
        padding: 7px 0;
        margin-top: 0px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &.disabled {
      .row-container {
        cursor: not-allowed;
      }
    }
  }
`;
