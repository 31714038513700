import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { pushRoute } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';

import { StoreState } from 'store/appReducers';

import PageTitle from 'components/items/PageTitle';
import { Form } from 'react-final-form';
import SearchForm from 'components/forms/SearchForm';
import { deepOmit } from '@makeit-studio/helpers';
import Filter from 'components/items/Filter';
import CategoryItem from 'components/items/CategoryItem';
import ListEvent from 'components/listItems/ListEvent';
import Card from 'components/items/Card';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import qs from 'qs';
import { cloneDeep, isEqual } from 'lodash';
import { usePrevious } from '@makeit-studio/ui-library';
import * as Styled from './ListEventLayout.styled';

interface ListEventLayoutOptionsProps {
  route?: string;
  title: string;
  text?: string;
  list?: any[];
  loading?: boolean;
}

export interface ListEventLayoutProps {
  options?: ListEventLayoutOptionsProps;
  back?: any;
  noBack?: boolean;
  children?: any;
  noCategory?: boolean;
}

export const ListEventLayout = (props: ListEventLayoutProps) => {
  const { options, back, children, noCategory } = props;
  const lg = useSelector((state: StoreState) => state.content.lg);
  const eventsCategory = useSelector((state: StoreState) => state.event.types?.data);

  const history = useHistory();

  const location = useLocation();

  const params = useParams();

  const [search, setSearch] = useState(location?.search?.[0] === '?' ? qs.parse(location?.search?.substring(1)) : null);

  const route = useMemo(() => options?.route || 'home', [options]);

  const handleClickCategory = useCallback(
    (id) => {
      const res = cloneDeep(search?.typeIds || []) as string[]; // Todo: review
      const index = res.indexOf(id);
      if (index === -1) res.push(id);
      else res.splice(index, 1);

      if (res.length === 0) setSearch(deepOmit(search, 'typeIds'));
      else setSearch({ ...search, typeIds: res });
    },
    [search, setSearch],
  );

  const pathname = useMemo(() => pushRoute(route, lg, params), [route, lg, params]);

  const prevSearch = usePrevious(search);
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (isEqual(prevSearch, search) && isEqual(prevPathname, pathname)) return;

    history.replace({
      pathname,
      search: qs.stringify(search),
    });
  }, [history, prevSearch, prevPathname, pathname, search]);

  // Update search if urls params changes
  useEffect(() => {
    setSearch(location?.search?.[0] === '?' ? qs.parse(location?.search?.substring(1)) : null);
  }, [location.search]);

  const pageTitleBackProps = !back ? {} : { topLabel: 'Back', topAction: () => back() };

  return (
    <Styled.ListEventLayout className={classNames('list-event-layout')}>
      <PageTitle title={options?.title} text={options?.text} {...pageTitleBackProps} />
      <div className={classNames('search-filter-wrapper')}>
        <div className={classNames('search-wrapper')}>
          <Form
            initialValues={search}
            component={SearchForm}
            onSubmit={(e) => {
              if (!e.searchText) setSearch({ ...deepOmit(e, 'searchText') });
              else setSearch(cloneDeep(e));
            }}
          />
        </div>
        <Filter
          initialValues={search}
          onSubmit={(e) => {
            setSearch(cloneDeep(e));
          }}
        />
      </div>
      {!noCategory && (
        <div className={classNames('category-wrapper')}>
          {eventsCategory?.items?.map((item, key) => (
            <CategoryItem
              neutral
              canActive
              category={item}
              key={key}
              action={handleClickCategory}
              active={Array.isArray(search?.typeIds) && search?.typeIds.includes(item?._id)}
            />
          ))}
        </div>
      )}
      {children || (
        <ListEvent vertical>
          {options?.list?.map((item, key) => (
            <Card data={item} key={key} action={() => history.push(pushRoute('event.id', lg, { id: item._id }))} />
          ))}
          {options?.list?.length === 0 && !options?.loading && <TextItem path="No event found" />}
        </ListEvent>
      )}
    </Styled.ListEventLayout>
  );
};

export default ListEventLayout;
