import React, { useCallback } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

import NavItem from 'components/listItems/NavItem';
import * as Styled from './Tabs.styled';

export interface TabsProps {
  isObject?: boolean;
  items?: any[];
  small?: boolean;
  tabAction?: Function;
  activeKey?: string;
  navItem?: any;
  selected?: string;
}

export const Tabs = (props: TabsProps) => {
  // mapStateToProps
  const { isObject, items, small, tabAction, activeKey = 'key', navItem: NavElement = NavItem, selected } = props;

  const handleSelection = useCallback(
    (item) => {
      if (isObject) {
        tabAction(item);
      } else {
        tabAction(item[activeKey]);
      }
    },
    [isObject, tabAction, activeKey],
  );

  return (
    <Styled.Tabs>
      {items.map((item, index) => {
        if (!item) return false;
        return (
          <NavElement
            tab
            small={small}
            key={index}
            to={item.to || item.link}
            active={item[activeKey] && item[activeKey] === selected}
            action={tabAction ? () => handleSelection(item) : null}
            label={item.label}
            expanded
          />
        );
      })}
    </Styled.Tabs>
  );
};

export default Tabs;
