import { createActionStructure, createAsyncStructure, createResourceStructure } from '@makeit-studio/store/dist/utils/actionsToolkit';

import constants from './constants';

const actions = {
  ...createResourceStructure(`${constants.prefix}`),
  types: createAsyncStructure(`${constants.prefix}Types`),
  recent: createAsyncStructure(`${constants.prefix}Recent`),
  suggested: createAsyncStructure(`${constants.prefix}Suggested`),
  pinned: createAsyncStructure(`${constants.prefix}Pinned`),
  filterActive: createActionStructure(`${constants.prefix}FilterActive`),
};

export default actions;
