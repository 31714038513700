import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { cloneDeep } from 'lodash';

export const useSearchParams = () => {
  const location = useLocation();

  const parsedSearch = useMemo(() => {
    let toParse = location.search;
    if (toParse.charAt(0) === '?') {
      toParse = toParse.substring(1);
    }
    return toParse ? qs.parse(toParse) : null;
  }, [location.search]);

  // Transform search params into query variables
  const eventSearch = useMemo(() => {
    const search = cloneDeep(parsedSearch) as any;
    if (search?.price?.length === 2) search.price = { min: parseInt(search.price[0], 10), max: parseInt(search.price[1], 10) };
    if (search?.avatars?.length === 2) search.avatars = { min: parseInt(search.avatars[0], 10), max: parseInt(search.avatars[1], 10) };
    if (search?.res?.typeIds === 'string') search.typeIds = [search.typeIds];

    return search;
  }, [parsedSearch]);

  return { eventSearch, parsedSearch, location };
};
