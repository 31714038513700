import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const CategoryItem = styled.div`
  display: flex;
  &.active {
    .tag {
      color: white !important;
      background-color: ${v('colors.primary')} !important;
    }
  }
  .tag {
    font-size: ${v('font.size.small')};
    color: white;
    font-weight: ${v('font.weight.bold')};
    background-color: ${v('colors.primary')};
    padding: 4px 8px;
    margin-right: 10px;
    border-radius: ${v('border.radius.default')};
    white-space: nowrap;

    &.light {
      background-color: white;
      color: ${v('colors.primary')};
    }
    &.neutral {
      background-color: ${v('colors.primaryLight')};
      color: ${v('colors.primary')};
    }
  }
`;
