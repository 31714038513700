declare let vuplex: any;

export enum UnityEvents { // eslint-disable-line
  READY = 'ready',
  SWITCH_SCENE = 'switchScene',
  LOAD_STREAM = 'loadStream',
  LOAD_AVATAR = 'loadAvatar',
  SET_AUTH_DATA = 'setAuthData',
}

export interface UnityPayload {
  event: UnityEvents;

  [key: string]: string | undefined;
}

export type UnityEventListener = (payload: UnityPayload) => void;

export enum UnityScenes { // eslint-disable-line
  WEBVIEW = 'WebviewScene',
  LIVE = 'StreamScene',
}

export class UnityBridge {
  log = (...args: any) => {
    console.log('[UnityBridge]', ...args);
  };

  protected _ready = false;

  protected _noThrow = true;

  protected get _webview() {
    return (window as any).vuplex as typeof vuplex;
  }

  protected _listeners: Record<UnityEvents, UnityEventListener[]> = {
    [UnityEvents.READY]: [],
    [UnityEvents.SWITCH_SCENE]: [],
    [UnityEvents.LOAD_STREAM]: [],
    [UnityEvents.LOAD_AVATAR]: [],
    [UnityEvents.SET_AUTH_DATA]: [],
  };

  constructor() {
    if (this._webview) {
      this._onReady();
    } else {
      window.addEventListener('vuplexready', () => {
        this._onReady();
      });
    }
  }

  protected _onReady() {
    this.log('ready');

    this._ready = true;

    this._webview.addEventListener('message', (event: any) => this._onMessage(event));

    this.emit(UnityEvents.READY);
  }

  protected _onMessage(event: any) {
    const json: Record<string, string | undefined> | undefined = event.data;

    if (!json) return;

    const { type, ...payload } = json;

    if (!type || !UnityEvents[type as keyof typeof UnityEvents]) return;

    this.emit(type as UnityEvents, payload);
  }

  protected _raiseException(...args: any): this {
    if (this._noThrow) return this;

    throw new Error(...args);
  }

  isReady() {
    return this._ready;
  }

  emit(event: UnityEvents, payload?: Omit<UnityPayload, 'event'>): this {
    this._listeners[event].forEach((cb) => cb({ event, ...payload }));

    return this;
  }

  on(event: UnityEvents, handler: UnityEventListener): this {
    this._listeners[event].push(handler);

    return this;
  }

  send(event: UnityEvents, payload: Omit<UnityPayload, 'event'>): this {
    console.log('UnityBridge: sending', event, payload);

    if (!this.isReady()) return this._raiseException('UnityBridge is not ready yet.');

    this._webview.postMessage({
      type: event,
      ...payload,
    });

    return this;
  }

  switchScene(scene: UnityScenes, payload?: Record<string, any>): this {
    console.log('UnityBridge: sceneSwitch(', scene, ')');
    return this.send(UnityEvents.SWITCH_SCENE, { scene, ...payload });
  }

  loadStream(payload: Record<string, string>): this {
    console.log('UnityBridge: loadStream(', payload, ')');
    return this.send(UnityEvents.LOAD_STREAM, payload);
  }

  loadAvatar(avatarId: string, eventId: string): this {
    console.log('UnityBridge: loadAvatar(', avatarId, eventId, ')');
    return this.send(UnityEvents.LOAD_AVATAR, { avatarId, eventId });
  }

  setAuthData(token: string): this {
    return this.send(UnityEvents.SET_AUTH_DATA, { token });
  }

  reload(): this {
    return this.send(UnityEvents.SWITCH_SCENE, { scene: UnityScenes.WEBVIEW });
  }
}
