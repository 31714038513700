import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { pushRoute, auth, useAction } from '@makeit-studio/store';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { NavLink, withRouter, useHistory, RouteComponentProps } from 'react-router-dom';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import PageTitle from 'components/items/PageTitle';
import * as Styled from './Account.styled';

// assets

import account from './assets/account.svg';
import pwd from './assets/pwd.svg';
import profile from './assets/profile.svg';

export interface AccountProps {}

export const Account = (props: AccountProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  const navItem = [
    // {
    //   link: 'settings.account.renew-email',
    //   icon: account,
    //   title: 'Change email',
    // },
    {
      link: 'settings.account.renew-password',
      icon: pwd,
      title: 'Change password',
    },
    {
      link: 'settings.account.change-email',
      icon: profile,
      title: 'Change email address',
    },
  ];

  return (
    <Styled.Account>
      <PageTitle
        title="Account"
        topLabel="Back"
        topAction={() => {
          history.push(pushRoute('settings', lg));
        }}
      />
      {navItem.map((item, key) => (
        <NavLink exact to={pushRoute(item.link, lg)} className={classNames('settings-nav-item')} key={key}>
          <div className={classNames('item-wrapper')}>
            <div className={classNames('image-wrapper')}>
              <img src={item.icon} alt={item.icon} />
            </div>
            <TextItem path={item.title} tag="h4" />
          </div>
          <IoChevronForward />
        </NavLink>
      ))}
    </Styled.Account>
  );
};

export default Account;
