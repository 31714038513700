import React, { useState, useEffect, useCallback } from 'react';

import { NavLink } from 'react-router-dom';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

// Import actions here

import classNames from 'classnames/bind';
import { TextItem, SimpleTooltip } from '@makeit-studio/ui-store';
import { RiArrowDownSLine } from 'react-icons/ri';

import config from 'config/general';

// assets
import * as Styled from './NavItem.styled';

export interface StateProps {
  className?: string;
  tab?: boolean;
  addArrow?: boolean;
  active?: boolean;
  action?: () => any;
  to?: string;
  label?: string;
  base?: number;
  answered?: number;
  compliance?: number;
  expanded?: boolean;
  noArrow?: boolean;
  exact?: boolean;
  subMenu?: any;
  small?: boolean;
  iconComponent?: any;
  icon?: string;
  opened?: boolean;
}

export interface DispatchProps {}

export interface OwnProps {}

export type NavItemProps = StateProps & DispatchProps & OwnProps;

const NavItem = (props: NavItemProps) => {
  const {
    className,
    tab,
    addArrow,
    active,
    opened,
    action,
    to,
    label,
    icon,
    expanded,
    iconComponent: IconComponent,
    small,
    subMenu,
    exact,
    noArrow,
  } = props;

  const [isOpened, setIsOpened] = useState(opened);

  useEffect(() => {
    setIsOpened(opened);
  }, [opened]);
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  const CHAR_LIMIT = 50;

  const text = label?.[lg] || label?.[config.defaultLanguage] || label;

  const content = (
    <div className={classNames('left')}>
      {IconComponent && <IconComponent className={classNames('icon')} />}
      {icon && icon.indexOf('/') >= 0 && (
        <div className={classNames('icon')}>
          <img src={icon} alt="icon" />
        </div>
      )}
      {text?.length > CHAR_LIMIT && (
        <SimpleTooltip path={label} direction="top">
          <TextItem className={classNames('text')} path={label} />
        </SimpleTooltip>
      )}
      {text?.length <= CHAR_LIMIT && <TextItem className={classNames('text')} path={label} />}
    </div>
  );

  const toggleSubMenu = () => {
    setIsOpened(!isOpened);
  };

  const renderSub = () => {
    if (!subMenu) return false;
    if (!isOpened) return false;

    return <Styled.SubItem className={classNames('sub', isOpened && 'opened')}>{subMenu}</Styled.SubItem>;
  };

  const renderActionable = () => {
    if (action) {
      return (
        <div className={classNames(className, 'nav-item', active && 'active')} onClick={action}>
          {content}
        </div>
      );
    }
    if (to) {
      return (
        <NavLink exact={exact} className={classNames(className, 'nav-item')} activeClassName={classNames('active')} to={to}>
          {content}
        </NavLink>
      );
    }
    return (
      <div className={classNames(className, 'nav-item', active && 'active')} onClick={toggleSubMenu}>
        {content}
      </div>
    );
  };

  return (
    <>
      <Styled.NavItem className={classNames('main', tab && 'tab', small && 'small')}>
        {renderActionable()}
        {(addArrow || subMenu) && !noArrow && (
          <div className={classNames('arrow', isOpened && 'active')} onClick={() => toggleSubMenu()}>
            <RiArrowDownSLine />
          </div>
        )}
      </Styled.NavItem>
      {renderSub()}
    </>
  );
};

export default NavItem;
