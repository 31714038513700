import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const EventAvatars = styled.div`
  position: relative;
  background-color: ${v('colors.primaryLight')};
  height: auto;
  min-height: 100%;
  .content-tab,
  .stream-wrapper {
    .subtitle {
      display: flex;
      text-transform: ${v('font.style.uppercase')};
      margin-bottom: 20px;
      .bold {
        font-weight: ${v('font.weight.bold')};
        margin-right: 2px;
      }
    }
  }

  .stream-wrapper {
    margin-top: 20px;
    iframe {
      border-radius: ${v('border.radius.default')};
    }
  }
  .provider-stream {
    position: relative;
    .button-wrapper {
      /* position: absolute;*/
      width: 100%;
      height: 99%;
      z-index: 2;
      padding: 20px;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: ${v('border.radius.default')};
      button {
        margin-top: 0;
      }
    }
  }
`;
