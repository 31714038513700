import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import { ClassNames, useTheme } from '@emotion/react';
import ProjectButton from 'components/items/ProjectButton';
import Logo from 'components/items/Logo';
import { RouteComponentProps } from 'react-router-dom';
import * as Styled from './Introduction.styled';

// assets
import backgroundWelcome from './assets/welcome-image.png';
import tick from './assets/custom-tick.svg';

export interface IntroductionProps {}

export const Introduction = (props: IntroductionProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const theme = useTheme();

  const list = [
    'Be in the pub with fans at 4:00 PM',
    'Be at the fan village at 5:00 PM',
    'Be at the hot dog stand at 8:00 PM',
    'Be at the game at 9:00 PM',
  ];

  return (
    <ClassNames>
      {({ css, cx }) => (
        <div
          className={cx(
            css`
              ${Styled.Introduction(theme)}
            `,
          )}
        >
          <div className={classNames('image-wrapper', 'full')}>
            <Logo mode="fullLight" />
            <div className={classNames('overlay')} />
            <img src={backgroundWelcome} alt={backgroundWelcome} className={classNames('background')} />
          </div>
          <div className={classNames('heading', 'align-center')}>
            <TextItem tag="h1" path="Customize your experience" />
            <TextItem tag="p" path="Be your own director and choose where you want to be!" />
          </div>
          <hr />
          <div className={classNames('content', 'align-left')}>
            <TextItem path="<h2>Switch & slide between <b>20 avatar experiences in 360°</b></h2>" isHtml />
            {list.map((elem, key) => (
              <div className={classNames('list-item')} key={key}>
                <img src={tick} alt={tick} />
                <TextItem tag="p" path={elem} isHtml />
              </div>
            ))}
          </div>
          <div className={classNames('inline-buttons')}>
            <ProjectButton
              label="See all events"
              colorClassName="ternary"
              className="half"
              action={() => {
                history.push('home', lg);
              }}
            />
            <ProjectButton
              angleRight
              label="Create profile"
              className="half"
              action={() => {
                history.push('register', lg);
              }}
            />
          </div>
          <div className={classNames('inline-buttons')}>
            <ProjectButton
              angleRight
              label="Login"
              // colorClassName="ternary"
              className="margin-top"
              action={() => {
                history.push('login', lg);
              }}
            />
          </div>
        </div>
      )}
    </ClassNames>
  );
};

export default Introduction;
