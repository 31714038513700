import account from 'store/account';
import event from 'store/event';

import { StoreState as miStoreState } from '@makeit-studio/store';
import auth from 'store/auth';

export const reducers = {
  account: account.reducer,
  event: event.reducer,
  auth: auth.reducer,
};

export type StoreState = miStoreState & {
  account: ReturnType<typeof account.reducer>;
  event: ReturnType<typeof event.reducer>;
  auth: ReturnType<typeof auth.reducer>;
};

export default reducers;
