import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { FormState } from 'final-form';

import { useSelector } from 'react-redux';

import { required, email, composeValidators } from '@makeit-studio/ui-library';

import ProjectInput from 'components/formItems/ProjectInput';
import ProjectButton from 'components/items/ProjectButton';

import { StoreState } from 'store/appReducers';

import { passwordValidation } from 'utils/helpers';
import * as Styled from './ConfirmPasswordResetForm.styled';

export interface ConfirmPasswordResetFormOwnProps {}

export type ConfirmPasswordResetFormProps = FormRenderProps & FormState<any> & ConfirmPasswordResetFormOwnProps;

const ConfirmPasswordResetForm = (props: ConfirmPasswordResetFormProps) => {
  const { handleSubmit, submitting, valid } = props;

  const content = useSelector((state: StoreState) => state.content.raw);

  return (
    <Styled.ConfirmPasswordResetForm onSubmit={handleSubmit}>
      <Field withValueBorder component={ProjectInput} name="code" type="text" label="Code" validate={required} />
      <Field withValueBorder component={ProjectInput} name="email" type="email" label="Email" validate={composeValidators(required, email)} />
      <Field
        withValueBorder
        component={ProjectInput}
        name="newPassword"
        seed="password"
        label="New Password"
        validate={composeValidators(required, passwordValidation)}
      />
      <Field withValueBorder component={ProjectInput} name="confirmPassword" seed="password" label="Confirm New Password" validate={required} />
      <ProjectButton disabled={submitting || !valid} label="Submit" type="submit" angleRight />
    </Styled.ConfirmPasswordResetForm>
  );
};

export default ConfirmPasswordResetForm;
