import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { FiDownload, FiPlusCircle } from 'react-icons/fi';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { ClassNames, useTheme } from '@emotion/react';
import { Button, ButtonProps } from '@makeit-studio/ui-store';
// import { ButtonProps } from '@makeit-studio/ui-library';
import * as Styled from './ProjectButton.styled';

// assets
import logoWhiteLabel from './assets/logo-small.svg';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  label?: string;
  className?: string;
  customClassName?: string;
  disabled?: boolean;
  colorClassName?: string;
  align?: string;
  angleRight?: any;
  plusLeft?: any;
  noEffect?: boolean;
  downloadLeft?: any;
  angleLeft?: any;
  logoLeft?: boolean;
  relative?: boolean;
  noFontEffect?: boolean;
  smallPadding?: boolean;
}

export type ProjectButtonProps = StateProps & DispatchProps & OwnProps & ButtonProps;

const ProjectButton = (props: ProjectButtonProps) => {
  const {
    noHover,
    className,
    customClassName,
    disabled,
    colorClassName = 'primary',
    align = 'right',
    angleRight,
    plusLeft,
    noEffect,
    downloadLeft,
    angleLeft,
    logoLeft,
    relative,
    noFontEffect,
    smallPadding,
  } = props;

  const theme = useTheme();

  let iconLeft;
  if (plusLeft) iconLeft = <FiPlusCircle />;
  if (downloadLeft) iconLeft = <FiDownload />;
  if (angleLeft) iconLeft = <IoChevronBack />;
  if (logoLeft) iconLeft = <img src={logoWhiteLabel} alt="logo" />;

  let iconRight;
  if (angleRight) iconRight = <IoChevronForward />;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Button
          noHover
          iconEffect={!noEffect}
          iconRight={iconRight}
          iconLeft={iconLeft}
          {...props}
          className={cx(
            relative && 'relative',
            disabled && 'disabled',
            noFontEffect && 'noFontEffect',
            colorClassName,
            align,
            customClassName,
            className,
            smallPadding && 'small-padding',
            css`
              ${Styled.ProjectButton(theme)}
            `,
          )}
        />
      )}
    </ClassNames>
  );
};

export default ProjectButton;
