import React, { useCallback } from 'react';
import { useTheme } from '@emotion/react';
import { IoChevronForward } from 'react-icons/io5';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import CategoryItem from 'components/items//CategoryItem';
import moment from 'moment';
import * as Styled from './Card.styled';
import Pinned from '../Pinned';
import Placeholder from './assets/placeholder.png';

export interface CardProps {
  action?: () => void;
  data?: any;
}

export const Card = (props: CardProps) => {
  const { action, data } = props;
  // mapStateToProps
  const profile = useSelector((state: StoreState) => state.account?.profile?.data);
  const theme = useTheme();

  return (
    <Styled.Card>
      <div onClick={action}>
        <div className={classNames('image-wrapper')}>
          {profile && <Pinned light active={profile?.pinnedEventsIds?.includes(data?._id)} />}
          <div className={classNames('thumbnail')}>
            <img
              src={data?.background ? data?.background?.getFile?.getUrl : Placeholder}
              alt={data?.background ? data?.background?.title : 'Placeholder Picture'}
            />
          </div>
        </div>
        <div className={classNames('top')}>
          <CategoryItem category={data?.type} />
          {/* <TextItem path={tag} tag="span" className={classNames('tag')} /> */}
          <div className={classNames('date')}>
            <TextItem path={moment(data?.startAt).format('MMM DD, YYYY - HH:mm')} tag="div" />
            {data?.endAt && <TextItem path={moment(data?.endAt).format('MMM DD, YYYY - HH:mm')} tag="div" />}
          </div>
        </div>
        <div className={classNames('link')}>
          <TextItem path={data?.name} tag="span" />
          <IoChevronForward />
        </div>
      </div>
    </Styled.Card>
  );
};

export default Card;
