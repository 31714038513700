import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Main from 'components/layouts/Main';
import { ApolloProvider } from '@apollo/client';
import { IconContext } from 'react-icons';

import { StaticApollo } from '@makeit-studio/store';
import { ThemeProvider } from '@emotion/react';
import { defaultTheme } from '@makeit-studio/ui-library';
import { UnityProvider } from '../lib';

// Default app used without SSR
const ClientApp = (props) => {
  const { store, history } = props;

  const iconStyle = useMemo(() => ({ style: { verticalAlign: 'middle' } }), []);

  return (
    <UnityProvider>
      <ApolloProvider client={StaticApollo.getInstance()}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <IconContext.Provider value={iconStyle}>
              <ThemeProvider theme={defaultTheme}>
                <Main />
              </ThemeProvider>
            </IconContext.Provider>
          </ConnectedRouter>
        </Provider>
      </ApolloProvider>
    </UnityProvider>
  );
};

export default ClientApp;
