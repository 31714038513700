import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { pushRoute, auth, useAction, app } from '@makeit-studio/store';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import PageTitle from 'components/items/PageTitle';
import CategoryItem from 'components/items/CategoryItem';
import { IoBookmarkOutline, IoCalendarClearOutline, IoTimeOutline, IoLocationOutline } from 'react-icons/io5';

import ProjectButton from 'components/items/ProjectButton';
import TeamItem from 'components/items/TeamItem';
import Tabs from 'components/items/Tabs';
import ProjectCountdown from 'components/items/ProjectCountdown';
import Pinned from 'components/items/Pinned';
import { useDetail as useEventDetail } from 'store/event/hooks';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { randomUUID } from 'crypto';
import * as Styled from './EventDetail.styled';

// assets
import redspart from './assets/redspart.png';
import reds from './assets/redstar-logo.svg';
import partizan from './assets/partizan-logo.svg';

export interface EventDetailProps {
  history?: any;
}

export const EventDetail = (props: EventDetailProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const profile = useSelector((state: StoreState) => state.account.profile?.data);
  const eventMeta = useSelector((state: StoreState) => state.event.detail);
  const event = useMemo(() => eventMeta?.data, [eventMeta]);

  const [once, setOnce] = useState(false);

  const dialogShow = useAction(app.actions.dialog.show);

  const [currentTab, setCurrentTab] = useState('tab1');

  const loadEvent = useEventDetail();

  const params = useParams();

  const currentId = useMemo(() => event?._id, [event]);

  // (re)load (silently if loaded) the event => reload always for image refresh
  useEffect(() => {
    if (once) return;

    setOnce(true);

    console.log('LOAD');

    loadEvent({
      variables: { id: params.id, reset: currentId !== params.id },
      noLoading: true,
    });
  }, [loadEvent, once, currentId, params.id]);

  const handleGoToEvent = useCallback(() => {
    if (!profile) {
      dialogShow({
        id: 'not-logged',
        wrapperClassName: classNames('modal-wrapper'),
        title: 'You are not yet logged',
        message: 'To get access at this event, please login you',
        actions: [
          { label: 'Create an account', action: () => history.push(pushRoute('register', lg)) },
          { label: 'Login', action: () => history.push(pushRoute('login', lg)) },
        ],
      });
      return;
    }

    history.push(pushRoute('event.id.avatars', lg, params));
  }, [profile, dialogShow, history, lg, params]);

  return (
    <>
      <Styled.EventDetail>
        <div className={classNames('background-absolute')} />
        <PageTitle
          topLabel="Back"
          topAction={() => {
            history.goBack();
          }}
        />
        <div className={classNames('top-detail')}>
          <div className={classNames('top')}>
            {event?.type?.name && <CategoryItem light category={event?.type?.name} />}
            {profile && <Pinned id={event?._id} light />}
          </div>
          <ProjectCountdown value={moment(event?.startAt).format()} />
          <div className={classNames('image-wrapper')}>
            <div className={classNames('overlay')} />
            {event?.background?.getFile?.getUrl && <img src={event?.background?.getFile?.getUrl} alt="background" />}
          </div>
        </div>
        {event && (
          <div className={classNames('detail')}>
            <div className={classNames('title')}>
              <TextItem tag="p" path={`Welcome ${profile?.firstName || ''}`} />
            </div>
            {/* <div className={classNames('text')}>
          <TextItem path="<p>Your match choice is <br><b>FK Red Star VS FK Partizan</b></p>" isHtml />
        </div> */}
            <div className={classNames('info')}>
              <div className={classNames('text')}>
                <TextItem path="Your match choice is" tag="p" />
              </div>
              <div className={classNames('text', 'bold')}>
                <TextItem path={`${event?.participants?.[0].name} vs. ${event?.participants?.[1].name}`} tag="p" isHtml />
              </div>
              <div className={classNames('text')}>
                <TextItem path="Choose your favourite team" tag="p" />
              </div>
              <div className={classNames('team-wrapper')}>
                {event?.participants?.map((item, key) => {
                  if (key === event.participants.length - 1) {
                    return <TeamItem data={item} key={key} />;
                  }
                  return (
                    <React.Fragment key={key}>
                      <TeamItem data={item} />
                      <TextItem path="VS" tag="span" />
                    </React.Fragment>
                  );
                })}
              </div>
              <div className={classNames('info-bottom')}>
                <div className={classNames('info-item')}>
                  <IoCalendarClearOutline />
                  <TextItem path={moment(event?.startAt)?.format('MMM DD, YYYY')} tag="span" />
                </div>
                <div className={classNames('info-item')}>
                  <IoTimeOutline />
                  <TextItem path={moment(event?.startAt).format('hh:mm')} tag="span" />
                </div>
              </div>
            </div>
          </div>
        )}
        {event && (
          <div className={classNames('tabs-wrapper')}>
            <Tabs items={[{ label: 'Event Infos', key: 'tab1' }]} tabAction={(e) => setCurrentTab(e)} selected={currentTab} />
            <div className={classNames('content-tab')}>
              {currentTab === 'tab1' && (
                <>
                  <div className={classNames('tab-sub-item')}>
                    <IoCalendarClearOutline />
                    <TextItem path={moment(event?.startAt).format('MMM DD, YYYY')} />
                  </div>
                  <div className={classNames('tab-sub-item')}>
                    <IoTimeOutline />
                    <TextItem path={moment(event?.startAt).format('hh:mm')} />
                  </div>
                  <div className={classNames('tab-sub-item')}>
                    <IoLocationOutline />
                    <TextItem path={event?.location?.city} />
                  </div>
                  <TextItem path={event?.description} tag="p" />
                </>
              )}
            </div>
          </div>
        )}
      </Styled.EventDetail>
      <Styled.Buttons>
        <ProjectButton angleRight label="Go to the event" className="absolute" action={handleGoToEvent} />
      </Styled.Buttons>
    </>
  );
};

export default EventDetail;
