import { deepOmit } from '@makeit-studio/helpers';
import { $ } from 'config/zeus';
import { useApollo, auth, StoreConfig } from '@makeit-studio/store';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import * as models from './models';
import actions from './actions';

export const treatmentOut = (values) => {
  const output = {
    ...values,
  };

  return deepOmit(output, ['_id', '__typename', 'createdAt', 'updatedAt', 'get.*']);
};

export const useSignUp = () => {
  return useApollo({
    type: 'mutation',
    action: actions.signup,
    name: 'register',
    model: models.accountModel,
    inputs: {
      input: $`input`,
    },
  });
};

export const useResetPassword = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      action: actions.resetPassword,
      name: 'resetPassword',
      model: true,
      addInputVariable: false,
      inputs: {
        email: $`email`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useConfirmResetPassword = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      action: actions.confirmResetPassword,
      name: 'confirmResetPassword',
      model: true,
      addInputVariable: false,
      inputs: {
        code: $`code`,
        email: $`email`,
        newPassword: $`newPassword`,
        confirmPassword: $`confirmPassword`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useChangePassword = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      action: actions.changePassword,
      name: 'updatePassword',
      model: models.accountModel,
      inputs: {
        input: $`input`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useChangeEmail = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      action: actions.changeEmail,
      name: 'updateEmail',
      model: models.accountModel,
      inputs: {
        input: $`input`,
      },
    }),
    [],
  );
  return useApollo(params);
};

export const useMe = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.me,
      name: 'me',
      model: models.accountModel,
    }),
    [],
  );

  return useApollo(params);
};

export const useUpdateMe = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      actionRequest: actions.updateMe.request,
      actionResult: actions.me.result,
      name: 'accountsEditSelf',
      model: models.accountModel,
      inputModel: 'EditAccountInputDto',
      inputs: {
        input: $`input`,
      },
      treatmentOut,
    }),
    [],
  );

  return useApollo(params);
};

export const useAddPinned = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      actionRequest: actions.addPinned.request,
      actionResult: actions.me.result,
      name: 'addPinnedEvent',
      model: models.accountModel,
      addInputVariable: false,
      inputs: {
        id: $`id`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useRemovePinned = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      actionRequest: actions.removePinned.request,
      actionResult: actions.me.result,
      name: 'removePinnedEvent',
      model: models.accountModel,
      addInputVariable: false,
      inputs: {
        id: $`id`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useAccountInit = () => {
  const loadMe = useMe();

  const accountInit = useCallback(async () => {
    await loadMe();
  }, [loadMe]);

  return accountInit;
};

export const useAfterLogin = () => {
  const loadMe = useMe();

  const afterLogin = useCallback(async () => {
    await loadMe().then((result) => {
      // If there's an error upon fetching the login don't go forward
      if (result && result[0] && result[0].extensions?.code) throw new Error('Invalid Token');
      return result;
    });
  }, [loadMe]);

  return afterLogin;
};

export const useListAccounts = () => {
  const params = useMemo(
    () => ({
      type: 'query',
      action: actions.list,
      name: 'accounts',
      model: {
        items: models.accountListModel,
      },
      inputs: {
        page: 1,
        limit: 10,
        sortBy: {
          createdAt: 'DESC',
        },
        searchBy: $`search`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useCheckRegistration = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      action: actions.checkRegistration,
      name: 'checkRegistrationStatus',
      model: true,
      addInputVariable: false,
      inputs: {
        id: $`id`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useResendCode = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      action: actions.resendCode,
      name: 'resendConfirmationCode',
      model: true,
      addInputVariable: false,
      inputs: {
        id: $`id`,
      },
    }),
    [],
  );

  return useApollo(params);
};

export const useConfirmRegistration = () => {
  const params = useMemo(
    () => ({
      type: 'mutation',
      action: actions.confirmRegistration,
      name: 'confirmRegistration',
      model: true,
      addInputVariable: false,
      inputs: {
        id: $`id`,
        code: $`code`,
        email: $`email`,
      },
    }),
    [],
  );

  return useApollo(params);
};
