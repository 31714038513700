import React from 'react';

import classNames from 'classnames/bind';

import { FaCheck } from 'react-icons/fa';

import * as Styled from './CheckableBox.styled';

export interface CheckableBoxProps {
  active?: boolean;
  className?: string;
  small?: boolean;
}

const CheckableBox = (props: CheckableBoxProps) => {
  const { active, className, small } = props;

  return <Styled.CheckableBox className={classNames(className, small && 'small', active && 'active')}>{active && <FaCheck />}</Styled.CheckableBox>;
};

export default CheckableBox;
