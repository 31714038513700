import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

// Import actions here

import classNames from 'classnames/bind';
import { TextItem } from '@makeit-studio/ui-store';
import { IoChevronBack } from 'react-icons/io5';
import { Form } from 'react-final-form';

import { NavLink, RouteComponentProps } from 'react-router-dom';

import { pushRoute, useAction } from '@makeit-studio/store/dist';
import account from 'store/account';
import ProjectRenewPasswordForm from 'components/forms/ProjectRenewPasswordForm';
import { fieldComparison } from '@makeit-studio/ui-library';
import qs from 'query-string';
import * as Styled from './RenewPassword.styled';

export interface RenewPasswordProps {}

const RenewPassword = (props: RenewPasswordProps & RouteComponentProps) => {
  const {
    history,
    location: { search },
  } = props;

  const params = qs.parse(search);
  const renewPassword = useAction(account.actions.renewPassword);

  const passwordMatch = (values) => {
    const errors = fieldComparison({
      field1: 'password',
      field2: 'confirmPassword',
      validationMessage: 'Does not match the new password above',
    })(values);
    return errors;
  };

  const submit = useCallback(
    async (values) => {
      console.log('values', values);
      await renewPassword({
        token: params.token,
        password: values.password,
        success: 'Password successfully updated',
      });

      history.push(pushRoute('login'));
    },
    [params, renewPassword, history],
  );

  return (
    <Styled.RenewPassword>
      <NavLink to={pushRoute('login')} className={classNames('back')}>
        <IoChevronBack />
      </NavLink>
      <div className={classNames('heading', 'align-center')}>
        <TextItem tag="h1" path="Create new password" />
        <TextItem tag="p" path="Your new password must be different from previous used passwords." />
      </div>
      <Form initialValues={{}} component={ProjectRenewPasswordForm} validate={passwordMatch} onSubmit={submit} />
    </Styled.RenewPassword>
  );
};

export default RenewPassword;
