import { createActionStructure, createAsyncStructure } from '@makeit-studio/store/dist/utils/actionsToolkit';

import constants from './constants';

const actions = {
  login: createAsyncStructure(`${constants.prefix}Login`),
  logout: createAsyncStructure(`${constants.prefix}Logout`),
  setOther: createActionStructure(`${constants.prefix}SetOther`),
};

export default actions;
