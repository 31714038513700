import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const Pinned = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    font-size: 24px;
    stroke: ${v('colors.primary')};
  }

  &.active {
    path {
      fill: ${v('colors.primary')};
    }
  }

  &.light {
    svg {
      stroke: white;
    }
    &.active {
      path {
        fill: white;
      }
    }
  }
`;
