import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const Tabs = styled.div`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid ${v('colors.primary300')};
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    margin: 0;
    margin-left: 20px;
    &:first-of-type {
      margin-left: 0;
    }

    .nav-item {
      padding: 0 5px 10px 5px !important;
      .text {
        -webkit-line-clamp: 3 !important;
        text-align: center;
        color: ${v('colors.primary300')};
      }
      &.active {
        .text {
          color: ${v('colors.primary')};
          font-weight: ${v('font.weight.extraBold')};
        }
        &:after {
          width: 100% !important;
        }
      }
    }

    span {
      font-size: ${v('font.size.default')};
      text-transform: ${v('font.style.uppercase')};
      font-weight: ${v('font.weight.bold')};
    }
  }
`;
