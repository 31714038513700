import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const Header = styled.div`
  width: 100%;
  left: 0;
  top: 0;
  flex-shrink: 0;
  background-color: transparent;
  z-index: 5;
  /* border-bottom: 1px solid ${v('colors.border.light')}; */

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  &.staging {
    border-top: 4px solid ${v('colors.green')};
  }
  &.relative {
    position: relative;
  }

  .left {
    text-align: left;
  }
`;
