import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import * as Styled from './TeamItem.styled';

export interface TeamItemProps {
  logo?: any;
  name?: string;
  data?: any;
}

export const TeamItem = (props: TeamItemProps) => {
  const { logo, name, data } = props;

  return (
    <Styled.TeamItem>
      <div className={classNames('logo')} style={{ backgroundImage: `url(${data?.picture?.getFile?.getUrl || logo})` }} />
      <TextItem className={classNames('name')} path={data?.name || name} tag="p" />
    </Styled.TeamItem>
  );
};

export default TeamItem;
