import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { pushRoute, auth, useAction } from '@makeit-studio/store';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import PageTitle from 'components/items/PageTitle';
import ProjectRenewEmailForm from 'components/forms/ProjectRenewEmailForm';
import { Form } from 'react-final-form';

import account from 'store/account';
import { fieldComparison } from '@makeit-studio/ui-library';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { useChangeEmail } from 'store/account/hooks';
import * as Styled from './RenewEmail.styled';

export interface RenewEmailProps {}

export const RenewEmail = (props: RenewEmailProps & RouteComponentProps) => {
  const {
    history,
    location: { search },
  } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  const changeEmail = useChangeEmail();

  const submit = useCallback(
    async (values) => {
      console.log('values', values);
      await changeEmail({
        variables: {
          input: values,
        },
        success: 'Email successfully updated',
      });

      history.push(pushRoute('settings.account'));
    },
    [changeEmail, history, lg],
  );

  return (
    <Styled.RenewEmail>
      <PageTitle
        title="Change email"
        topLabel="Back"
        topAction={() => {
          history.push(pushRoute('settings.account', lg));
        }}
      />
      <Form initialValues={{}} component={ProjectRenewEmailForm} onSubmit={submit} />
    </Styled.RenewEmail>
  );
};

export default RenewEmail;
