import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Form } from 'react-final-form';
import { NavLink, RouteComponentProps, useHistory } from 'react-router-dom';

import { useAction, pushRoute } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';

import { IoChevronBack } from 'react-icons/io5';

import { StoreState } from 'store/appReducers';

import ConfirmPasswordResetForm from 'components/forms/ConfirmPasswordResetForm';
import { useConfirmResetPassword } from 'store/account/hooks';
import * as Styled from './ConfirmResetPassword.styled';

export interface ConfirmResetPasswordProps {}

export const ConfirmResetPassword = (props: ConfirmResetPasswordProps & RouteComponentProps) => {
  const history = useHistory();
  const lg = useSelector((state: StoreState) => state.content.lg);

  const confirmResetPassword = useConfirmResetPassword();

  const submit = useCallback(
    async (values) => {
      await confirmResetPassword({
        variables: values,
        success: 'Successfully set your new password',
      });
      history.push(pushRoute('login', lg));
    },
    [confirmResetPassword, history, lg],
  );

  return (
    <Styled.ConfirmResetPassword>
      <NavLink to={pushRoute('login')} className={classNames('back')}>
        <IoChevronBack />
      </NavLink>
      <div className={classNames('heading', 'align-center')}>
        <TextItem tag="h1" path="Reset password" />
        <TextItem
          tag="p"
          path="Enter the code received to your e-mail as well as the new password you wish to use. Your new password must be different from previously used passwords."
        />
      </div>
      <Form initialValues={{}} component={ConfirmPasswordResetForm} onSubmit={submit} />
    </Styled.ConfirmResetPassword>
  );
};

export default ConfirmResetPassword;
