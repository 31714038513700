import { StoreConfig } from '@makeit-studio/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRefreshToken } from 'store/auth/hooks';
import useBootUp from 'store/useBootUp';
import moment from 'moment';
import { SessionData } from 'store/auth/reducer';
import { StoreState } from 'store/appReducers';
import auth from 'store/auth';

export const useConfig = () => {
  const [isAppReady, setIsAppReady] = useState(false);
  const bootDone = useSelector((state: StoreState) => state.app.ready);

  const refreshToken = useRefreshToken();
  const boot = useBootUp();

  useEffect(() => {
    StoreConfig.setOptions({
      refreshTokenFunction: refreshToken,
    });

    setIsAppReady(true);
  }, [setIsAppReady, refreshToken]);

  useEffect(() => {
    if (!isAppReady) return;
    boot();
  }, [isAppReady, boot]);

  return { isAppReady, bootDone };
};
