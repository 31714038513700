import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import * as Styled from './CategoryItem.styled';

export interface CategoryItemProps {
  neutral?: boolean;
  category?: any;
  light?: boolean;
  canActive?: boolean;
  active?: any;
  action?: any;
}

export const CategoryItem = (props: CategoryItemProps) => {
  const { category, light, neutral, canActive, action, active } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  const handleClick = useCallback(() => {
    if (action) {
      action(category?._id);
    }
  }, [action, category]);

  return (
    <Styled.CategoryItem className={classNames(active && 'active')} onClick={() => (canActive ? handleClick() : null)}>
      <TextItem path={category} tag="span" className={classNames('tag', light && 'light', neutral && 'neutral')} />
    </Styled.CategoryItem>
  );
};

export default CategoryItem;
