import styled from '@emotion/styled';
import { getV, v } from '@makeit-studio/ui-library';

export const Card = styled.div`
  .image-wrapper {
    position: relative;
    margin-bottom: 14px;
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    .thumbnail {
      width: 100%;
      height: 120px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .top {
    span {
      font-size: ${v('font.size.extraSmall')};
    }
    .date {
      text-align: right;
      font-size: ${v('font.size.extraSmall')};
    }
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: ${v('font.weight.bold')};
  }
`;
