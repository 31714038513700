import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const BackButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 10px;
  color: ${v('colors.greyFont')};
  display: flex;
  align-items: center;
  font-size: ${v('font.size.default')};
  font-weight: ${v('font.weight.extraBold')};
  .icon {
    margin-right: 7px;
    font-size: 13px;
  }
`;
