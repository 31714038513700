import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import * as Styled from './PageTitle.styled';
import ProjectButton from '../ProjectButton';

export interface PageTitleProps {
  center?: boolean;
  title?: string;
  text?: string;
  textUppercase?: boolean;
  topAction?: () => any;
  topLabel?: string;
}

export const PageTitle = (props: PageTitleProps) => {
  const { center, title, text, textUppercase, topAction, topLabel } = props;

  return (
    <Styled.PageTitle className={classNames('back-button', center && 'center')}>
      {topAction && <ProjectButton color="ternary" noHover align="left" relative noMargin angleLeft label={topLabel} action={topAction} />}
      {title && <TextItem path={title} tag="h1" />}
      {text && <TextItem className={classNames(textUppercase && 'uppercase')} path={text} isHtml />}
    </Styled.PageTitle>
  );
};

export default PageTitle;
