import styled from '@emotion/styled';
import { v } from '@makeit-studio/ui-library';

export const EventDetail = styled.div`
  height: auto;
  min-height: 100%;
  padding-bottom: 0 !important; // handled by the sticky button
  .back-button {
    margin-bottom: 0;
  }
  .top-detail,
  .detail,
  .tabs-wrapper {
    z-index: 2;
    position: relative;
  }
  .top-detail {
    display: block;
    min-height: 230px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      position: relative;
      z-index: 3;
      .fav-icon {
        font-size: 24px;
        path {
          color: white;
        }
      }
    }

    .image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 230px;
      max-height: 40vh;
      border-radius: ${v('border.radius.default')};
      overflow: hidden;
      display: inline-grid;

      img {
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }
    }
  }
  .detail {
    background-color: white;
    margin: -50px 10px 20px 10px;
    border-radius: ${v('border.radius.default')};
    padding: 20px;
    text-align: center;
    .title {
      display: inline-flex;
      align-items: center;
      margin: 0 auto;
      font-weight: ${v('font.weight.extraBold')} !important;
      font-size: ${v('font.size.medium')} !important;
      text-transform: uppercase;
    }
    .text {
      margin-bottom: 2px;
      /* font-size: 14px; */
      p {
        margin: 0;
      }
      &.bold {
        font-weight: 700;
      }
    }
    .team-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: no-wrap;
      margin: 20px 0;
      span {
        font-weight: ${v('font.weight.bold')};
      }
    }
    .info {
      .info-bottom {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;

        .info-item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          span {
            font-weight: ${v('font.weight.bold')};
          }
          &:nth-of-type(2) {
            margin-right: 0;
          }
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const ModalWrapper = styled.div`
  padding: 20px;
`;

export const Buttons = styled.div`
  position: sticky;
  z-index: 3;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: linear-gradient(180deg, rgba(242, 243, 247, 0) 0%, rgba(242, 243, 247, 1) 30%);
  button {
    margin: 0;
  }
`;
