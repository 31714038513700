import { eventListModel } from 'store/event/models';

export const accountListModel = {
  _id: true,
  createdAt: true,
  updatedAt: true,
  email: true,
  firstName: true,
  lastName: true,
  country: true,
  gender: true,
  birthDate: true,
  phoneNumber: true,
  hasAcceptedTerms: true,
  pinnedEventsIds: true,
  pinnedEvents: eventListModel,
  settings: {
    eventTypesIds: true,
    eventMoments: true,
  },
};

export const accountModel = {
  ...accountListModel,
};
