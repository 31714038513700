import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import classNames from 'classnames';
import { pushRoute } from '@makeit-studio/store';

import ListEvent from 'components/listItems/ListEvent';
import PageTitle from 'components/items/PageTitle';
import Card from 'components/items/Card';
import Filter from 'components/items/Filter';
import CategoryItem from 'components/items/CategoryItem';
import SearchForm from 'components/forms/SearchForm';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useList as useEventList, useRecentList, useSuggestedList } from 'store/event/hooks';
import { deepOmit } from '@makeit-studio/helpers';
import { TextItem } from '@makeit-studio/ui-store';
import qs from 'qs';
import { cloneDeep } from 'lodash';
import ListEventLayout from 'components/layouts/ListEventLayout';
import { useSearchParams } from 'hooks/searchParams';
import * as Styled from './Home.styled';

// assets
import redspart from './assets/redspart.png';
import worldCup from './assets/world-cup.png';

export interface HomeProps {}

export const Home = (props: HomeProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const eventsMeta = useSelector((state: StoreState) => state.event.list);
  const events = eventsMeta?.data;
  const recent = useSelector((state: StoreState) => state.event.recent);
  const suggested = useSelector((state: StoreState) => state.event.suggested);
  const profile = useSelector((state: StoreState) => state.account?.profile?.data);

  // Allow to dispatch actions
  const loadEvents = useEventList();
  const loadRecent = useRecentList();
  const loadSuggested = useSuggestedList();

  const { location, eventSearch } = useSearchParams();

  const handleSearch = useCallback(() => {
    loadEvents({
      variables: { search: eventSearch },
    });
  }, [eventSearch, loadEvents]);

  useEffect(() => {
    handleSearch();
  }, [location, handleSearch]);

  useEffect(() => {
    loadRecent();
  }, [loadRecent]);

  useEffect(() => {
    if (profile) {
      loadSuggested({ variables: { search: { typeIds: profile?.settings?.eventTypesIds } } });
    }
  }, [profile, loadSuggested]);

  return (
    <Styled.Home>
      <ListEventLayout
        options={{
          title: 'Events',
          text: 'Welcome to the closest most intense experience',
          route: 'home',
          loading: eventsMeta?.loading,
          list: events?.items || [],
        }}
      >
        {profile && (!eventSearch || Object.keys(eventSearch)?.length === 0) && (
          <>
            <ListEvent
              title="Suggested events"
              labelAction="See all"
              horizontal
              action={() => {
                history.push(pushRoute('events.type', lg, { type: 'suggested' }));
              }}
            >
              {suggested?.data?.items?.map((item: any, key) => (
                <Card
                  data={item}
                  action={() => {
                    history.push(pushRoute('event.id', lg, { id: item._id }));
                  }}
                  key={key}
                />
              ))}
            </ListEvent>
            <ListEvent
              title="New events"
              labelAction="See all"
              horizontal
              action={() => {
                history.push(pushRoute('events.type', lg, { type: 'new' }));
              }}
            >
              {recent?.data?.items?.map((item: any, key) => (
                <Card
                  data={item}
                  action={() => {
                    history.push(pushRoute('event.id', lg, { id: item._id }));
                  }}
                  key={key}
                />
              ))}
            </ListEvent>
            <ListEvent
              title="Other events"
              labelAction="See all"
              horizontal
              action={() => {
                history.push(pushRoute('events.type', lg, { type: 'other' }));
              }}
            >
              {events?.items?.map((item: any, key) => (
                <Card
                  data={item}
                  action={() => {
                    history.push(pushRoute('event.id', lg, { id: item._id }));
                  }}
                  key={key}
                />
              ))}
            </ListEvent>
          </>
        )}
      </ListEventLayout>
    </Styled.Home>
  );
};

export default Home;
