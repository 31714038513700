import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';
import { pushRoute, useAction } from '@makeit-studio/store';

import classNames from 'classnames';
import { TextItem } from '@makeit-studio/ui-store';
import PreferencesForm from 'components/forms/PreferencesForm';
import { Form } from 'react-final-form';
import Logo from 'components/items/Logo';
import { RouteComponentProps } from 'react-router-dom';
import { useUpdateMe } from 'store/account/hooks';
import * as Styled from './Preferences.styled';

export interface PreferencesProps {}

export const Preferences = (props: PreferencesProps & RouteComponentProps) => {
  const { history } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);

  const updateMe = useUpdateMe();

  const submit = useCallback(
    async (values) => {
      const res = await updateMe({
        variables: {
          input: values,
        },
        success: 'Preferences successfully set',
      });
      if (res) {
        history.push(pushRoute('home', lg));
      }
    },
    [updateMe, history, lg],
  );

  return (
    <Styled.Preferences>
      <div className={classNames('heading', 'align-center')}>
        <TextItem tag="h1" path="Setup preferences" />
        <TextItem tag="p" path="Select one or more" />
      </div>
      <div className={classNames('form-container')}>
        <Form component={PreferencesForm} onSubmit={submit} />
      </div>
    </Styled.Preferences>
  );
};

export default Preferences;
