import React from 'react';

import classNames from 'classnames/bind';
import { TextItem } from '@makeit-studio/ui-store';
import { AiOutlineLeft } from 'react-icons/ai';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import * as Styled from './BackButton.styled';

export interface BackButtonProps {
  className?: string;
  action?: () => void;
}

const BackButton = (props: BackButtonProps & RouteComponentProps) => {
  const { history, className, action } = props;

  const goBack = () => {
    if (action) action();
    else history.goBack();
  };

  return (
    <Styled.BackButton className={classNames(className)} onClick={goBack}>
      <AiOutlineLeft className={classNames('icon')} />
      <TextItem path="Back" />
    </Styled.BackButton>
  );
};

export default withRouter(BackButton);
