import React, { useCallback, useState } from 'react';
import { Field } from 'react-final-form';
import { IoSearchOutline } from 'react-icons/io5';

// Redux part
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/appReducers';
// Import actions here

import classNames from 'classnames';
import ProjectInput from 'components/formItems/ProjectInput';

import * as Styled from './SearchForm.styled';

export interface StateProps {}

export interface DispatchProps {}

export interface OwnProps {
  handleSubmit: () => void;
  submitting?: boolean;
  valid?: boolean;
  form?: any;
}

export type SearchFormProps = StateProps & DispatchProps & OwnProps;

const SearchForm = (props: SearchFormProps) => {
  const { handleSubmit, submitting, valid, form } = props;

  // mapStateToProps
  const content = useSelector((state: StoreState) => state.content.raw);

  const [timeoutId, setTimeOutID] = useState(null);

  const handleChange = useCallback(() => {
    if (timeoutId) clearInterval(timeoutId);
    const timeOut = setTimeout(() => handleSubmit(), 500);
    setTimeOutID(timeOut);
  }, [handleSubmit, timeoutId]);

  return (
    <Styled.SearchForm onSubmit={handleSubmit}>
      <div className={classNames('form-wrapper')}>
        <button className={classNames('button')} type="submit" disabled={submitting || !valid}>
          <IoSearchOutline />
        </button>
        <Field
          clear
          className={classNames('search-input')}
          fieldWrapperClassName={classNames('field-wrapper')}
          component={ProjectInput}
          onChange={handleChange}
          name="searchText"
          placeholder="Search"
        />
      </div>
    </Styled.SearchForm>
  );
};

export default SearchForm;
