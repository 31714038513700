/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = GraphQLTypes["BaseDto"]
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["EventPriceComponentDto"]: AliasType<{
	amount?:boolean,
	currency?:boolean,
		__typename?: boolean
}>;
	["Location"]: AliasType<{
	type?:boolean,
	coordinates?:boolean,
		__typename?: boolean
}>;
	["AddressComponentDto"]: AliasType<{
	number?:boolean,
	street?:boolean,
	streetBis?:boolean,
	floor?:boolean,
	box?:boolean,
	zip?:boolean,
	state?:boolean,
	city?:boolean,
	country?:boolean,
	location?:ValueTypes["Location"],
		__typename?: boolean
}>;
	["ImageComponentDto"]: AliasType<{
	_id?:boolean,
	title?:boolean,
	fileType?:boolean,
	large?:boolean,
	medium?:boolean,
	small?:boolean,
	getFile?:ValueTypes["FileDto"],
		__typename?: boolean
}>;
	["AvatarStreamComponentDto"]: AliasType<{
	isLive?:boolean,
	hls?:boolean,
		__typename?: boolean
}>;
	["AvatarParticipantComponentDto"]: AliasType<{
	id?:boolean,
	position?:boolean,
	eventId?:boolean,
		__typename?: boolean
}>;
	/** Mongo object id scalar type */
["ObjectId"]:unknown;
	["AvatarDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	name?:boolean,
	description?:boolean,
	picture?:ValueTypes["ImageComponentDto"],
	stream?:ValueTypes["AvatarStreamComponentDto"],
	participants?:ValueTypes["AvatarParticipantComponentDto"],
	externalLink?:boolean,
		__typename?: boolean
}>;
	["BaseDto"]:AliasType<{
		_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean;
		['...on AvatarDto']?: Omit<ValueTypes["AvatarDto"],keyof ValueTypes["BaseDto"]>;
		['...on LiveEventDto']?: Omit<ValueTypes["LiveEventDto"],keyof ValueTypes["BaseDto"]>;
		['...on EventCategoryDto']?: Omit<ValueTypes["EventCategoryDto"],keyof ValueTypes["BaseDto"]>;
		['...on EventTypeDto']?: Omit<ValueTypes["EventTypeDto"],keyof ValueTypes["BaseDto"]>;
		['...on EventParticipantDto']?: Omit<ValueTypes["EventParticipantDto"],keyof ValueTypes["BaseDto"]>;
		['...on EventDto']?: Omit<ValueTypes["EventDto"],keyof ValueTypes["BaseDto"]>;
		['...on AccountDto']?: Omit<ValueTypes["AccountDto"],keyof ValueTypes["BaseDto"]>;
		__typename?: boolean
}>;
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:unknown;
	["LiveEventDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	name?:boolean,
	description?:boolean,
	eventId?:boolean,
	event?:ValueTypes["EventDto"],
	avatarIds?:boolean,
	avatars?:ValueTypes["AvatarDto"],
	avatarsCount?:boolean,
		__typename?: boolean
}>;
	["EventCategoryDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	name?:boolean,
	logo?:ValueTypes["ImageComponentDto"],
		__typename?: boolean
}>;
	["EventTypeDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	name?:boolean,
	logo?:ValueTypes["ImageComponentDto"],
		__typename?: boolean
}>;
	["EventParticipantDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	name?:boolean,
	avatars?:ValueTypes["AvatarDto"],
	avatarsCount?:boolean,
	picture?:ValueTypes["ImageComponentDto"],
		__typename?: boolean
}>;
	["EventDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	background?:ValueTypes["ImageComponentDto"],
	name?:boolean,
	description?:boolean,
	location?:ValueTypes["AddressComponentDto"],
	categoryId?:boolean,
	category?:ValueTypes["EventCategoryDto"],
	typeId?:boolean,
	type?:ValueTypes["EventTypeDto"],
	startAt?:boolean,
	endAt?:boolean,
	liveEventId?:boolean,
	liveEvent?:ValueTypes["LiveEventDto"],
	price?:ValueTypes["EventPriceComponentDto"],
	participantIds?:boolean,
	participants?:ValueTypes["EventParticipantDto"],
	avatarsCount?:boolean,
		__typename?: boolean
}>;
	["PaginatedEventsDto"]: AliasType<{
	page?:boolean,
	limit?:boolean,
	totalCount?:boolean,
	count?:boolean,
	items?:ValueTypes["EventDto"],
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
		__typename?: boolean
}>;
	["PaginatedEventTypesDto"]: AliasType<{
	page?:boolean,
	limit?:boolean,
	totalCount?:boolean,
	count?:boolean,
	items?:ValueTypes["EventTypeDto"],
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
		__typename?: boolean
}>;
	["PaginatedEventCategoriesDto"]: AliasType<{
	page?:boolean,
	limit?:boolean,
	totalCount?:boolean,
	count?:boolean,
	items?:ValueTypes["EventCategoryDto"],
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
		__typename?: boolean
}>;
	["PaginatedEventParticipantsDto"]: AliasType<{
	page?:boolean,
	limit?:boolean,
	totalCount?:boolean,
	count?:boolean,
	items?:ValueTypes["EventParticipantDto"],
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
		__typename?: boolean
}>;
	["FileDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	mime?:boolean,
	title?:boolean,
	accountId?:boolean,
getUrl?: [{	size?:number | null},boolean],
		__typename?: boolean
}>;
	["UploadInitDto"]: AliasType<{
	url?:boolean,
	file?:ValueTypes["FileDto"],
		__typename?: boolean
}>;
	["AccountSettingsComponentDto"]: AliasType<{
	eventTypes?:ValueTypes["EventTypeDto"],
	eventTypesIds?:boolean,
	eventMoments?:boolean,
		__typename?: boolean
}>;
	/** - */
["EventMoments"]:EventMoments;
	["AccountDto"]: AliasType<{
	_id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	email?:boolean,
	firstName?:boolean,
	lastName?:boolean,
	country?:boolean,
	gender?:boolean,
	birthDate?:boolean,
	phoneNumber?:boolean,
	hasAcceptedTerms?:boolean,
	roles?:boolean,
	settings?:ValueTypes["AccountSettingsComponentDto"],
	pinnedEventsIds?:boolean,
pinnedEvents?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["EventSearchDto"] | null,	sortBy?:ValueTypes["EventSortByDto"] | null},ValueTypes["EventDto"]],
		__typename?: boolean
}>;
	/** - */
["Gender"]:Gender;
	["Roles"]:Roles;
	["EventSearchDto"]: {
	ids?:ValueTypes["ObjectId"][],
	name?:string | null,
	typeIds?:ValueTypes["ObjectId"][],
	categoryIds?:ValueTypes["ObjectId"][],
	liveEventId?:ValueTypes["ObjectId"] | null,
	searchText?:string | null,
	price?:ValueTypes["NumberRangeArgs"] | null,
	date?:ValueTypes["DateRangeArgs"] | null,
	participantsIds?:ValueTypes["ObjectId"][],
	avatars?:ValueTypes["NumberRangeArgs"] | null
};
	["NumberRangeArgs"]: {
	min?:number | null,
	max?:number | null
};
	["DateRangeArgs"]: {
	to?:ValueTypes["DateTime"] | null,
	from?:ValueTypes["DateTime"] | null
};
	["EventSortByDto"]: {
	name?:ValueTypes["SortByDirection"] | null,
	startAt?:ValueTypes["SortByDirection"] | null,
	createdAt?:ValueTypes["SortByDirection"] | null
};
	/** Sort direction for Queries */
["SortByDirection"]:SortByDirection;
	["UserSessionDto"]: AliasType<{
	localId?:boolean,
	email?:boolean,
	displayName?:boolean,
	idToken?:boolean,
	registered?:boolean,
	refreshToken?:boolean,
	expiresIn?:boolean,
		__typename?: boolean
}>;
	["PaginatedAccountsDto"]: AliasType<{
	page?:boolean,
	limit?:boolean,
	totalCount?:boolean,
	count?:boolean,
	items?:ValueTypes["AccountDto"],
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
		__typename?: boolean
}>;
	["PaginatedAvatarsDto"]: AliasType<{
	page?:boolean,
	limit?:boolean,
	totalCount?:boolean,
	count?:boolean,
	items?:ValueTypes["AvatarDto"],
	hasNextPage?:boolean,
	hasPreviousPage?:boolean,
		__typename?: boolean
}>;
	["EventPriceComponentInputDto"]: {
	amount:number,
	currency:string
};
	["LocationComponentInputDto"]: {
	type?:string | null,
	coordinates?:number[]
};
	["AddressComponentInputDto"]: {
	number?:string | null,
	street?:string | null,
	streetBis?:string | null,
	floor?:string | null,
	box?:string | null,
	zip?:string | null,
	state?:string | null,
	city?:string | null,
	country?:string | null,
	location?:ValueTypes["LocationComponentInputDto"] | null
};
	["ImageComponentInputDto"]: {
	_id?:string | null,
	title?:string | null,
	fileType?:string | null,
	large?:string | null,
	medium?:string | null,
	small?:string | null
};
	["AvatarStreamComponentInputDto"]: {
	isLive:boolean,
	hls?:string | null
};
	["AvatarParticipantComponentInputDto"]: {
	id?:ValueTypes["ObjectId"] | null,
	position?:string | null,
	eventId?:ValueTypes["ObjectId"] | null
};
	["AvatarInputDto"]: {
	name:string,
	description?:string | null,
	picture?:ValueTypes["ImageComponentInputDto"] | null,
	stream?:ValueTypes["AvatarStreamComponentInputDto"] | null,
	participants?:ValueTypes["AvatarParticipantComponentInputDto"][],
	externalLink?:string | null
};
	["LiveEventInputDto"]: {
	name:string,
	description?:string | null,
	eventId:ValueTypes["ObjectId"],
	event?:ValueTypes["EventInputDto"] | null,
	avatarIds:ValueTypes["ObjectId"][],
	avatars?:ValueTypes["AvatarInputDto"][],
	avatarsCount?:number | null
};
	["EventCategoryInputDto"]: {
	name:string,
	logo?:ValueTypes["ImageComponentInputDto"] | null
};
	["EventTypeInputDto"]: {
	name:string,
	logo?:ValueTypes["ImageComponentInputDto"] | null
};
	["EventParticipantInputDto"]: {
	name:string,
	avatars?:ValueTypes["AvatarInputDto"][],
	avatarsCount?:number | null,
	picture?:ValueTypes["ImageComponentInputDto"] | null
};
	["EventInputDto"]: {
	background?:ValueTypes["ImageComponentInputDto"] | null,
	name:string,
	description?:string | null,
	location:ValueTypes["AddressComponentInputDto"],
	categoryId:ValueTypes["ObjectId"],
	category:ValueTypes["EventCategoryInputDto"],
	typeId:ValueTypes["ObjectId"],
	type:ValueTypes["EventTypeInputDto"],
	startAt:ValueTypes["DateTime"],
	endAt?:ValueTypes["DateTime"] | null,
	liveEventId?:ValueTypes["ObjectId"] | null,
	liveEvent?:ValueTypes["LiveEventInputDto"] | null,
	price?:ValueTypes["EventPriceComponentInputDto"] | null,
	participantIds?:ValueTypes["ObjectId"][],
	participants?:ValueTypes["EventParticipantInputDto"][],
	avatarsCount?:number | null
};
	["AccountSettingsComponentInputDto"]: {
	eventTypes?:ValueTypes["EventTypeInputDto"][],
	eventTypesIds?:ValueTypes["ObjectId"][],
	eventMoments?:ValueTypes["EventMoments"][]
};
	["AccountInputDto"]: {
	email:string,
	firstName?:string | null,
	lastName?:string | null,
	country?:string | null,
	gender?:ValueTypes["Gender"] | null,
	birthDate?:ValueTypes["DateTime"] | null,
	phoneNumber?:string | null,
	hasAcceptedTerms:boolean,
	roles?:ValueTypes["Roles"][],
	settings?:ValueTypes["AccountSettingsComponentInputDto"] | null,
	pinnedEventsIds?:ValueTypes["ObjectId"][],
	pinnedEvents?:ValueTypes["EventInputDto"][]
};
	["Query"]: AliasType<{
login?: [{	email:string,	password:string},ValueTypes["UserSessionDto"]],
	me?:ValueTypes["AccountDto"],
refreshToken?: [{	refreshToken:string},ValueTypes["UserSessionDto"]],
accounts?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["AccountSearchDto"] | null,	sortBy?:ValueTypes["AccountSortByDto"] | null},ValueTypes["PaginatedAccountsDto"]],
account?: [{	id:ValueTypes["ObjectId"]},ValueTypes["AccountDto"]],
myPinnedEvents?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["EventSearchDto"] | null,	sortBy?:ValueTypes["EventSortByDto"] | null},ValueTypes["PaginatedEventsDto"]],
events?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["EventSearchDto"] | null,	sortBy?:ValueTypes["EventSortByDto"] | null},ValueTypes["PaginatedEventsDto"]],
event?: [{	id:ValueTypes["ObjectId"]},ValueTypes["EventDto"]],
participants?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["EventParticipantsSearchDto"] | null,	sortBy?:ValueTypes["EventParticipantSortByDto"] | null},ValueTypes["PaginatedEventParticipantsDto"]],
participant?: [{	id:ValueTypes["ObjectId"]},ValueTypes["EventParticipantDto"]],
categories?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["EventCategorySearchDto"] | null,	sortBy?:ValueTypes["EventCategorySortByDto"] | null},ValueTypes["PaginatedEventCategoriesDto"]],
category?: [{	id:ValueTypes["ObjectId"]},ValueTypes["EventCategoryDto"]],
types?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["EventTypeSearchDto"] | null,	sortBy?:ValueTypes["EventTypeSortByDto"] | null},ValueTypes["PaginatedEventTypesDto"]],
type?: [{	id:ValueTypes["ObjectId"]},ValueTypes["EventTypeDto"]],
	liveEvents?:ValueTypes["LiveEventDto"],
liveEvent?: [{	id:ValueTypes["ObjectId"]},ValueTypes["LiveEventDto"]],
filesGetOne?: [{	id:string},ValueTypes["FileDto"]],
avatars?: [{	page?:number | null,	limit?:number | null,	searchBy?:ValueTypes["AvatarSearchDto"] | null,	sortBy?:ValueTypes["AvatarSortByDto"] | null},ValueTypes["PaginatedAvatarsDto"]],
avatar?: [{	id:ValueTypes["ObjectId"]},ValueTypes["AvatarDto"]],
		__typename?: boolean
}>;
	["AccountSearchDto"]: {
	email?:string | null,
	searchText?:string | null
};
	["AccountSortByDto"]: {
	email?:ValueTypes["SortByDirection"] | null,
	createdAt?:ValueTypes["SortByDirection"] | null
};
	["EventParticipantsSearchDto"]: {
	name?:string | null,
	avatarsCount?:ValueTypes["NumberRangeArgs"] | null,
	searchText?:string | null
};
	["EventParticipantSortByDto"]: {
	name?:ValueTypes["SortByDirection"] | null,
	createdAt?:ValueTypes["SortByDirection"] | null
};
	["EventCategorySearchDto"]: {
	name?:string | null,
	searchText?:string | null
};
	["EventCategorySortByDto"]: {
	name?:ValueTypes["SortByDirection"] | null,
	createdAt?:ValueTypes["SortByDirection"] | null
};
	["EventTypeSearchDto"]: {
	name?:string | null,
	searchText?:string | null
};
	["EventTypeSortByDto"]: {
	name?:ValueTypes["SortByDirection"] | null,
	createdAt?:ValueTypes["SortByDirection"] | null
};
	["AvatarSearchDto"]: {
	name?:string | null
};
	["AvatarSortByDto"]: {
	name?:ValueTypes["SortByDirection"] | null,
	startDate?:ValueTypes["SortByDirection"] | null
};
	["Mutation"]: AliasType<{
register?: [{	input:ValueTypes["NewAccountInputDto"]},ValueTypes["AccountDto"]],
accountsEditSelf?: [{	input:ValueTypes["EditAccountInputDto"]},ValueTypes["AccountDto"]],
accountsEditOne?: [{	id:ValueTypes["ObjectId"],	input:ValueTypes["EditAccountInputDto"]},ValueTypes["AccountDto"]],
confirmRegistration?: [{	id:ValueTypes["ObjectId"],	code:string,	email:string},boolean],
checkRegistrationStatus?: [{	id:ValueTypes["ObjectId"]},boolean],
resendConfirmationCode?: [{	id:ValueTypes["ObjectId"]},boolean],
deleteUser?: [{	id:ValueTypes["ObjectId"]},boolean],
updatePassword?: [{	input:ValueTypes["EditAccountPasswordInputDto"]},ValueTypes["AccountDto"]],
updateEmail?: [{	input:ValueTypes["EditAccountEmailInputDto"]},ValueTypes["AccountDto"]],
addPinnedEvent?: [{	id:ValueTypes["ObjectId"]},ValueTypes["AccountDto"]],
removePinnedEvent?: [{	id:ValueTypes["ObjectId"]},ValueTypes["AccountDto"]],
resetPassword?: [{	email:string},boolean],
confirmResetPassword?: [{	code:string,	email:string,	newPassword:string,	confirmPassword:string},boolean],
createEvent?: [{	input:ValueTypes["NewEventInputDto"]},ValueTypes["EventDto"]],
editEvent?: [{	id:ValueTypes["ObjectId"],	input:ValueTypes["EditEventInputDto"]},ValueTypes["EventDto"]],
deleteEvent?: [{	id:ValueTypes["ObjectId"]},boolean],
createEventParticipant?: [{	input:ValueTypes["NewEventParticipantInputDto"]},ValueTypes["EventParticipantDto"]],
deleteEventParticipant?: [{	id:ValueTypes["ObjectId"]},boolean],
editEventParticipant?: [{	id:ValueTypes["ObjectId"],	input:ValueTypes["EditEventParticipantInputDto"]},ValueTypes["EventParticipantDto"]],
createEventCategory?: [{	input:ValueTypes["NewEventCategoryInputDto"]},ValueTypes["EventCategoryDto"]],
deleteEventCategory?: [{	id:ValueTypes["ObjectId"]},boolean],
editEventCategory?: [{	id:ValueTypes["ObjectId"],	input:ValueTypes["NewEventCategoryInputDto"]},ValueTypes["EventCategoryDto"]],
createEventType?: [{	input:ValueTypes["NewEventTypeInputDto"]},ValueTypes["EventTypeDto"]],
deleteEventType?: [{	id:ValueTypes["ObjectId"]},boolean],
editEventType?: [{	id:ValueTypes["ObjectId"],	input:ValueTypes["NewEventTypeInputDto"]},ValueTypes["EventTypeDto"]],
createLiveEvent?: [{	input:ValueTypes["NewLiveEventInputDto"]},ValueTypes["LiveEventDto"]],
editLiveEvent?: [{	id:ValueTypes["ObjectId"],	input:ValueTypes["NewLiveEventInputDto"]},ValueTypes["LiveEventDto"]],
deleteLiveEvent?: [{	id:ValueTypes["ObjectId"]},boolean],
filesAddOne?: [{	input:ValueTypes["UploadInitInput"]},ValueTypes["UploadInitDto"]],
createAvatar?: [{	input:ValueTypes["NewAvatarInputDto"]},ValueTypes["AvatarDto"]],
editAvatar?: [{	id:ValueTypes["ObjectId"],	input:ValueTypes["EditAvatarInputDto"]},ValueTypes["AvatarDto"]],
deleteAvatar?: [{	id:ValueTypes["ObjectId"]},boolean],
		__typename?: boolean
}>;
	["NewAccountInputDto"]: {
	email:string,
	firstName?:string | null,
	lastName?:string | null,
	country?:string | null,
	gender?:ValueTypes["Gender"] | null,
	birthDate?:ValueTypes["DateTime"] | null,
	phoneNumber?:string | null,
	hasAcceptedTerms:boolean,
	roles?:ValueTypes["Roles"][],
	settings?:ValueTypes["AccountSettingsComponentInputDto"] | null,
	pinnedEventsIds?:ValueTypes["ObjectId"][],
	pinnedEvents?:ValueTypes["EventInputDto"][],
	password:string,
	passwordConfirmation:string
};
	["EditAccountInputDto"]: {
	firstName?:string | null,
	lastName?:string | null,
	country?:string | null,
	gender?:ValueTypes["Gender"] | null,
	birthDate?:ValueTypes["DateTime"] | null,
	phoneNumber?:string | null,
	roles?:ValueTypes["Roles"][],
	settings?:ValueTypes["AccountSettingsComponentInputDto"] | null,
	pinnedEventsIds?:ValueTypes["ObjectId"][],
	pinnedEvents?:ValueTypes["EventInputDto"][]
};
	["EditAccountPasswordInputDto"]: {
	firstName?:string | null,
	lastName?:string | null,
	country?:string | null,
	gender?:ValueTypes["Gender"] | null,
	birthDate?:ValueTypes["DateTime"] | null,
	phoneNumber?:string | null,
	roles?:ValueTypes["Roles"][],
	settings?:ValueTypes["AccountSettingsComponentInputDto"] | null,
	pinnedEventsIds?:ValueTypes["ObjectId"][],
	pinnedEvents?:ValueTypes["EventInputDto"][],
	password:string,
	newPassword:string,
	newPasswordConfirmation:string
};
	["EditAccountEmailInputDto"]: {
	firstName?:string | null,
	lastName?:string | null,
	country?:string | null,
	gender?:ValueTypes["Gender"] | null,
	birthDate?:ValueTypes["DateTime"] | null,
	phoneNumber?:string | null,
	roles?:ValueTypes["Roles"][],
	settings?:ValueTypes["AccountSettingsComponentInputDto"] | null,
	pinnedEventsIds?:ValueTypes["ObjectId"][],
	pinnedEvents?:ValueTypes["EventInputDto"][],
	password:string,
	newEmail:string
};
	["NewEventInputDto"]: {
	background?:ValueTypes["ImageComponentInputDto"] | null,
	name:string,
	description?:string | null,
	location:ValueTypes["AddressComponentInputDto"],
	categoryId:ValueTypes["ObjectId"],
	typeId:ValueTypes["ObjectId"],
	startAt:ValueTypes["DateTime"],
	endAt?:ValueTypes["DateTime"] | null,
	liveEventId?:ValueTypes["ObjectId"] | null,
	liveEvent?:ValueTypes["LiveEventInputDto"] | null,
	price?:ValueTypes["EventPriceComponentInputDto"] | null,
	participantIds?:ValueTypes["ObjectId"][],
	participants?:ValueTypes["EventParticipantInputDto"][],
	avatarsCount?:number | null
};
	["EditEventInputDto"]: {
	background?:ValueTypes["ImageComponentInputDto"] | null,
	name?:string | null,
	description?:string | null,
	location?:ValueTypes["AddressComponentInputDto"] | null,
	categoryId?:ValueTypes["ObjectId"] | null,
	typeId?:ValueTypes["ObjectId"] | null,
	startAt?:ValueTypes["DateTime"] | null,
	endAt?:ValueTypes["DateTime"] | null,
	liveEventId?:ValueTypes["ObjectId"] | null,
	liveEvent?:ValueTypes["LiveEventInputDto"] | null,
	price?:ValueTypes["EventPriceComponentInputDto"] | null,
	participantIds?:ValueTypes["ObjectId"][],
	participants?:ValueTypes["EventParticipantInputDto"][],
	avatarsCount?:number | null
};
	["NewEventParticipantInputDto"]: {
	name:string,
	avatars?:ValueTypes["AvatarInputDto"][],
	avatarsCount?:number | null,
	picture?:ValueTypes["ImageComponentInputDto"] | null
};
	["EditEventParticipantInputDto"]: {
	name?:string | null,
	avatars?:ValueTypes["AvatarInputDto"][],
	avatarsCount?:number | null,
	picture?:ValueTypes["ImageComponentInputDto"] | null
};
	["NewEventCategoryInputDto"]: {
	name:string,
	logo?:ValueTypes["ImageComponentInputDto"] | null
};
	["NewEventTypeInputDto"]: {
	name:string,
	logo?:ValueTypes["ImageComponentInputDto"] | null
};
	["NewLiveEventInputDto"]: {
	name:string,
	description?:string | null,
	eventId:ValueTypes["ObjectId"],
	event?:ValueTypes["EventInputDto"] | null,
	avatarIds:ValueTypes["ObjectId"][],
	avatars?:ValueTypes["AvatarInputDto"][],
	avatarsCount?:number | null
};
	["UploadInitInput"]: {
	fileName:string,
	publicFile?:boolean | null,
	tagsIds?:string[]
};
	["NewAvatarInputDto"]: {
	name:string,
	description?:string | null,
	picture?:ValueTypes["ImageComponentInputDto"] | null,
	stream?:ValueTypes["AvatarStreamComponentInputDto"] | null,
	participants?:ValueTypes["AvatarParticipantComponentInputDto"][],
	externalLink?:string | null
};
	["EditAvatarInputDto"]: {
	name?:string | null,
	description?:string | null,
	picture?:ValueTypes["ImageComponentInputDto"] | null,
	stream?:ValueTypes["AvatarStreamComponentInputDto"] | null,
	participants?:ValueTypes["AvatarParticipantComponentInputDto"][],
	externalLink?:string | null
}
  }

export type ModelTypes = {
    ["EventPriceComponentDto"]: {
		amount:number,
	currency:string
};
	["Location"]: {
		type?:string,
	coordinates?:number[]
};
	["AddressComponentDto"]: {
		number?:string,
	street?:string,
	streetBis?:string,
	floor?:string,
	box?:string,
	zip?:string,
	state?:string,
	city?:string,
	country?:string,
	location?:ModelTypes["Location"]
};
	["ImageComponentDto"]: {
		_id?:string,
	title?:string,
	fileType?:string,
	large?:string,
	medium?:string,
	small?:string,
	getFile?:ModelTypes["FileDto"]
};
	["AvatarStreamComponentDto"]: {
		isLive:boolean,
	hls?:string
};
	["AvatarParticipantComponentDto"]: {
		id?:ModelTypes["ObjectId"],
	position?:string,
	eventId?:ModelTypes["ObjectId"]
};
	/** Mongo object id scalar type */
["ObjectId"]:any;
	["AvatarDto"]: {
		_id:ModelTypes["ObjectId"],
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	name:string,
	description?:string,
	picture?:ModelTypes["ImageComponentDto"],
	stream?:ModelTypes["AvatarStreamComponentDto"],
	participants?:ModelTypes["AvatarParticipantComponentDto"][],
	externalLink?:string
};
	["BaseDto"]: ModelTypes["AvatarDto"] | ModelTypes["LiveEventDto"] | ModelTypes["EventCategoryDto"] | ModelTypes["EventTypeDto"] | ModelTypes["EventParticipantDto"] | ModelTypes["EventDto"] | ModelTypes["AccountDto"];
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:any;
	["LiveEventDto"]: {
		_id:ModelTypes["ObjectId"],
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	name:string,
	description?:string,
	eventId:ModelTypes["ObjectId"],
	event?:ModelTypes["EventDto"],
	avatarIds:ModelTypes["ObjectId"][],
	avatars?:ModelTypes["AvatarDto"][],
	avatarsCount?:number
};
	["EventCategoryDto"]: {
		_id:ModelTypes["ObjectId"],
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	name:string,
	logo?:ModelTypes["ImageComponentDto"]
};
	["EventTypeDto"]: {
		_id:ModelTypes["ObjectId"],
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	name:string,
	logo?:ModelTypes["ImageComponentDto"]
};
	["EventParticipantDto"]: {
		_id:ModelTypes["ObjectId"],
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	name:string,
	avatars?:ModelTypes["AvatarDto"][],
	avatarsCount?:number,
	picture?:ModelTypes["ImageComponentDto"]
};
	["EventDto"]: {
		_id:ModelTypes["ObjectId"],
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	background?:ModelTypes["ImageComponentDto"],
	name:string,
	description?:string,
	location:ModelTypes["AddressComponentDto"],
	categoryId:ModelTypes["ObjectId"],
	category:ModelTypes["EventCategoryDto"],
	typeId:ModelTypes["ObjectId"],
	type:ModelTypes["EventTypeDto"],
	startAt:ModelTypes["DateTime"],
	endAt?:ModelTypes["DateTime"],
	liveEventId?:ModelTypes["ObjectId"],
	liveEvent?:ModelTypes["LiveEventDto"],
	price?:ModelTypes["EventPriceComponentDto"],
	participantIds?:ModelTypes["ObjectId"][],
	participants?:ModelTypes["EventParticipantDto"][],
	avatarsCount?:number
};
	["PaginatedEventsDto"]: {
		page:number,
	limit:number,
	totalCount:number,
	count:number,
	items:ModelTypes["EventDto"][],
	hasNextPage:boolean,
	hasPreviousPage:boolean
};
	["PaginatedEventTypesDto"]: {
		page:number,
	limit:number,
	totalCount:number,
	count:number,
	items:ModelTypes["EventTypeDto"][],
	hasNextPage:boolean,
	hasPreviousPage:boolean
};
	["PaginatedEventCategoriesDto"]: {
		page:number,
	limit:number,
	totalCount:number,
	count:number,
	items:ModelTypes["EventCategoryDto"][],
	hasNextPage:boolean,
	hasPreviousPage:boolean
};
	["PaginatedEventParticipantsDto"]: {
		page:number,
	limit:number,
	totalCount:number,
	count:number,
	items:ModelTypes["EventParticipantDto"][],
	hasNextPage:boolean,
	hasPreviousPage:boolean
};
	["FileDto"]: {
		_id:string,
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	mime:string,
	title:string,
	accountId?:string,
	getUrl?:string
};
	["UploadInitDto"]: {
		url:string,
	file:ModelTypes["FileDto"]
};
	["AccountSettingsComponentDto"]: {
		eventTypes?:ModelTypes["EventTypeDto"][],
	eventTypesIds?:ModelTypes["ObjectId"][],
	eventMoments?:ModelTypes["EventMoments"][]
};
	/** - */
["EventMoments"]: GraphQLTypes["EventMoments"];
	["AccountDto"]: {
		_id:ModelTypes["ObjectId"],
	createdAt:ModelTypes["DateTime"],
	updatedAt:ModelTypes["DateTime"],
	email:string,
	firstName?:string,
	lastName?:string,
	country?:string,
	gender?:ModelTypes["Gender"],
	birthDate?:ModelTypes["DateTime"],
	phoneNumber?:string,
	hasAcceptedTerms:boolean,
	roles?:ModelTypes["Roles"][],
	settings?:ModelTypes["AccountSettingsComponentDto"],
	pinnedEventsIds?:ModelTypes["ObjectId"][],
	pinnedEvents?:ModelTypes["EventDto"][]
};
	/** - */
["Gender"]: GraphQLTypes["Gender"];
	["Roles"]: GraphQLTypes["Roles"];
	["EventSearchDto"]: GraphQLTypes["EventSearchDto"];
	["NumberRangeArgs"]: GraphQLTypes["NumberRangeArgs"];
	["DateRangeArgs"]: GraphQLTypes["DateRangeArgs"];
	["EventSortByDto"]: GraphQLTypes["EventSortByDto"];
	/** Sort direction for Queries */
["SortByDirection"]: GraphQLTypes["SortByDirection"];
	["UserSessionDto"]: {
		localId:string,
	email?:string,
	displayName?:string,
	idToken:string,
	registered?:boolean,
	refreshToken:string,
	expiresIn:string
};
	["PaginatedAccountsDto"]: {
		page:number,
	limit:number,
	totalCount:number,
	count:number,
	items:ModelTypes["AccountDto"][],
	hasNextPage:boolean,
	hasPreviousPage:boolean
};
	["PaginatedAvatarsDto"]: {
		page:number,
	limit:number,
	totalCount:number,
	count:number,
	items:ModelTypes["AvatarDto"][],
	hasNextPage:boolean,
	hasPreviousPage:boolean
};
	["EventPriceComponentInputDto"]: GraphQLTypes["EventPriceComponentInputDto"];
	["LocationComponentInputDto"]: GraphQLTypes["LocationComponentInputDto"];
	["AddressComponentInputDto"]: GraphQLTypes["AddressComponentInputDto"];
	["ImageComponentInputDto"]: GraphQLTypes["ImageComponentInputDto"];
	["AvatarStreamComponentInputDto"]: GraphQLTypes["AvatarStreamComponentInputDto"];
	["AvatarParticipantComponentInputDto"]: GraphQLTypes["AvatarParticipantComponentInputDto"];
	["AvatarInputDto"]: GraphQLTypes["AvatarInputDto"];
	["LiveEventInputDto"]: GraphQLTypes["LiveEventInputDto"];
	["EventCategoryInputDto"]: GraphQLTypes["EventCategoryInputDto"];
	["EventTypeInputDto"]: GraphQLTypes["EventTypeInputDto"];
	["EventParticipantInputDto"]: GraphQLTypes["EventParticipantInputDto"];
	["EventInputDto"]: GraphQLTypes["EventInputDto"];
	["AccountSettingsComponentInputDto"]: GraphQLTypes["AccountSettingsComponentInputDto"];
	["AccountInputDto"]: GraphQLTypes["AccountInputDto"];
	["Query"]: {
		login:ModelTypes["UserSessionDto"],
	me:ModelTypes["AccountDto"],
	refreshToken:ModelTypes["UserSessionDto"],
	accounts:ModelTypes["PaginatedAccountsDto"],
	account:ModelTypes["AccountDto"],
	myPinnedEvents:ModelTypes["PaginatedEventsDto"],
	events:ModelTypes["PaginatedEventsDto"],
	event?:ModelTypes["EventDto"],
	participants:ModelTypes["PaginatedEventParticipantsDto"],
	participant?:ModelTypes["EventParticipantDto"],
	categories:ModelTypes["PaginatedEventCategoriesDto"],
	category?:ModelTypes["EventCategoryDto"],
	types:ModelTypes["PaginatedEventTypesDto"],
	type?:ModelTypes["EventTypeDto"],
	liveEvents:ModelTypes["LiveEventDto"][],
	liveEvent?:ModelTypes["LiveEventDto"],
	filesGetOne?:ModelTypes["FileDto"],
	avatars:ModelTypes["PaginatedAvatarsDto"],
	avatar?:ModelTypes["AvatarDto"]
};
	["AccountSearchDto"]: GraphQLTypes["AccountSearchDto"];
	["AccountSortByDto"]: GraphQLTypes["AccountSortByDto"];
	["EventParticipantsSearchDto"]: GraphQLTypes["EventParticipantsSearchDto"];
	["EventParticipantSortByDto"]: GraphQLTypes["EventParticipantSortByDto"];
	["EventCategorySearchDto"]: GraphQLTypes["EventCategorySearchDto"];
	["EventCategorySortByDto"]: GraphQLTypes["EventCategorySortByDto"];
	["EventTypeSearchDto"]: GraphQLTypes["EventTypeSearchDto"];
	["EventTypeSortByDto"]: GraphQLTypes["EventTypeSortByDto"];
	["AvatarSearchDto"]: GraphQLTypes["AvatarSearchDto"];
	["AvatarSortByDto"]: GraphQLTypes["AvatarSortByDto"];
	["Mutation"]: {
		register:ModelTypes["AccountDto"],
	accountsEditSelf:ModelTypes["AccountDto"],
	accountsEditOne:ModelTypes["AccountDto"],
	confirmRegistration:boolean,
	checkRegistrationStatus:boolean,
	resendConfirmationCode:boolean,
	deleteUser:boolean,
	updatePassword:ModelTypes["AccountDto"],
	updateEmail:ModelTypes["AccountDto"],
	addPinnedEvent:ModelTypes["AccountDto"],
	removePinnedEvent:ModelTypes["AccountDto"],
	resetPassword:boolean,
	confirmResetPassword:boolean,
	createEvent:ModelTypes["EventDto"],
	editEvent:ModelTypes["EventDto"],
	deleteEvent:boolean,
	createEventParticipant:ModelTypes["EventParticipantDto"],
	deleteEventParticipant:boolean,
	editEventParticipant:ModelTypes["EventParticipantDto"],
	createEventCategory:ModelTypes["EventCategoryDto"],
	deleteEventCategory:boolean,
	editEventCategory:ModelTypes["EventCategoryDto"],
	createEventType:ModelTypes["EventTypeDto"],
	deleteEventType:boolean,
	editEventType:ModelTypes["EventTypeDto"],
	createLiveEvent:ModelTypes["LiveEventDto"],
	editLiveEvent:ModelTypes["LiveEventDto"],
	deleteLiveEvent:boolean,
	filesAddOne:ModelTypes["UploadInitDto"],
	createAvatar:ModelTypes["AvatarDto"],
	editAvatar:ModelTypes["AvatarDto"],
	deleteAvatar:boolean
};
	["NewAccountInputDto"]: GraphQLTypes["NewAccountInputDto"];
	["EditAccountInputDto"]: GraphQLTypes["EditAccountInputDto"];
	["EditAccountPasswordInputDto"]: GraphQLTypes["EditAccountPasswordInputDto"];
	["EditAccountEmailInputDto"]: GraphQLTypes["EditAccountEmailInputDto"];
	["NewEventInputDto"]: GraphQLTypes["NewEventInputDto"];
	["EditEventInputDto"]: GraphQLTypes["EditEventInputDto"];
	["NewEventParticipantInputDto"]: GraphQLTypes["NewEventParticipantInputDto"];
	["EditEventParticipantInputDto"]: GraphQLTypes["EditEventParticipantInputDto"];
	["NewEventCategoryInputDto"]: GraphQLTypes["NewEventCategoryInputDto"];
	["NewEventTypeInputDto"]: GraphQLTypes["NewEventTypeInputDto"];
	["NewLiveEventInputDto"]: GraphQLTypes["NewLiveEventInputDto"];
	["UploadInitInput"]: GraphQLTypes["UploadInitInput"];
	["NewAvatarInputDto"]: GraphQLTypes["NewAvatarInputDto"];
	["EditAvatarInputDto"]: GraphQLTypes["EditAvatarInputDto"]
    }

export type GraphQLTypes = {
    ["EventPriceComponentDto"]: {
	__typename: "EventPriceComponentDto",
	amount: number,
	currency: string
};
	["Location"]: {
	__typename: "Location",
	type?: string,
	coordinates?: Array<number>
};
	["AddressComponentDto"]: {
	__typename: "AddressComponentDto",
	number?: string,
	street?: string,
	streetBis?: string,
	floor?: string,
	box?: string,
	zip?: string,
	state?: string,
	city?: string,
	country?: string,
	location?: GraphQLTypes["Location"]
};
	["ImageComponentDto"]: {
	__typename: "ImageComponentDto",
	_id?: string,
	title?: string,
	fileType?: string,
	large?: string,
	medium?: string,
	small?: string,
	getFile?: GraphQLTypes["FileDto"]
};
	["AvatarStreamComponentDto"]: {
	__typename: "AvatarStreamComponentDto",
	isLive: boolean,
	hls?: string
};
	["AvatarParticipantComponentDto"]: {
	__typename: "AvatarParticipantComponentDto",
	id?: GraphQLTypes["ObjectId"],
	position?: string,
	eventId?: GraphQLTypes["ObjectId"]
};
	/** Mongo object id scalar type */
["ObjectId"]:any;
	["AvatarDto"]: {
	__typename: "AvatarDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	description?: string,
	picture?: GraphQLTypes["ImageComponentDto"],
	stream?: GraphQLTypes["AvatarStreamComponentDto"],
	participants?: Array<GraphQLTypes["AvatarParticipantComponentDto"]>,
	externalLink?: string
};
	["BaseDto"]: {
	__typename:"AvatarDto" | "LiveEventDto" | "EventCategoryDto" | "EventTypeDto" | "EventParticipantDto" | "EventDto" | "AccountDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"]
	['...on AvatarDto']: '__union' & GraphQLTypes["AvatarDto"];
	['...on LiveEventDto']: '__union' & GraphQLTypes["LiveEventDto"];
	['...on EventCategoryDto']: '__union' & GraphQLTypes["EventCategoryDto"];
	['...on EventTypeDto']: '__union' & GraphQLTypes["EventTypeDto"];
	['...on EventParticipantDto']: '__union' & GraphQLTypes["EventParticipantDto"];
	['...on EventDto']: '__union' & GraphQLTypes["EventDto"];
	['...on AccountDto']: '__union' & GraphQLTypes["AccountDto"];
};
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:any;
	["LiveEventDto"]: {
	__typename: "LiveEventDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	description?: string,
	eventId: GraphQLTypes["ObjectId"],
	event?: GraphQLTypes["EventDto"],
	avatarIds: Array<GraphQLTypes["ObjectId"]>,
	avatars?: Array<GraphQLTypes["AvatarDto"]>,
	avatarsCount?: number
};
	["EventCategoryDto"]: {
	__typename: "EventCategoryDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	logo?: GraphQLTypes["ImageComponentDto"]
};
	["EventTypeDto"]: {
	__typename: "EventTypeDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	logo?: GraphQLTypes["ImageComponentDto"]
};
	["EventParticipantDto"]: {
	__typename: "EventParticipantDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	avatars?: Array<GraphQLTypes["AvatarDto"]>,
	avatarsCount?: number,
	picture?: GraphQLTypes["ImageComponentDto"]
};
	["EventDto"]: {
	__typename: "EventDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	background?: GraphQLTypes["ImageComponentDto"],
	name: string,
	description?: string,
	location: GraphQLTypes["AddressComponentDto"],
	categoryId: GraphQLTypes["ObjectId"],
	category: GraphQLTypes["EventCategoryDto"],
	typeId: GraphQLTypes["ObjectId"],
	type: GraphQLTypes["EventTypeDto"],
	startAt: GraphQLTypes["DateTime"],
	endAt?: GraphQLTypes["DateTime"],
	liveEventId?: GraphQLTypes["ObjectId"],
	liveEvent?: GraphQLTypes["LiveEventDto"],
	price?: GraphQLTypes["EventPriceComponentDto"],
	participantIds?: Array<GraphQLTypes["ObjectId"]>,
	participants?: Array<GraphQLTypes["EventParticipantDto"]>,
	avatarsCount?: number
};
	["PaginatedEventsDto"]: {
	__typename: "PaginatedEventsDto",
	page: number,
	limit: number,
	totalCount: number,
	count: number,
	items: Array<GraphQLTypes["EventDto"]>,
	hasNextPage: boolean,
	hasPreviousPage: boolean
};
	["PaginatedEventTypesDto"]: {
	__typename: "PaginatedEventTypesDto",
	page: number,
	limit: number,
	totalCount: number,
	count: number,
	items: Array<GraphQLTypes["EventTypeDto"]>,
	hasNextPage: boolean,
	hasPreviousPage: boolean
};
	["PaginatedEventCategoriesDto"]: {
	__typename: "PaginatedEventCategoriesDto",
	page: number,
	limit: number,
	totalCount: number,
	count: number,
	items: Array<GraphQLTypes["EventCategoryDto"]>,
	hasNextPage: boolean,
	hasPreviousPage: boolean
};
	["PaginatedEventParticipantsDto"]: {
	__typename: "PaginatedEventParticipantsDto",
	page: number,
	limit: number,
	totalCount: number,
	count: number,
	items: Array<GraphQLTypes["EventParticipantDto"]>,
	hasNextPage: boolean,
	hasPreviousPage: boolean
};
	["FileDto"]: {
	__typename: "FileDto",
	_id: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	mime: string,
	title: string,
	accountId?: string,
	getUrl?: string
};
	["UploadInitDto"]: {
	__typename: "UploadInitDto",
	url: string,
	file: GraphQLTypes["FileDto"]
};
	["AccountSettingsComponentDto"]: {
	__typename: "AccountSettingsComponentDto",
	eventTypes?: Array<GraphQLTypes["EventTypeDto"]>,
	eventTypesIds?: Array<GraphQLTypes["ObjectId"]>,
	eventMoments?: Array<GraphQLTypes["EventMoments"]>
};
	/** - */
["EventMoments"]: EventMoments;
	["AccountDto"]: {
	__typename: "AccountDto",
	_id: GraphQLTypes["ObjectId"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	email: string,
	firstName?: string,
	lastName?: string,
	country?: string,
	gender?: GraphQLTypes["Gender"],
	birthDate?: GraphQLTypes["DateTime"],
	phoneNumber?: string,
	hasAcceptedTerms: boolean,
	roles?: Array<GraphQLTypes["Roles"]>,
	settings?: GraphQLTypes["AccountSettingsComponentDto"],
	pinnedEventsIds?: Array<GraphQLTypes["ObjectId"]>,
	pinnedEvents?: Array<GraphQLTypes["EventDto"]>
};
	/** - */
["Gender"]: Gender;
	["Roles"]: Roles;
	["EventSearchDto"]: {
		ids?: Array<GraphQLTypes["ObjectId"]>,
	name?: string,
	typeIds?: Array<GraphQLTypes["ObjectId"]>,
	categoryIds?: Array<GraphQLTypes["ObjectId"]>,
	liveEventId?: GraphQLTypes["ObjectId"],
	searchText?: string,
	price?: GraphQLTypes["NumberRangeArgs"],
	date?: GraphQLTypes["DateRangeArgs"],
	participantsIds?: Array<GraphQLTypes["ObjectId"]>,
	avatars?: GraphQLTypes["NumberRangeArgs"]
};
	["NumberRangeArgs"]: {
		min?: number,
	max?: number
};
	["DateRangeArgs"]: {
		to?: GraphQLTypes["DateTime"],
	from?: GraphQLTypes["DateTime"]
};
	["EventSortByDto"]: {
		name?: GraphQLTypes["SortByDirection"],
	startAt?: GraphQLTypes["SortByDirection"],
	createdAt?: GraphQLTypes["SortByDirection"]
};
	/** Sort direction for Queries */
["SortByDirection"]: SortByDirection;
	["UserSessionDto"]: {
	__typename: "UserSessionDto",
	localId: string,
	email?: string,
	displayName?: string,
	idToken: string,
	registered?: boolean,
	refreshToken: string,
	expiresIn: string
};
	["PaginatedAccountsDto"]: {
	__typename: "PaginatedAccountsDto",
	page: number,
	limit: number,
	totalCount: number,
	count: number,
	items: Array<GraphQLTypes["AccountDto"]>,
	hasNextPage: boolean,
	hasPreviousPage: boolean
};
	["PaginatedAvatarsDto"]: {
	__typename: "PaginatedAvatarsDto",
	page: number,
	limit: number,
	totalCount: number,
	count: number,
	items: Array<GraphQLTypes["AvatarDto"]>,
	hasNextPage: boolean,
	hasPreviousPage: boolean
};
	["EventPriceComponentInputDto"]: {
		amount: number,
	currency: string
};
	["LocationComponentInputDto"]: {
		type?: string,
	coordinates?: Array<number>
};
	["AddressComponentInputDto"]: {
		number?: string,
	street?: string,
	streetBis?: string,
	floor?: string,
	box?: string,
	zip?: string,
	state?: string,
	city?: string,
	country?: string,
	location?: GraphQLTypes["LocationComponentInputDto"]
};
	["ImageComponentInputDto"]: {
		_id?: string,
	title?: string,
	fileType?: string,
	large?: string,
	medium?: string,
	small?: string
};
	["AvatarStreamComponentInputDto"]: {
		isLive: boolean,
	hls?: string
};
	["AvatarParticipantComponentInputDto"]: {
		id?: GraphQLTypes["ObjectId"],
	position?: string,
	eventId?: GraphQLTypes["ObjectId"]
};
	["AvatarInputDto"]: {
		name: string,
	description?: string,
	picture?: GraphQLTypes["ImageComponentInputDto"],
	stream?: GraphQLTypes["AvatarStreamComponentInputDto"],
	participants?: Array<GraphQLTypes["AvatarParticipantComponentInputDto"]>,
	externalLink?: string
};
	["LiveEventInputDto"]: {
		name: string,
	description?: string,
	eventId: GraphQLTypes["ObjectId"],
	event?: GraphQLTypes["EventInputDto"],
	avatarIds: Array<GraphQLTypes["ObjectId"]>,
	avatars?: Array<GraphQLTypes["AvatarInputDto"]>,
	avatarsCount?: number
};
	["EventCategoryInputDto"]: {
		name: string,
	logo?: GraphQLTypes["ImageComponentInputDto"]
};
	["EventTypeInputDto"]: {
		name: string,
	logo?: GraphQLTypes["ImageComponentInputDto"]
};
	["EventParticipantInputDto"]: {
		name: string,
	avatars?: Array<GraphQLTypes["AvatarInputDto"]>,
	avatarsCount?: number,
	picture?: GraphQLTypes["ImageComponentInputDto"]
};
	["EventInputDto"]: {
		background?: GraphQLTypes["ImageComponentInputDto"],
	name: string,
	description?: string,
	location: GraphQLTypes["AddressComponentInputDto"],
	categoryId: GraphQLTypes["ObjectId"],
	category: GraphQLTypes["EventCategoryInputDto"],
	typeId: GraphQLTypes["ObjectId"],
	type: GraphQLTypes["EventTypeInputDto"],
	startAt: GraphQLTypes["DateTime"],
	endAt?: GraphQLTypes["DateTime"],
	liveEventId?: GraphQLTypes["ObjectId"],
	liveEvent?: GraphQLTypes["LiveEventInputDto"],
	price?: GraphQLTypes["EventPriceComponentInputDto"],
	participantIds?: Array<GraphQLTypes["ObjectId"]>,
	participants?: Array<GraphQLTypes["EventParticipantInputDto"]>,
	avatarsCount?: number
};
	["AccountSettingsComponentInputDto"]: {
		eventTypes?: Array<GraphQLTypes["EventTypeInputDto"]>,
	eventTypesIds?: Array<GraphQLTypes["ObjectId"]>,
	eventMoments?: Array<GraphQLTypes["EventMoments"]>
};
	["AccountInputDto"]: {
		email: string,
	firstName?: string,
	lastName?: string,
	country?: string,
	gender?: GraphQLTypes["Gender"],
	birthDate?: GraphQLTypes["DateTime"],
	phoneNumber?: string,
	hasAcceptedTerms: boolean,
	roles?: Array<GraphQLTypes["Roles"]>,
	settings?: GraphQLTypes["AccountSettingsComponentInputDto"],
	pinnedEventsIds?: Array<GraphQLTypes["ObjectId"]>,
	pinnedEvents?: Array<GraphQLTypes["EventInputDto"]>
};
	["Query"]: {
	__typename: "Query",
	login: GraphQLTypes["UserSessionDto"],
	me: GraphQLTypes["AccountDto"],
	refreshToken: GraphQLTypes["UserSessionDto"],
	accounts: GraphQLTypes["PaginatedAccountsDto"],
	account: GraphQLTypes["AccountDto"],
	myPinnedEvents: GraphQLTypes["PaginatedEventsDto"],
	events: GraphQLTypes["PaginatedEventsDto"],
	event?: GraphQLTypes["EventDto"],
	participants: GraphQLTypes["PaginatedEventParticipantsDto"],
	participant?: GraphQLTypes["EventParticipantDto"],
	categories: GraphQLTypes["PaginatedEventCategoriesDto"],
	category?: GraphQLTypes["EventCategoryDto"],
	types: GraphQLTypes["PaginatedEventTypesDto"],
	type?: GraphQLTypes["EventTypeDto"],
	liveEvents: Array<GraphQLTypes["LiveEventDto"]>,
	liveEvent?: GraphQLTypes["LiveEventDto"],
	filesGetOne?: GraphQLTypes["FileDto"],
	avatars: GraphQLTypes["PaginatedAvatarsDto"],
	avatar?: GraphQLTypes["AvatarDto"]
};
	["AccountSearchDto"]: {
		email?: string,
	searchText?: string
};
	["AccountSortByDto"]: {
		email?: GraphQLTypes["SortByDirection"],
	createdAt?: GraphQLTypes["SortByDirection"]
};
	["EventParticipantsSearchDto"]: {
		name?: string,
	avatarsCount?: GraphQLTypes["NumberRangeArgs"],
	searchText?: string
};
	["EventParticipantSortByDto"]: {
		name?: GraphQLTypes["SortByDirection"],
	createdAt?: GraphQLTypes["SortByDirection"]
};
	["EventCategorySearchDto"]: {
		name?: string,
	searchText?: string
};
	["EventCategorySortByDto"]: {
		name?: GraphQLTypes["SortByDirection"],
	createdAt?: GraphQLTypes["SortByDirection"]
};
	["EventTypeSearchDto"]: {
		name?: string,
	searchText?: string
};
	["EventTypeSortByDto"]: {
		name?: GraphQLTypes["SortByDirection"],
	createdAt?: GraphQLTypes["SortByDirection"]
};
	["AvatarSearchDto"]: {
		name?: string
};
	["AvatarSortByDto"]: {
		name?: GraphQLTypes["SortByDirection"],
	startDate?: GraphQLTypes["SortByDirection"]
};
	["Mutation"]: {
	__typename: "Mutation",
	register: GraphQLTypes["AccountDto"],
	accountsEditSelf: GraphQLTypes["AccountDto"],
	accountsEditOne: GraphQLTypes["AccountDto"],
	confirmRegistration: boolean,
	checkRegistrationStatus: boolean,
	resendConfirmationCode: boolean,
	deleteUser: boolean,
	updatePassword: GraphQLTypes["AccountDto"],
	updateEmail: GraphQLTypes["AccountDto"],
	addPinnedEvent: GraphQLTypes["AccountDto"],
	removePinnedEvent: GraphQLTypes["AccountDto"],
	resetPassword: boolean,
	confirmResetPassword: boolean,
	createEvent: GraphQLTypes["EventDto"],
	editEvent: GraphQLTypes["EventDto"],
	deleteEvent: boolean,
	createEventParticipant: GraphQLTypes["EventParticipantDto"],
	deleteEventParticipant: boolean,
	editEventParticipant: GraphQLTypes["EventParticipantDto"],
	createEventCategory: GraphQLTypes["EventCategoryDto"],
	deleteEventCategory: boolean,
	editEventCategory: GraphQLTypes["EventCategoryDto"],
	createEventType: GraphQLTypes["EventTypeDto"],
	deleteEventType: boolean,
	editEventType: GraphQLTypes["EventTypeDto"],
	createLiveEvent: GraphQLTypes["LiveEventDto"],
	editLiveEvent: GraphQLTypes["LiveEventDto"],
	deleteLiveEvent: boolean,
	filesAddOne: GraphQLTypes["UploadInitDto"],
	createAvatar: GraphQLTypes["AvatarDto"],
	editAvatar: GraphQLTypes["AvatarDto"],
	deleteAvatar: boolean
};
	["NewAccountInputDto"]: {
		email: string,
	firstName?: string,
	lastName?: string,
	country?: string,
	gender?: GraphQLTypes["Gender"],
	birthDate?: GraphQLTypes["DateTime"],
	phoneNumber?: string,
	hasAcceptedTerms: boolean,
	roles?: Array<GraphQLTypes["Roles"]>,
	settings?: GraphQLTypes["AccountSettingsComponentInputDto"],
	pinnedEventsIds?: Array<GraphQLTypes["ObjectId"]>,
	pinnedEvents?: Array<GraphQLTypes["EventInputDto"]>,
	password: string,
	passwordConfirmation: string
};
	["EditAccountInputDto"]: {
		firstName?: string,
	lastName?: string,
	country?: string,
	gender?: GraphQLTypes["Gender"],
	birthDate?: GraphQLTypes["DateTime"],
	phoneNumber?: string,
	roles?: Array<GraphQLTypes["Roles"]>,
	settings?: GraphQLTypes["AccountSettingsComponentInputDto"],
	pinnedEventsIds?: Array<GraphQLTypes["ObjectId"]>,
	pinnedEvents?: Array<GraphQLTypes["EventInputDto"]>
};
	["EditAccountPasswordInputDto"]: {
		firstName?: string,
	lastName?: string,
	country?: string,
	gender?: GraphQLTypes["Gender"],
	birthDate?: GraphQLTypes["DateTime"],
	phoneNumber?: string,
	roles?: Array<GraphQLTypes["Roles"]>,
	settings?: GraphQLTypes["AccountSettingsComponentInputDto"],
	pinnedEventsIds?: Array<GraphQLTypes["ObjectId"]>,
	pinnedEvents?: Array<GraphQLTypes["EventInputDto"]>,
	password: string,
	newPassword: string,
	newPasswordConfirmation: string
};
	["EditAccountEmailInputDto"]: {
		firstName?: string,
	lastName?: string,
	country?: string,
	gender?: GraphQLTypes["Gender"],
	birthDate?: GraphQLTypes["DateTime"],
	phoneNumber?: string,
	roles?: Array<GraphQLTypes["Roles"]>,
	settings?: GraphQLTypes["AccountSettingsComponentInputDto"],
	pinnedEventsIds?: Array<GraphQLTypes["ObjectId"]>,
	pinnedEvents?: Array<GraphQLTypes["EventInputDto"]>,
	password: string,
	newEmail: string
};
	["NewEventInputDto"]: {
		background?: GraphQLTypes["ImageComponentInputDto"],
	name: string,
	description?: string,
	location: GraphQLTypes["AddressComponentInputDto"],
	categoryId: GraphQLTypes["ObjectId"],
	typeId: GraphQLTypes["ObjectId"],
	startAt: GraphQLTypes["DateTime"],
	endAt?: GraphQLTypes["DateTime"],
	liveEventId?: GraphQLTypes["ObjectId"],
	liveEvent?: GraphQLTypes["LiveEventInputDto"],
	price?: GraphQLTypes["EventPriceComponentInputDto"],
	participantIds?: Array<GraphQLTypes["ObjectId"]>,
	participants?: Array<GraphQLTypes["EventParticipantInputDto"]>,
	avatarsCount?: number
};
	["EditEventInputDto"]: {
		background?: GraphQLTypes["ImageComponentInputDto"],
	name?: string,
	description?: string,
	location?: GraphQLTypes["AddressComponentInputDto"],
	categoryId?: GraphQLTypes["ObjectId"],
	typeId?: GraphQLTypes["ObjectId"],
	startAt?: GraphQLTypes["DateTime"],
	endAt?: GraphQLTypes["DateTime"],
	liveEventId?: GraphQLTypes["ObjectId"],
	liveEvent?: GraphQLTypes["LiveEventInputDto"],
	price?: GraphQLTypes["EventPriceComponentInputDto"],
	participantIds?: Array<GraphQLTypes["ObjectId"]>,
	participants?: Array<GraphQLTypes["EventParticipantInputDto"]>,
	avatarsCount?: number
};
	["NewEventParticipantInputDto"]: {
		name: string,
	avatars?: Array<GraphQLTypes["AvatarInputDto"]>,
	avatarsCount?: number,
	picture?: GraphQLTypes["ImageComponentInputDto"]
};
	["EditEventParticipantInputDto"]: {
		name?: string,
	avatars?: Array<GraphQLTypes["AvatarInputDto"]>,
	avatarsCount?: number,
	picture?: GraphQLTypes["ImageComponentInputDto"]
};
	["NewEventCategoryInputDto"]: {
		name: string,
	logo?: GraphQLTypes["ImageComponentInputDto"]
};
	["NewEventTypeInputDto"]: {
		name: string,
	logo?: GraphQLTypes["ImageComponentInputDto"]
};
	["NewLiveEventInputDto"]: {
		name: string,
	description?: string,
	eventId: GraphQLTypes["ObjectId"],
	event?: GraphQLTypes["EventInputDto"],
	avatarIds: Array<GraphQLTypes["ObjectId"]>,
	avatars?: Array<GraphQLTypes["AvatarInputDto"]>,
	avatarsCount?: number
};
	["UploadInitInput"]: {
		fileName: string,
	publicFile?: boolean,
	tagsIds?: Array<string>
};
	["NewAvatarInputDto"]: {
		name: string,
	description?: string,
	picture?: GraphQLTypes["ImageComponentInputDto"],
	stream?: GraphQLTypes["AvatarStreamComponentInputDto"],
	participants?: Array<GraphQLTypes["AvatarParticipantComponentInputDto"]>,
	externalLink?: string
};
	["EditAvatarInputDto"]: {
		name?: string,
	description?: string,
	picture?: GraphQLTypes["ImageComponentInputDto"],
	stream?: GraphQLTypes["AvatarStreamComponentInputDto"],
	participants?: Array<GraphQLTypes["AvatarParticipantComponentInputDto"]>,
	externalLink?: string
}
    }
/** - */
export const enum EventMoments {
	BEFORE = "BEFORE",
	DURING = "DURING",
	AFTER = "AFTER"
}
/** - */
export const enum Gender {
	FEMALE = "FEMALE",
	MALE = "MALE",
	OTHER = "OTHER"
}
export const enum Roles {
	USER = "USER",
	ADMIN = "ADMIN"
}
/** Sort direction for Queries */
export const enum SortByDirection {
	ASC = "ASC",
	DESC = "DESC"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };



const allOperations = {
    "query": "Query",
    "mutation": "Mutation"
}

export type GenericOperation<O> = O extends 'query'
  ? "Query"
  : O extends 'mutation'
  ? "Mutation"
  : never

export const Thunder = (fn: FetchFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));  
  
export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(
  o: Z | ValueTypes[R],
  ops?: OperationOptions
)=>
  fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
    o as any,
    ops,
  ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();
  

export const Gql = Chain('http://localhost:4011/api')