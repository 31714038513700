import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux part
import { useSelector } from 'react-redux';
import { StoreState } from 'store/appReducers';

import classNames from 'classnames';
import { useAction } from '@makeit-studio/store';
import { TextItem } from '@makeit-studio/ui-store';
import { IoBookmarkOutline } from 'react-icons/io5';
import { useAddPinned, useRemovePinned } from 'store/account/hooks';
import * as Styled from './Pinned.styled';

export interface PinnedProps {
  light?: boolean;
  active?: boolean;
  id?: string;
}

export const Pinned = (props: PinnedProps) => {
  const { light, active, id } = props;
  // mapStateToProps
  const lg = useSelector((state: StoreState) => state.content.lg);
  const [isActive, setIsActive] = useState(!!active);
  const profile = useSelector((state: StoreState) => state.account.profile?.data);

  const addPinned = useAddPinned();
  const removePinned = useRemovePinned();

  const handleClick = useCallback(() => {
    if (isActive) {
      removePinned({ variables: { id } });
    } else {
      addPinned({ variables: { id } });
    }
  }, [id, isActive, removePinned, addPinned]);

  useEffect(() => {
    if (id) {
      setIsActive(profile?.pinnedEventsIds?.includes(id));
    }
  }, [profile, id]);

  return (
    <Styled.Pinned onClick={() => id && handleClick()} className={classNames('fav-icon', light && 'light', isActive && 'active')}>
      <IoBookmarkOutline />
    </Styled.Pinned>
  );
};

export default Pinned;
